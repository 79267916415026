<template>
  <div class="guidance-coll">
    <a-collapse default-active-key="1" :bordered="false" @change="changeActivekey" expandIconPosition="right">
      <a-collapse-panel key="1" :show-arrow="false">
        <template #header="props">
          <div class="head-title">
            <span>1</span>
            <span>{{$VPropertyColl("传统资产配置的局_228a")}}</span>
          </div>
          <img :style="{ transform: `rotate(${props.isActive ? 180 : 0}deg)` }" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/down-icon.svg"
            alt="">
        </template>
        <div class="main-content-box">
          <p>
            <p>{{$VPropertyColl("单一维度:局限于_5384")}}</p>
            <p>{{$VPropertyColl("低透明度:中心化_d34d")}}</p>
            <p>{{$VPropertyColl("较高门槛:高昂的_ddbd")}}</p>
            <p>{{$VPropertyColl("低流动性:部分传_debe")}}</p>
          </p>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="2" :show-arrow="false">
        <template #header="props">
          <div class="head-title">
            <span>2</span>
            <span>{{$VPropertyColl("创新资产配置")}}</span>
          </div>
          <img :style="{ transform: `rotate(${props.isActive ? 180 : 0}deg)` }" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/down-icon.svg"
            alt="">
        </template>
        <div class="main-content-box">
          <p>{{$VPropertyColl("多元化与创新:把_caf5")}}</p>
          <p>{{$VPropertyColl("安全与合规:区块_52e8")}}</p>
          <p>{{$VPropertyColl("普惠与便捷:低门_33fc")}}</p>
          <p>{{$VPropertyColl("可编程性与自动化_7b5c")}}</p>
          <p>{{$VPropertyColl("所有权与控制:用_d0bd")}}</p>
        </div>
      </a-collapse-panel>
	  
    </a-collapse>
  </div>
</template>
<script>

    /* viai18n-disable */
    import $VPropertyCollMessages from "./PropertyColl.messages.json";
  const $defaultObject = {
  data() {
    return {
      text: `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`,
      active: []
    }
  },
  methods: {
    changeActivekey(key) {
      console.log(key)
    },
  }
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VPropertyColl(key,origin){
      const messages = $VPropertyCollMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VPropertyColl(key,origin){
      const messages = $VPropertyCollMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
.guidance-coll {}
</style>
<style lang="scss">
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件


.ant-collapse {
  color: $fontcolor;

  .ant-collapse-item {
    border-color: rgba(255, 255, 255, 0.20);
    margin-bottom: 40px;

    .ant-collapse-header {

      font-size: 24px;
      font-weight: 700;
      height: 106px;
      padding: 0;
      padding-left: 0 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @screen md {
        height: 60px;

        font-size: 14px;
        padding-bottom: 24px !important;
      }

      .head-title {
        display: flex;
        align-items: center;
        gap: 24px;

        & span:first-of-type {
          display: inline-block;
          width: 78px;
          height: 66px;
          text-align: center;
          line-height: 66px;
          background: url(https://wami-wod.oss-cn-beijing.aliyuncs.com/img/settingNumIcon.png) no-repeat center;
          background-size: 100% 100%;

          @screen md {
            width: 38px;
            height: 34px;
            font-size: 16px;
            line-height: 34px;
          }
        }
      }

      img {
        width: 40px;
        transition: all .2s;
        user-select: none;

        @screen md {
          width: 20px;
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0;
      padding-top: 30px !important;
      padding-bottom: 60px;

      @screen md {
        padding-top: 10px !important;
        padding-bottom: 24px;
      }

      .main-content-box {

        display: flex;
        gap: 30px;
        flex-direction: column;

        @screen md {
          gap: 20px;
        }

        p {
          margin: 0;

          font-size: 24px;
          font-weight: 410;
          line-height: 30px;

          @screen md {
            font-size: 12px;
            line-height: 20px;
          }

          .txt-light {
            background: linear-gradient(270deg, #203DFD 0%, #34C5F3 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .table-box {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          border: 1px solid rgba(255, 255, 255, 0.20);

          @media (min-width: 1200px) and (max-width: 1440px) {
            /* width: 100% !important; */
          }

          @media (min-width: 667px) and (max-width: 1200px) {
            width: 100% !important;
          }

          @screen md {
            width: 100% !important;
          }

          .table-tit,
          .table-li {
            display: flex;

            span {
              padding: 35px;
              padding-right: 0;
              text-align: left;
              flex: 1;
              display: inline-block;
              border-right: 1px solid rgba(255, 255, 255, 0.20);
              border-bottom: 1px solid rgba(255, 255, 255, 0.20);

              font-size: 24px;
              font-weight: 510;
              line-height: 30px;

              @screen md {
                padding: 16px 0 16px 21px;
                font-size: 12px;
                line-height: 20px;
              }
            }

            & span:last-of-type {
              border-right: none;
            }
          }

          & .table-li:last-of-type {
            span {
              border-bottom: none;
            }
          }
        }

        .team-table {

          .table-tit,
          .table-li {
            span {
              flex: 1;

              @screen md {
                flex: 6;
                line-height: 100%;
              }
            }

            & span:last-of-type {
              flex: 2;

              @screen md {
                flex: 8;
                line-height: 100%;
              }
            }
          }
        }
      }
    }
  }

  & .ant-collapse-item:last-of-type {
    border: none;
  }
}

.ant-collapse-borderless {
  background-color: transparent !important;
}
</style>