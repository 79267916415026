<template>
  <div class="medal-wall-content md:px-12 md:flex-col-reverse">
    <div class="content-left md:block md:text-center">
      <span class="tit md:text-32 md:leading-40 md:mb-16 md:mr-16">{{$VMedalContent("我们让您能够快速_93d8")}}</span>
      <p class="md:text-12 md:mb-52">{{$VMedalContent("C&RAMGlo_4bb8")}}</p>
      <div class="btn md:mx-auto">
        <span @click="$router.push('/Migrate')">{{$VMedalContent("了解更多")}}</span>
      </div>
    </div>
    <div class="content-right md:pt-32 md:pb-48 md:flex-col md:gap-16">
      <div class="mask-up md:hidden"></div>
      <div class="mask-down md:hidden"></div>
      <div class="animation-up md:hidden">
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_2.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_3.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_4.png" /></div>

        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_1.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_2.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_3.png" /></div>
      </div>

      <div class="animation-left-md md:flex">
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_2.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_3.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_4.png" /></div>
		
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_1.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_2.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_3.png" /></div>
      </div>

      <div class="animation-down md:hidden">
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_1.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_2.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_3.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_4.png" /></div>
		
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_1.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_2.png" /></div>
      </div>

      <div class="animation-right-md md:flex">
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_1.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_2.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_3.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_4.png" /></div>
		
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_1.png" /></div>
        <div class="medal-item md:mr-16"><img src="../../assets/medalWall/right_2.png" /></div>
      </div>
    </div>
  </div>
</template>
<script>


    /* viai18n-disable */
    import $VMedalContentMessages from "./MedalContent.messages.json";
  const $defaultObject = {

}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VMedalContent(key,origin){
      const messages = $VMedalContentMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VMedalContent(key,origin){
      const messages = $VMedalContentMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

.medal-wall-content {

  color: $fontcolor;

  position: relative;
  z-index: 1;
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;

  .content-left {
    display: flex;
    flex-direction: column;
	padding-right:10px;
	
    .tit {
	  letter-spacing: 2px;
	  word-spacing: 10px;
      display: inline-block;
      font-size: 32px;
      font-weight: 800;
      line-height: 52px;
      background-image: linear-gradient(to right, #33C4F2, #1F35FD);
      background-clip: text;
   
      margin-bottom: 12px;
    }

    .tit-second {
      display: inline-block;
      font-size: 86px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0.9px;
      margin-bottom: 40px;
    }

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      margin: 0;
      margin-bottom: 62px;
	  
    }

    .btn {
	
		background-color: $bt_bg_color;
		color: $bt_ft_color;

      width: 240px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
      cursor: pointer;

      span {
        display: inline-block;
        text-align: center;
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height: 48px;
      }
	  
		&:hover {
			box-shadow: $box_shadow;
			background: $jbfontbacColor;
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			border-radius: 100px;
			font-weight: bold;
		}
    }
  }
  .content-right {
    display: flex;
    max-height: calc(100vh - 80px);
    overflow: hidden;
    align-items: flex-start;
    gap: 32px;
    justify-content: space-between;
    position: relative;
    z-index: 0;
	
    .animation-up, .animation-down {
      display: flex;
      flex-direction: column;
      /* gap: 40px; */
      .medal-item {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        overflow: hidden;
        border: 1px solid rgba(255, 255, 255, 0.20);
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
	
    .animation-left-md, .animation-right-md {
      display: none;
      flex-direction: row;
	  overflow: hidden;
      /* gap: 40px; */
      .medal-item {
        width: 120px;
        height: 180px;
        border-radius: 15px;
        overflow: hidden;
        border: 1px solid rgba(255, 255, 255, 0.20);
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      
    }
    .animation-up {
      transform: translate3d(0, 0, 0);
      animation: k-up 40s linear infinite;
      .medal-item {
        margin-bottom: 40px;
      }
    }
    .animation-down {
      transform: translate3d(0, -50%, 0);
      animation: k-down 40s linear infinite;
      .medal-item {
        margin-top: 40px;
      }
    }

    .animation-left-md {
      transform: translate3d(27%, 0, 0);
      animation: k-left 40s linear infinite;
    }
    .animation-right-md {
      transform: translate3d(-25%, 0, 0);
      animation: k-right 40s linear infinite;
    }
    .mask-up, .mask-down {
      width: 100%;
      height: 180px;
      position: absolute;
      left: 0;
      z-index: 1;
    }
    .mask-up {
      top: 0;
      background: linear-gradient(180deg, rgba(200, 200, 200, 0.8) 0%, rgba(200, 200, 200, 0.8) 5%, rgba(0, 0, 0, 0.00) 30%);
    }
    .mask-down {
      bottom: 0;
      background: linear-gradient(0deg, rgba(200, 200, 200, 0.8) 0%, rgba(200, 200, 200, 0.8) 5%, rgba(0, 0, 0, 0.00) 30%);
    }
  }
}
@keyframes k-up {
  0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	100% {
		-webkit-transform: translate3d(0, -50%, 0);
		transform: translate3d(0, -50%, 0);
	}
}
@keyframes k-down {
  0% {
		-webkit-transform: translate3d(0, -50%, 0);
		transform: translate3d(0, -50%, 0);
	}

	100% {
		-webkit-transform: translate3d(0, 0%, 0);
		transform: translate3d(0, 0%, 0);
	}
}

@keyframes k-left {
  0% {
		-webkit-transform: translate3d(27%, 0, 0);
		transform: translate3d(27%, 0, 0);
	}

	100% {
		-webkit-transform: translate3d(-23%, 0%, 0);
		transform: translate3d(-23%, 0%, 0);
	}
}
@keyframes k-right {
  0% {
		-webkit-transform: translate3d(-25%, 0, 0);
		transform: translate3d(-25%, 0, 0);
	}

	100% {
		-webkit-transform: translate3d(25%, 0%, 0);
		transform: translate3d(25%, 0%, 0);
	}
}
</style>