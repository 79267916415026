import store from '@/store'
import {
	queryTool,
	executeTool,
	queryTool2,
	executeTool2
} from "./tool";

const abi = require("./abi/mintNft.json");


/**
 * 领取
 * @param {Object} params
	第一个参数 NFT合约地址   可以使用定义 也可以使用 中心化接口返回的  contract
	第二个参数  接口返回的签名  signedmsg * @returns
 */
export const mintNft = ({ address, signedmsg } = {}, contract) => {
	
	return executeTool({
		name: 'mintNft',
		contractAbi: abi,
		contractAddress: contract,
		fn: 'mintNft',
		params: [address, signedmsg],
		fromAddress:store.state.address,
		returnReceipt: true,
	})
}



//累计已领取
export const isMinted = (address ,nftAddress, contract) => {
	return queryTool({
		name: 'isMinted',
		contractAbi: abi,
		contractAddress: contract,
		fn: 'isMinted',
		params: [address,nftAddress],
	})
}