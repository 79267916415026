import axios from "axios";
const axiosApi = axios.create();

axiosApi.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'

// 开发环境
axiosApi.defaults.baseURL = "https://kedao.wamigroup.com/api/wod/"; //api接口地址-测试环境

// 添加请求拦截
axiosApi.interceptors.request.use((config) => {
  // config.headers["Content-Type"] = "application/json";
  // config.data = JSON.stringify(config.data);
  return config;
});
// 响应拦截器
// axiosApi.interceptors.response.use(response => {

//     return response
// });

// post raw
export const postRaw = (url, params = {}) => {
  params = JSON.stringify(params);
  return new Promise((resolve, reject) => {
    axiosApi
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.data);
      });
  });
}
//post请求
export const post = (url, params) => {
  params = params || {};
  return new Promise((resolve, reject) => {
    axiosApi
      .post(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
        console.log(error);
      });
  });
};

//get请求
export const get = (url, params) => {
  params = params || {};

  return new Promise((resolve, reject) => {
    axiosApi
      .get(url, {
        params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export const defaults = axiosApi.defaults;
