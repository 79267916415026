<template>
	<div >
		<div class="airdrop flex-between">
			<div class="airdrop-left">
				<h1 class="flex">
					<span>{{$VMediCareFuturoNexus("尖端医疗养老规划")}}</span>
				</h1>
				<h2>{{$VMediCareFuturoNexus("医疗创新，养老尖_f71b")}}</h2>
				<h4>{{$VMediCareFuturoNexus("1.高端医疗健康_988f")}}<p>{{$VMediCareFuturoNexus("-提供专业医疗健_871d")}}</p>
				</h4>
				<h4>{{$VMediCareFuturoNexus("2.全球医疗资源_cf78")}}<p>{{$VMediCareFuturoNexus("-整合全球尖端医_5a69")}}</p>
				</h4>
				
				<h4>{{$VMediCareFuturoNexus("3.养老医疗陪护_5e55")}}<p>{{$VMediCareFuturoNexus("-提供养老医疗陪_0884")}}</p>
				</h4>
				
				<h4>{{$VMediCareFuturoNexus("4.全球医疗旅行_641f")}}<p>{{$VMediCareFuturoNexus("-提供全球医疗旅_517a")}}</p>
				</h4>
				<h4>{{$VMediCareFuturoNexus("5.营养与生活方_654e")}}<p>{{$VMediCareFuturoNexus("-为客户提供个性_12ab")}}</p>
				</h4>
				<h4>{{$VMediCareFuturoNexus("6.全球养老生活_c2ea")}}<p>{{$VMediCareFuturoNexus("-设计全球养老生_0896")}}</p>
				</h4>
				
			</div>
			<div class="airdrop-right">
				<img src="img/plan/4.png" alt="" srcset="" />
			</div>
		</div>
		<div style="font-size: 20px; margin-top:20px; text-align:center">
			<h4>
				C&RAM MediCareFuturo Nexus
				<p>{{$VMediCareFuturoNexus("是一套尖端医疗养_5e7c")}}</p>
			</h4>
		</div>
	</div>
</template>

<script>
	import modal from '@/components/modal.vue'
	import { mapState } from 'vuex'
	
    /* viai18n-disable */
    import $VMediCareFuturoNexusMessages from "./MediCareFuturoNexus.messages.json";
  const $defaultObject = {
		name: 'company',
		components: { modal },
		data() {
			return {
				isShow: false,
			}
		},
		computed: {

		},
		mounted() {

		},
		methods: {
			confirm() {
				this.isShow = true;
			},
		}
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VMediCareFuturoNexus(key,origin){
      const messages = $VMediCareFuturoNexusMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VMediCareFuturoNexus(key,origin){
      const messages = $VMediCareFuturoNexusMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件



	.airdrop {
		min-height: 90vh;
		color: $fontcolor;
  
		.airdrop-left {
			flex: 1;
			margin-right: 50px;

			h1 {
				flex-wrap: wrap;
				max-width: 740px;

				span {
					display: inline-block;
					font-size: 36px;
					font-weight: 800;
					line-height: 9 2px;
					letter-spacing: 0.01em;
					text-align: left;

				}

				span:nth-of-type(2) {
					padding-left: 10px;
					background-image: -webkit-linear-gradient(270deg, #203DFD 0%, #34C5F3 100%);

					-webkit-background-clip: text;

					-webkit-text-fill-color: transparent;
				}
			}
			h2 {
				max-width: 740px;
				padding-top: 35px;
				
				font-size: 26px;
				font-weight: 400;
				line-height: 34px;
				letter-spacing: 0em;
				text-align: center;

			}
			h4 {
				max-width: 740px;
				padding-top: 35px;
				
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0em;
				text-align: left;

			}

			.btn-box {
				padding-top: 64px;
				
				.ant-btn-primary {
					width: 320px;
					height: unset;

					line-height: unset;
					padding: 10px 0px;
					border-radius: 100px;

					font-size: 20px;

					font-weight: 400;
					background: linear-gradient(to right, #203dfc, #33c4f2);
					border: none;

					@screen md {
						height: unset;
						line-height: unset;
						width: 200px;
						padding: 8px 40px;
						font-size: 14px;
					}

				}
			}
			
			.btn{
				margin-top: 50px;
			}
		}

		.airdrop-right {
			width: 660px;
			margin-top:20px;
			// background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/airdropRightBg.png') no-repeat;
			// background-size: 100% 100%;
		}

		.popup-box {
			.popup-left {
				width: 300px;
				height: 300px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.popup-right {
				flex: 1;
				padding-left: 40px;
				text-align: left;
				width: 490px;

				h1 {
					margin: 0;
					padding-top: 30px;
					
					font-size: 32px;
					font-weight: 700;
					line-height: 32px;
					letter-spacing: 0px;

				}

				h4 {
					margin: 0;
					padding: 24px 0;
					
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0px;

				}

				ul {
					li {
						.left {
							
							font-size: 16px;
							font-weight: 400;
							line-height: 16px;
							letter-spacing: 0px;
							text-align: left;


						}

						.right {
							
							font-size: 16px;
							font-weight: 400;
							line-height: 16px;
							letter-spacing: 0px;

						}
					}

					li+li {
						padding-top: 16px;
					}
				}
			}
		}



		@screen md {
			flex-direction: column-reverse;

			.airdrop-left {
				padding-top: 30px;
				margin: 0;
				text-align: center;

				h1 {
					margin: 0 auto;
					display: block;
					width: 242px;
					justify-content: center;

					span {
						display: inline;
						font-size: 32px;
						line-height: unset;
					}

					span:nth-of-type(2) {
						padding: 0;
						padding-right: 15px;
					}
				}

				h4 {
					font-size: 12px;
					line-height: 20px;
					text-align: center;
				}
			}

			.airdrop-right {
				width: 280px;
			}

			.popup-box {
				&>div {
					display: flex;
					flex-direction: column !important;
				}

				.popup-left {
					width: 100px;
					height: 100px;
				}

				.popup-right {
					width: unset;
					padding-left: 0;
					text-align: center;

					h1 {
						font-size: 20px;
						line-height: 0;
					}

					h4 {
						padding: 20px 0;
						margin-bottom: 20px;
						font-size: 12px;
						line-height: 18px;

						border-bottom: 1px solid rgba(49, 45, 58, 1);
					}

					ul {
						li {
							.left {
								font-size: 14px;
							}

							.right {
								font-size: 14px;
							}
						}
					}
				}
			}

		}




	}
	
    .btn {
	
		background-color: $bt_bg_color;
		color: $bt_ft_color;

      width: 240px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
      cursor: pointer;

      span {
        display: inline-block;
        text-align: center;
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height: 48px;
      }
	  
		&:hover {
			box-shadow: $box_shadow;
			background: $jbfontbacColor;
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			border-radius: 100px;
			font-weight: bold;
		}
    }
</style>