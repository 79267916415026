<template>
  <div class="supply">
    <Header></Header>

    <div class="supply-content md:px-12">
      <Breadcrumb :routes="routes"></Breadcrumb>
      <div class="supply-title">
        <div class="title">
          <h1>{{$VAccess("提前访问")}}</h1>
          <span>&nbsp;BP-FLAC</span>
        </div>
        <p>{{$VAccess("在下方注册您的兴_1139")}}<br>{{$VAccess("未来几个月，我们_2a39")}}</p>
        <div class="cost">
          <div class="line-box" @click="$router.push({name: 'Cost'})">
            <span>{{$VAccess("成本与性能")}}</span>
            <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/next-icon.png" alt="">
          </div>
        </div>
      </div>
      <AccessFrom></AccessFrom>
    </div>
    
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/home/Banner.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import AccessFrom from '@/components/access/AccessFrom.vue'


    /* viai18n-disable */
    import $VAccessMessages from "./Access.messages.json";
  const $defaultObject = {
  name: 'Supply',
  components: {
    Header,
    Footer,
    Banner,
    Breadcrumb,
    AccessFrom
  },
  data() {
    return {
      routes: [
        {
          path: '/',
          breadcrumbName: 'Home',
        },
        {
          path: '/access',
          breadcrumbName: 'Access compute ',
        },
      ],
    }
  },
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VAccess(key,origin){
      const messages = $VAccessMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VAccess(key,origin){
      const messages = $VAccessMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
.supply {
  /* height: 100vh; */
  position: relative;
  background-color: #000000;
  color: #ffffff;
  .supply-content {
    padding: 14px 75px;
    .supply-title {
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 42px;
        margin-bottom: 26px;
        @screen md {
          display: block;
          margin-top: 32px;
          margin-bottom: 16px;
        }
        h1 {
          margin: 0;
          font-size: 64px;
          color: #fff;
          font-weight: 700;
          @screen md {
            font-size: 30px;
            text-align: center;
          }
        }
        span {
          font-size: 64px;
          font-weight: 800;
          background-image: linear-gradient(to right, #33C4F2, #1F35FD);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          display: inline-block;
          @screen md {
            display: inline-block;
            width: 100%;
            font-size: 30px;
            text-align: center;
          }
        }
      }
      p {
        text-align: center;
        color: rgba(255, 255, 255, 0.60);
        font-size: 20px;
        font-weight: 700;
        font-style: normal;
        line-height: 32.5px; /* 162.5% */
        letter-spacing: 2px;
        margin: 0;
        @screen md {
          font-size: 10px;
          line-height: 18px;
          letter-spacing: 0.1px;
        }
      }
      .cost {
        width: 100%;
        margin-top: 30px;
        text-align: center;
        .line-box {
          cursor: pointer;
          display: inline-block;
          padding-bottom: 8px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.60);
          
          span {
            display: inline-block;
            color: #33C4F2;
            font-size: 14px;
            text-align: center;
          }
          img {
            display: inline-block;
            width: 10px;
            margin-left: 8px;
          }
        }
      }
    }
  }
  @screen md {
    /* padding-top: 55px; */
  }
}
</style>