<template>
	<div >
		<div class="airdrop flex-between">
			<div class="airdrop-left">
				<h1 class="flex">
					<span>{{$VWealthNavigator("全球资产配置规划")}}</span>
				</h1>
				<h2>{{$VWealthNavigator("全球视野，智慧配_74ab")}}</h2>
				<h4>{{$VWealthNavigator("1.国际投资咨询_c689")}}<p>{{$VWealthNavigator("-提供全球范围内_2fc9")}}</p>
				</h4>
				<h4>{{$VWealthNavigator("2.跨境财富管理_68e7")}}<p>{{$VWealthNavigator("-为高净值客户提_3df4")}}</p>
				</h4>
				
				<h4>{{$VWealthNavigator("3.全球房地产投_8f7e")}}<p>{{$VWealthNavigator("-提供全球范围内_ceb8")}}</p>
				</h4>
				
				<h4>{{$VWealthNavigator("4.跨境企业投资_7422")}}<p>{{$VWealthNavigator("-为企业提供跨境_b6c0")}}</p>
				</h4>
				<h4>{{$VWealthNavigator("5.机构级全球资_3d83")}}<p>{{$VWealthNavigator("-面向机构客户提_c50f")}}</p>
				</h4>
				<h4>{{$VWealthNavigator("6.数字资产财富_b3ca")}}<p>{{$VWealthNavigator("-提供数字资产管_16a7")}}</p>
				</h4>
				<h4>
					<p>{{$VWealthNavigator("a、数字资产通识_b4eb")}}</p>
					<p>{{$VWealthNavigator("b、区块链技术咨_67d9")}}</p>
					<p>{{$VWealthNavigator("c、数字资产管理_d741")}}</p>
					<p>{{$VWealthNavigator("d、智能合约开发_a3cd")}}</p>
					<p>{{$VWealthNavigator("e、香港地区IC_fa47")}}</p>
				</h4>


				
			</div>
			<div class="airdrop-right">
				<img src="img/plan/5.png" alt="" srcset="" />
			</div>
		</div>
		<div style="font-size: 20px; margin-top:20px; text-align:center">
			<h4>
				C&RAM GlobalWealth Navigator
				<p>{{$VWealthNavigator("是一个全球资产配_524c")}}</p>
			</h4>
		</div>
	</div>
</template>

<script>
	import modal from '@/components/modal.vue'
	import { mapState } from 'vuex'
	
    /* viai18n-disable */
    import $VWealthNavigatorMessages from "./WealthNavigator.messages.json";
  const $defaultObject = {
		name: 'company',
		components: { modal },
		data() {
			return {
				isShow: false,
			}
		},
		computed: {

		},
		mounted() {

		},
		methods: {
			confirm() {
				this.isShow = true;
			},
		}
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VWealthNavigator(key,origin){
      const messages = $VWealthNavigatorMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VWealthNavigator(key,origin){
      const messages = $VWealthNavigatorMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件



	.airdrop {
		min-height: 90vh;
		color: $fontcolor;
  
		.airdrop-left {
			flex: 1;
			margin-right: 50px;

			h1 {
				flex-wrap: wrap;
				max-width: 740px;

				span {
					display: inline-block;
					font-size: 36px;
					font-weight: 800;
					line-height: 9 2px;
					letter-spacing: 0.01em;
					text-align: left;

				}

				span:nth-of-type(2) {
					padding-left: 10px;
					background-image: -webkit-linear-gradient(270deg, #203DFD 0%, #34C5F3 100%);

					-webkit-background-clip: text;

					-webkit-text-fill-color: transparent;
				}
			}
			h2 {
				max-width: 740px;
				padding-top: 35px;
				
				font-size: 26px;
				font-weight: 400;
				line-height: 34px;
				letter-spacing: 0em;
				text-align: center;

			}
			h4 {
				max-width: 740px;
				padding-top: 35px;
				
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0em;
				text-align: left;

			}

			.btn-box {
				padding-top: 64px;
				
				.ant-btn-primary {
					width: 320px;
					height: unset;

					line-height: unset;
					padding: 10px 0px;
					border-radius: 100px;

					font-size: 20px;

					font-weight: 400;
					background: linear-gradient(to right, #203dfc, #33c4f2);
					border: none;

					@screen md {
						height: unset;
						line-height: unset;
						width: 200px;
						padding: 8px 40px;
						font-size: 14px;
					}

				}
			}
			
			.btn{
				margin-top: 50px;
			}
		}

		.airdrop-right {
			width: 660px;
			margin-top:20px;
			// background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/airdropRightBg.png') no-repeat;
			// background-size: 100% 100%;
		}

		.popup-box {
			.popup-left {
				width: 300px;
				height: 300px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.popup-right {
				flex: 1;
				padding-left: 40px;
				text-align: left;
				width: 490px;

				h1 {
					margin: 0;
					padding-top: 30px;
					
					font-size: 32px;
					font-weight: 700;
					line-height: 32px;
					letter-spacing: 0px;

				}

				h4 {
					margin: 0;
					padding: 24px 0;
					
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0px;

				}

				ul {
					li {
						.left {
							
							font-size: 16px;
							font-weight: 400;
							line-height: 16px;
							letter-spacing: 0px;
							text-align: left;


						}

						.right {
							
							font-size: 16px;
							font-weight: 400;
							line-height: 16px;
							letter-spacing: 0px;

						}
					}

					li+li {
						padding-top: 16px;
					}
				}
			}
		}



		@screen md {
			flex-direction: column-reverse;

			.airdrop-left {
				padding-top: 30px;
				margin: 0;
				text-align: center;

				h1 {
					margin: 0 auto;
					display: block;
					width: 242px;
					justify-content: center;

					span {
						display: inline;
						font-size: 32px;
						line-height: unset;
					}

					span:nth-of-type(2) {
						padding: 0;
						padding-right: 15px;
					}
				}

				h4 {
					font-size: 12px;
					line-height: 20px;
					text-align: center;
				}
			}

			.airdrop-right {
				width: 280px;
			}

			.popup-box {
				&>div {
					display: flex;
					flex-direction: column !important;
				}

				.popup-left {
					width: 100px;
					height: 100px;
				}

				.popup-right {
					width: unset;
					padding-left: 0;
					text-align: center;

					h1 {
						font-size: 20px;
						line-height: 0;
					}

					h4 {
						padding: 20px 0;
						margin-bottom: 20px;
						font-size: 12px;
						line-height: 18px;

						border-bottom: 1px solid rgba(49, 45, 58, 1);
					}

					ul {
						li {
							.left {
								font-size: 14px;
							}

							.right {
								font-size: 14px;
							}
						}
					}
				}
			}

		}




	}
	
    .btn {
	
		background-color: $bt_bg_color;
		color: $bt_ft_color;

      width: 240px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
      cursor: pointer;

      span {
        display: inline-block;
        text-align: center;
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height: 48px;
      }
	  
		&:hover {
			box-shadow: $box_shadow;
			background: $jbfontbacColor;
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			border-radius: 100px;
			font-weight: bold;
		}
    }
</style>