<template>
  <div class="c-solution md:mx-0 md:mt-0 md:mb-0">
    <div class="content">
      <div class="title-info md:px-12">
        <div class="flex items-center"><span class="dot"></span>{{$VSolution("如何工作")}}</div>
        <div class="title font-bold">{{$VSolution("解决“困境四边形_1f7f")}}</div>
        <div class="desc">{{$VSolution("BP-FLAC致_3b3d")}}</div>
      </div>


      <div class="list-wrap md:mx-12">
        <div class="item-content">
          <div class="items">
            <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/solution-1.png" alt="">
            <div class="item-title">{{$VSolution("数据隐私保护")}}</div>
            <div class="description">{{$VSolution("零知识证明解决数_d47b")}}</div>
          </div>
          <div class="items md:mt-60">
            <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/solution-2.png" alt="">
            <div class="item-title">{{$VSolution("偏见和公平性")}}</div>
            <div class="description">{{$VSolution("通过DAO知识实_a94a")}}</div>
          </div>
        </div>
        <div class="item-content mt-80 md:mt-60">
          <div class="items">
            <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/solution-3.png" alt="">
            <div class="item-title">{{$VSolution("缺乏可解释性")}}</div>
            <div class="description">{{$VSolution("AI模块通用解决_9441")}}</div>
          </div>
          <div class="items md:mt-60 md:pb-60">
            <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/solution-4.png" alt="">
            <div class="item-title">{{$VSolution("资源密集型")}}</div>
            <div class="description">{{$VSolution("突破计算能力壁垒_3b9d")}}</div>
          </div>
        </div>
      </div>
      <img class="bg-1 md:hidden" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/solution-bg-1.png" alt="">
      <img class="bg-1-m hidden md:block" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/solution-bg-m-1.png" alt="">
      <img class="bg-2" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/solution-bg-2.webp" alt="">
    </div>
    <div class="introduce-wrap">
      <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/solution-bg-3.png" alt="">
      <div class="introduce font-bold">
        <div class="flex items-center justify-center">
          <span class="text-dot"></span>
          <span class="question text-14">{{$VSolution("我们的愿景")}}</span>
        </div>
        <div class="md:px-40">
          <Language class="md:text-24 md:leading-30" :text='$VSolution("给所有人提供由顶_8503")'>
            <span slot="r1" class="light-color" slot-scope="data">{{ data.value }}</span>
          </Language>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import Language from '@/components/common/Language'

    /* viai18n-disable */
    import $VSolutionMessages from "./Solution.messages.json";
  const $defaultObject = {
  components: {
    Language
  }
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VSolution(key,origin){
      const messages = $VSolutionMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VSolution(key,origin){
      const messages = $VSolutionMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
.c-solution {
  color: white;
  margin-top: 197px;
  margin: 197px 75px 100px 75px;

  .content {
    // max-width: 1770px;
    margin: 0 auto;
    position: relative;

    .title-info {
      max-width: 1920px;
      margin: 0 auto;
    }

    .bg-1 {
      position: absolute;
      right: -75px;
      z-index: 1;
      top: 20px;
    }

    .bg-1-m {
      position: absolute;
      top: 90px;
      right: 0;
    }

    .bg-2 {
      position: absolute;
      left: -75px;
      bottom: -180px;
      z-index: 1;

      @screen md {
        width: 240px;
        bottom: -180px;
        left: -20px;
      }
    }
  }

  .introduce-wrap {
    text-align: center;
    padding-top: 177px;

    img {
      margin: 0 auto;
      
      @screen md {
        width: 200px;
      }
    }

    .introduce {
      font-size: 54px;
      line-height: 68px;
      position: relative;
      top: -126px;
      max-width: 1280px;
      margin: 0 auto;

      .question {
        line-height: 22px;
      }

      .text-dot {
        width: 6px;
        height: 6px;
        display: block;
        border-radius: 6px;
        background-color: #253bff;
        margin-right: 7px;
      }

      .light-color {
        color: #253BFF;
      }
    }
  }

  .dot {
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 6px;
    background-color: #253bff;
    margin-right: 7px;
  }

  .title {
    font-size: 54px;
    line-height: 68px;
    margin-bottom: 40px;

    @screen md {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }

  .desc {
    font-size: 20px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.6);

    @screen md {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .list-wrap {
    height: 593px;
    background-color: rgba(26, 23, 40, 0.8);
    border-radius: 20px;
    margin-top: 78px;
    padding: 75px 158px;
    max-width: 1920px;
    margin: 78px auto 0 auto;

    backdrop-filter: blur(45px);
    -webkit-backdrop-filter: blur(45px);
    position: relative;
    z-index: 2;

    @screen md {
      padding: 37px 28px;
      height: auto;
    }

    .item-content {
      display: flex;

      @screen md {
        display: block;
      }


      .items {
        flex: 1;

        img {
          width: 60px;
        }
      }

      .item-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        color: white;
        margin-top: 20px;
        margin-bottom: 12px;

        @screen md {
          font-size: 20px;
        }
      }

      .description {
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        max-width: 440px;

        @screen md {
          font-size: 12px;
        }
      }
    }
  }
}</style>