<template>
  
  <a-carousel autoplay :autoplay-speed="5000">
    <div class="c-banner">
      <div class="banner-content">
        <video class="video" ref="video" :controls="false" :src="animation.high" autoplay :width="'100%'" muted
          :height="'100%'" webkit-playsinline playsinline x5-playsinline loop></video>
        <div class="banner-text-content md:pl-12 md:relative">
          <div class="inner-text">
            <span class="text" :class="[$lang === 'ru' ? 'md:text-24 md:leading-28' : 'md:text-32 md:leading-34']">
              <Language :text='$VBanner("彻底的范式变革[_5616")'>
                <span class="text-slogan" slot="r1" slot-scope="data">{{ data.value }}</span>
                <span class="block" slot="r2"></span>
              </Language>
            </span>
            <div class="desc md:mt-20 md:text-12">{{$VBanner("使用我们强大的B_9b31")}}</div>
            <Button :class="$lang" link=" https://wmkjcds-organization.gitbook.io/wod/"><span
                class="text-ffffff">{{$VBanner("查看文档")}}</span></Button>
            
          </div>
        </div>
      </div>
    </div>
    <div class="c-banner">
      <BannerFaucet></BannerFaucet>
    </div>
    <div class="c-banner">
      <BannerBridge></BannerBridge>
    </div>
    <div class="c-banner">
      <div class="eon-content">
        <div class="eon-box">
          <video src="https://wami-wod.oss-cn-beijing.aliyuncs.com/eon-mobile.mp4" webkit-playsinline x5-playsinline
            playsinline muted autoplay="autoplay" loop class="xm:block md:block xl:hidden"></video>
          <video src="https://wami-wod.oss-cn-beijing.aliyuncs.com/eon.mp4" webkit-playsinline x5-playsinline playsinline
            muted autoplay="autoplay" loop class="md:hidden xl:block"></video>
          <div class="eon-pc md:hidden">
            <div class="title-box">
              <h1><span>BP-FLAC</span>{{$VBanner("推出")}}</h1> <br>
              <h1>{{$VBanner("PoW侧链")}}<span class="eon-txt"> EON</span></h1>
            </div>
            <div class="details-box">
              <div class="details-item">
                <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/set-icon.svg" alt="">
                <span>{{$VBanner("工作量证明共识机_63e1")}}</span>
              </div>
              <div class="details-item">
                <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/set-icon.svg" alt="">
                <span>{{$VBanner("聚合消费级芯片和_c074")}}</span>
              </div>
              <div class="details-item">
                <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/set-icon.svg" alt="">
                <span>{{$VBanner("比特币第2层解决_6b83")}}</span>
              </div>
              <div class="details-item">
                <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/set-icon.svg" alt="">
                <span>{{$VBanner("开发DePIN网_2c3f")}}</span>
              </div>
            </div>
            <div class="btn-box">
              <Button :class="$lang" link="https://eonchain.xyz">
                <span class="text-ffffff">{{$VBanner("Eon网站")}}</span>
              </Button>
              <Button :class="$lang"
                link="https://wodswap.io/#/swap?use=v2&inputCurrency=0xe8a4e1c10371d5a9eea7a8baa09c3186bfe11f14">
                <span class="text-ffffff">{{$VBanner("交易Eon")}}</span>
              </Button>
            </div>
          </div>
          <div class="eon-mobile xl:hidden md:block">
            <div class="title-box">
              <h1><span>BP-FLAC</span></h1>
              <h1>{{$VBanner("推出了")}}<br>{{$VBanner("PoW侧链")}}</h1>
              <h1><span class="eon-txt">EON</span></h1>
            </div>
            <div class="details-box">
              <div class="details-item">
                <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/set-icon.svg" alt="">
                <span>{{$VBanner("工作量证明共识机_63e1")}}</span>
              </div>
              <div class="details-item">
                <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/set-icon.svg" alt="">
                <span>{{$VBanner("聚合消费级芯片和_c074")}}</span>
              </div>
              <div class="details-item">
                <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/set-icon.svg" alt="">
                <span>{{$VBanner("比特币第2层解决_6b83")}}</span>
              </div>
              <div class="details-item">
                <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/set-icon.svg" alt="">
                <span>{{$VBanner("开发DePIN网_2c3f")}}</span>
              </div>
            </div>
            <div class="btn-box">
              <Button link="https://eonchain.xyz">
                <span class="text-ffffff">{{$VBanner("Eon网站")}}</span>
              </Button>
              <Button
                link="https://wodswap.io/#/swap?use=v2&inputCurrency=0xe8a4e1c10371d5a9eea7a8baa09c3186bfe11f14">
                <span class="text-ffffff">{{$VBanner("交易Eon")}}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </a-carousel>
</template>


<script>
import Button from '@/components/Button.vue';
import BannerBridge from '@/components/banner/BannerBridge.vue'
import BannerFaucet from '@/components/banner/BannerFaucet.vue'
import Language from '@/components/common/Language.vue';

    /* viai18n-disable */
    import $VBannerMessages from "./Banner.messages.json";
  const $defaultObject = {
  components: {
    Button,
    Language,
    BannerBridge,
    BannerFaucet
  },
  data() {
    return {
      animation: {
        high: 'https://wami-wod.oss-cn-beijing.aliyuncs.com/flac.mp4',
        default: 'https://wami-wod.oss-cn-beijing.aliyuncs.com/04_0000.mp4'
      }
    }
  },
  mounted() {
    console.log(this.$device);
    this.$refs.video.playbackRate = 0.5;
  }
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VBanner(key,origin){
      const messages = $VBannerMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VBanner(key,origin){
      const messages = $VBannerMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss">
.ant-carousel {
  .slick-slide {
    height: 100% !important;
  }

  .slick-dots {
    li {
      width: 12px;
      height: 12px;
      margin: 0 6px !important;

      button {
        width: 100% !important;
        height: 100% !important;
        border-radius: 50% !important;
        background-color: #fff !important;
        opacity: 1 !important;
      }
    }

    .slick-active {
      width: 12px;
      height: 12px;
      border-radius: 50% !important;

      button {
        border-radius: 50% !important;
        background-color: #253BFF !important;
      }
    }
  }
}

.c-banner {
  overflow: hidden;
  width: 100%;
  height: 100%;

  .banner-content {
    overflow: hidden;
    // min-width: 1200px;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;

    .video {
      max-width: 90%;
      max-height: 650px;
      position: relative;
      left: 30%;

      @screen md {
        position: absolute;
        transform: scale(1.5);
        top: 6%;
        left: 40%;
        z-index: 1;
        mix-blend-mode: screen;
        point-events: none;
      }

      @media screen and (min-width: 2070px) {
        left: 20%;
      }
      @media screen and (min-width: 1660px) {
        max-height: 100%;
        max-width: 100%;
        .eon-box {
          padding-left: 75px;
        }
      }
    }

    .banner-text-content {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      color: #ffffff;
      /* display: flex;
      align-items: center; */
      padding-left: 75px;
      /* padding-top: 100px; */
      z-index: 100;

      .c-button {
        &.ru {
          width: 300px;

          &:hover {
            width: 350px;

            @screen md {
              width: 300px;
            }
          }
        }
      }

      .inner-text {
        max-width: 50%;
        padding-top: 100px;

        @screen md {
          max-width: 65%;
          padding-top: 83px;
        }
      }

      @media screen and (min-width: 2070px) {
        padding-left: 0;
      }

      @media screen and (max-width: 1180px) {
        /* padding-left: 0; */
        padding-top: 100px;
        .inner-text {
          padding-top: 0;
        }
      }

      .text {
        font-size: 86px;
        font-weight: bold;
        line-height: 92px;


        @media screen and (max-width: 1440px) {
          font-size: 60px;
          line-height: 66px;
        }

        .text-slogan {
          background-image: linear-gradient(to right, #33C4F2, #1F35FD);
          background-clip: text;
          color: transparent;
          display: inline-block;
        }
      }

      .desc {
        margin-top: 40px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 95px;
        max-width: 950px;
        word-break: break-word;

        @screen md {
          margin-bottom: 80px;
        }
      }

      .dot-wrap {
        margin-top: 127px;

        @screen md {
          margin-top: 69px;
        }

        .dot {
          width: 12px;
          height: 12px;
          display: block;
          background-color: #ffffff;
          border-radius: 12px;
          margin-bottom: 12px;

          &.active {
            background-color: #253bff;
          }
        }
      }

    }
  }

  .eon-content {
    overflow: hidden;
    width: 100%;
    /* height: 100%; */
    height: 1080px;
    position: relative;
    z-index: 3;
    color: #fff;
    margin: 0 auto;

    video {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .eon-box {
      max-width: 1920px;
      margin: 0 auto;
      padding-top: 238px;
      padding-left: 75px;

      &::after {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
        content: "";
        width: 100%;
        height: 340px;
        background: linear-gradient(0deg, #000 0%, rgba(5, 6, 6, 0) 100%);
        filter: blur(0px);
        -webkit-filter: blur(0px);
      }

      .eon-pc {
        position: relative;
        z-index: 1;

        .title-box {
          margin-bottom: 40px;

          h1 {
            display: inline-block;
            font-size: 72px;
            font-weight: 800;
            margin: 0;
            line-height: 92px;
            color: #fff;

            span {
              display: inline-block;
              font-size: 72px;
              font-weight: 800;
              line-height: 92px;
              background-image: linear-gradient(to right, #33C4F2, #1F35FD);
              background-clip: text;
              color: transparent;
            }

            .eon-txt {
              color: #3FDD78;
            }
          }
        }

        .details-box {
          display: flex;
          flex-direction: column;
          gap: 24px;
          margin-bottom: 48px;

          .details-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
              width: 24px;
              margin-right: 8px;
            }

            span {
              font-size: 24px;
              color: rgba(255, 255, 255, 0.80);
              font-weight: 600;
            }
          }
        }

        .btn-box {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 80px;
        }
      }

      .eon-mobile {
        position: relative;
        z-index: 1;

        .title-box {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;

          h1 {
            display: inline-block;
            font-size: 32px;
            font-weight: 800;
            margin: 0;
            line-height: 32px;
            color: #fff;
            margin-bottom: 8px;

            span {
              display: inline-block;
              font-size: 32px;
              font-weight: 800;
              line-height: 32px;
              background-image: linear-gradient(to right, #33C4F2, #1F35FD);
              background-clip: text;
              color: transparent;
            }

            .eon-txt {
              color: #3FDD78;
            }
          }
        }

        .details-box {
          display: flex;
          max-width: 248px;
          flex-direction: column;
          gap: 8px;
          margin-bottom: 32px;

          .details-item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            img {
              width: 16px;
              margin-right: 8px;
            }

            span {
              font-size: 12px;
              color: rgba(255, 255, 255, 0.80);
              font-weight: 600;
              line-height: 16px;
            }
          }
        }

        .btn-box {
          padding: 0 12px 0 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 26px;
        }
      }
    }
    
    @media screen and (min-width: 2070px) {
      .eon-box {
        padding-left: 0;
      }
    }
    @media screen and (max-width: 1660px) {
      height: 790px;
      .eon-box {
        padding-left: 75px;
      }
    }

    @media screen and (max-width: 1440px) {
      height: 650px;

      .eon-box {
        padding-top: 100px;
        padding-left: 75px;
        .eon-pc {
          .title-box {
            h1 {
              font-size: 52px;
              line-height: 52px;

              span {
                line-height: 52px;
                font-size: 52px;
              }
            }
          }

          .details-box {
            display: flex;
            gap: 8px;
          }
        }

        .eon-mobile {
          display: none;
        }
        &::after {
          height: 575px;
        }
      }
    }
    @media screen and (max-width: 965px) {
      height: 575px;
      .eon-box {
        padding-top: 100px;
        padding-left: 12px;
        &::after {
          height: 575px;
        }
      }
    }
  }
}
</style>