<template>
  <div class="home">
    <Header></Header>
	<MedalContent></MedalContent>

    <Introduce></Introduce>
    
    <Dapp></Dapp>
    
    <Partnership></Partnership>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

import Introduce from '@/components/home/Introduce.vue';
import Dapp from '@/components/home/Dapp.vue';
import Solution from '@/components/home/Solution.vue';
import Partnership from '@/components/home/Partnership.vue';
import Productivity from '../components/home/Productivity.vue';
import MedalContent from '@/components/medalWall/MedalContent.vue';
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Introduce,
    Solution,
    Productivity,
    Partnership,
    Header,
    Footer,
	MedalContent,
    Dapp
  }
}
</script>

<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

.home {
  position: relative;
  background-color: $bacColor;
  color: $fontcolor;
  .banner-content-box {
    min-height: 100vh;
    @screen md {
      min-height: 100vh;
    }
  }
  .static-bg-1 {
    position: absolute;
    top: -99px;
    left: 0;
    z-index: 0;
  }
}

</style>
