<template>
	<div class="all-medal-content md:flex-col md:gap-16">
		<div class="content-menu md:w-full md:min-w-full md:max-w-full md:flex-row">
			<div class="menu-item md:w-auto md:px-20 md:py-2 md:h-36 "
				:class="{ 'menu-item-active': active === 'yz' }" @click="changeActive('yz')">

				<span>{{$VVisaContent("亚洲签证")}}</span>
			</div>
			<div class="menu-item md:w-auto md:px-20 md:py-2 md:h-36 "
				:class="{ 'menu-item-active': active === 'oz' }" @click="changeActive('oz')">
				<span>{{$VVisaContent("欧洲签证")}}</span>
			</div>
			<div class="menu-item md:w-auto md:px-20 md:py-2 md:h-36 "
				:class="{ 'menu-item-active': active === 'bm' }" @click="changeActive('bm')">
				<span>{{$VVisaContent("北美签证")}}</span>
			</div>
			
		</div>
		<div class="content-main md:grid md:grid-cols-1">
			<template v-for="(item, index) in medalList" >
				<visaCard :item="item"></visaCard>
			</template>
		</div>
		</div>
</template>

<script>
	import {  getConfig } from '@/utils/active.api.js'
	import { mapState } from 'vuex'
	import visaCard from '../../components/visa/visaCard.vue';

	
    /* viai18n-disable */
    import $VVisaContentMessages from "./VisaContent.messages.json";
  const $defaultObject = {
		components: { visaCard},

		data() {
			return {
				popupItem: {},
				active: 'yz',
			};
		},
		computed: {
			...mapState(['isConnect', 'address', 'sbtMedal']),
			medalList() {

				
				if(this.active == 'yz'){
					return {
						'hgqz': {
							"SBTName": this.$VVisaContent("韩国签证","韩国签证"),
							"SBTIntro": this.$VVisaContent("个人旅游签证、商_d329","个人旅游签证、商务签证、参加各种活动及会议"),
							"Icon": 'img/visa/hgqz.jpg',
							"condition_name": this.$VVisaContent("个人旅游签证","个人旅游签证"),
							"condition":[
								{key:'2',value:this.$VVisaContent("1、护照原件","1、护照原件")},
								{key:'2',value:this.$VVisaContent("2、身份证正反面_1da7","2、身份证正反面复印件")},
								{key:'3',value:this.$VVisaContent("3、白底彩色照片","3、白底彩色照片")},
								{key:'4',value:this.$VVisaContent("4、财产证明","4、财产证明")},
								{key:'5',value:this.$VVisaContent("5、6个月以上的_77b0","5、6个月以上的银行流水原件")}
							],
							"flowpath_name": this.$VVisaContent("商务签证","商务签证"),
							"flowpath":[
								{key:'1',value:this.$VVisaContent("1、邀请函+邀请_bc4a","1、邀请函+邀请单位营业执照传真件或扫描件")},
								{key:'1',value:this.$VVisaContent("2、护照原件","2、护照原件")},
								{key:'1',value:this.$VVisaContent("3、白底彩色照片","3、白底彩色照片")},
								{key:'1',value:this.$VVisaContent("4、身份证复印件","4、身份证复印件")},
								{key:'1',value:this.$VVisaContent("5、在职证明","5、在职证明")},
								{key:'1',value:this.$VVisaContent("6、营业执照复印_0639","6、营业执照复印件并加盖公司公章")},
								{key:'1',value:this.$VVisaContent("7、银行流水、打_c737","7、银行流水、打半年")},
								{key:'1',value:this.$VVisaContent("8、财产证明","8、财产证明")},
							]
						},
						'rbqz': {
							"SBTName": this.$VVisaContent("日本签证","日本签证"),
							"SBTIntro": this.$VVisaContent("个人旅游签证","个人旅游签证"),
							"Icon": 'img/visa/rbqz.jpg',
							"condition_name": this.$VVisaContent("个人旅游签证","个人旅游签证"),
							"condition":[
								{key:'2',value:this.$VVisaContent("1、护照原件","1、护照原件")},
								{key:'2',value:this.$VVisaContent("2、白底2寸彩照","2、白底2寸彩照")},
								{key:'3',value:this.$VVisaContent("3、身份证、户口_5e2f","3、身份证、户口本复印件")},
								{key:'4',value:this.$VVisaContent("4、在职证明原件_05be","4、在职证明原件/学生复印件/退休证复印件/提供夫妻或者父母其中一方的在职证明及所在单位营业执照副本的复印件加盖公章")},
								{key:'5',value:this.$VVisaContent("6、房产证复印件_b670","6、房产证复印件+银行近6个流水原件")}
							],
							"flowpath_name": "",
							"flowpath":[
		
							]
						}
					}
				}else if(this.active == 'oz'){
					return {
						'fgqz': {
							"SBTName": this.$VVisaContent("法国签证","法国签证"),
							"SBTIntro": this.$VVisaContent("商务签证/探亲/_222a","商务签证/探亲/访友签证"),
							"Icon": 'img/visa/fgqz.jpg',
							"condition_name": this.$VVisaContent("签证材料","签证材料"),
							"condition":[
								{key:'2',value:this.$VVisaContent("1、新旧护照原件","1、新旧护照原件")},
								{key:'2',value:this.$VVisaContent("2、白底彩色照片","2、白底彩色照片")},
								{key:'3',value:this.$VVisaContent("3、申根签证个人_c92c","3、申根签证个人信息表")},
								{key:'4',value:this.$VVisaContent("4、申根国家签证_9a3a","4、申根国家签证保险单原件")},
								{key:'5',value:this.$VVisaContent("5、机票订单","5、机票订单")},
								{key:'5',value:this.$VVisaContent("6、申请人近6个_34bc","6、申请人近6个月银行流水原件")},
								{key:'5',value:this.$VVisaContent("7、单位在职证明","7、单位在职证明")},
								{key:'5',value:this.$VVisaContent("8、单位营业执照_a566","8、单位营业执照副本或组织机构代码证副本复印件加盖单位公章")},
								{key:'5',value:this.$VVisaContent("9、详细行程单","9、详细行程单")},
							],
							"flowpath_name": this.$VVisaContent("特定材料","特定材料"),
							"flowpath":[
								{key:'1',value:this.$VVisaContent("1、财产证明文件","1、财产证明文件")},
								{key:'1',value:this.$VVisaContent("2、户口本全本复_93c4","2、户口本全本复印件")},
							]
						},
						'ygqz': {
							"SBTName": this.$VVisaContent("英国签证","英国签证"),
							"SBTIntro": this.$VVisaContent("个人旅游签证/探_cc9d","个人旅游签证/探亲访友签证/商务签证"),
							"Icon": 'img/visa/ygqz.jpg',
							"condition_name": this.$VVisaContent("签证材料","签证材料"),
							"condition":[
								{key:'1',value:this.$VVisaContent("1、新旧护照原件","1、新旧护照原件")},
								{key:'2',value:this.$VVisaContent("2、户口本全本复_93c4","2、户口本全本复印件")},
								{key:'3',value:this.$VVisaContent("3、结婚证复印件_0172","3、结婚证复印件（或离婚证）")},
								{key:'5',value:this.$VVisaContent("4、资产证明：近_1fc6","4、资产证明：近6个月银行流水原件、房产证、行驶证复印件")}
							],
							"flowpath_name": this.$VVisaContent("特定材料","特定材料"),
							"flowpath":[
								{key:'1',value:this.$VVisaContent("1、单位在职证明","1、单位在职证明")},
								{key:'1',value:this.$VVisaContent("2、单位营业执照_c53e","2、单位营业执照副本复印件加盖单位公章")},
								{key:'1',value:this.$VVisaContent("3、英国亲友邀请_7bd2","3、英国亲友邀请函扫描件")},
								{key:'1',value:this.$VVisaContent("4、邀请人在英国_c015","4、邀请人在英国的身份证明文件")},
								{key:'1',value:this.$VVisaContent("5、资金证明、申_7553","5、资金证明、申请人最近6个月银行流水原件")},
								{key:'1',value:this.$VVisaContent("6、单位派遣函原_9615","6、单位派遣函原件一份")},
							]
						}
					}
				}else if(this.active == 'bm'){
					return {
						'mgqz': {
							"SBTName": this.$VVisaContent("美国签证","美国签证"),
							"SBTIntro": this.$VVisaContent("商务签证(B1)_d2f1","商务签证(B1)/学生签证（F1)/交流访问签证(J1)/探亲访友(B2)"),
							"Icon": 'img/visa/mgqz.jpg',
							"condition_name": this.$VVisaContent("签证材料","签证材料"),
							"condition":[
								{key:'1',value:this.$VVisaContent("1、新旧护照原件","1、新旧护照原件")},
								{key:'2',value:this.$VVisaContent("2、白底彩色相片","2、白底彩色相片")},
								{key:'3',value:this.$VVisaContent("3、个人信息表","3、个人信息表")},
								{key:'5',value:this.$VVisaContent("4、预约单","4、预约单")},
								{key:'5',value:this.$VVisaContent("5、身份证原件","5、身份证原件")},
							],
							"flowpath_name": this.$VVisaContent("辅助文件","辅助文件"),
							"flowpath":[
								{key:'1',value:this.$VVisaContent("1、近半年银行流_f0b0","1、近半年银行流水明细")},
								{key:'1',value:this.$VVisaContent("2、定期存折和定_fec5","2、定期存折和定期存单原件")},
								{key:'1',value:this.$VVisaContent("3、房产证原件","3、房产证原件")},
								{key:'1',value:this.$VVisaContent("4、商务签证(B_9f30","4、商务签证(B1)、提供邀请函、商务日程等")},
								{key:'1',value:this.$VVisaContent("5、学生签证（F_be1d","5、学生签证（F1)、需提供I20表、SEVIS费用收据等")},
								{key:'1',value:this.$VVisaContent("6、交流访问学者_b51f","6、交流访问学者签证(J1)、需提供DS2019表、SEVIS费用收据等文件")},
								{key:'1',value:this.$VVisaContent("6、以探亲访友为_8c00","6、以探亲访友为目的(B2)：亲属邀请函；亲属在美身份证明；社安卡复印件等。根据亲属在美的不同身份、则需要提供的资料略有不同")},
							]
						},
						'jndqz': {
							"SBTName": this.$VVisaContent("加拿大签证","加拿大签证"),
							"SBTIntro": this.$VVisaContent("个人旅游签证/探_cc9d","个人旅游签证/探亲访友签证/商务签证"),
							"Icon": 'img/visa/jndqz.jpg',
							"condition_name": this.$VVisaContent("签证材料","签证材料"),
							"condition":[
								{key:'1',value:this.$VVisaContent("1、新旧护照原件","1、新旧护照原件")},
								{key:'2',value:this.$VVisaContent("2、户口本全本复_93c4","2、户口本全本复印件")},
								{key:'3',value:this.$VVisaContent("3、结婚证复印件_0172","3、结婚证复印件（或离婚证）")},
								{key:'5',value:this.$VVisaContent("4、资产证明：近_1fc6","4、资产证明：近6个月银行流水原件、房产证、行驶证复印件")}
							],
							"flowpath_name": this.$VVisaContent("额外材料","额外材料"),
							"flowpath":[
								{key:'1',value:this.$VVisaContent("1、单位在职证明","1、单位在职证明")},
								{key:'1',value:this.$VVisaContent("2、单位营业执照_c53e","2、单位营业执照副本复印件加盖单位公章")},
								{key:'1',value:this.$VVisaContent("3、与加拿大邀请_d7be","3、与加拿大邀请人关系证明文件")},
								{key:'1',value:this.$VVisaContent("4、加拿大邀请人_ee7f","4、加拿大邀请人身份证明复印件")},
								{key:'1',value:this.$VVisaContent("5、资金证明、申_7553","5、资金证明、申请人最近6个月银行流水原件")},
								{key:'1',value:this.$VVisaContent("6、如加拿大邀请_c343","6、如加拿大邀请人为学生、则需要校方出具一份录取信函原件、即已登记注册证明或在读证明")},
							]
						}
					}
				}else if(this.active == 'qt'){
					return []
				}
			},
		},
		created() {
			this.active = this.$route.query.type || 'yz'
			this.getMedalWall()
		},
		mounted() {
			this.init()
		},
		watch: {
			'$route.query': {
				handler(newQuery, oldQuery) {
					if (this.$route.query.type) {
						this.active = this.$route.query.type
						this.getMedalWall()
					}
					const currentRoute = this.$route;
					if (currentRoute.query.type) {
						this.$router.replace({
							query: {} // 更新后的查询参数对象
						});
					}
				},
				immediate: true, // 是否立即执行回调
				deep: true, // 是否深度监听
			},
		},
		methods: {
			init(){
				getConfig();
				
			},
			changeActive(active) {
				this.active = active;
			},
			
			getMedalWall() {
				console.log(this.active)
			}
		},
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VVisaContent(key,origin){
      const messages = $VVisaContentMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VVisaContent(key,origin){
      const messages = $VVisaContentMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件



	.all-medal-content {
		/* max-width: 1590px; */
		margin: auto;
		overflow: hidden;
		position: relative;
		z-index: 1;
		min-height: calc(100vh - 80px);
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 50px 75px 80px;
		display: flex;
		gap: 40px;

		@screen md {
			padding: 16px;
		}

		.content-menu {
			/* margin-right: 40px; */
			width: 196px;
			min-width: 196px;
			max-width: 196px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;


			.menu-item {
				cursor: pointer;
				width: 100%;
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 12px;
				border-radius: 2px;
				transition: all .3s;
				

				img {
					width: 24px;
					margin-right: 16px;
				}

				span {
					display: inline-block;
					color: $fontcolor;
					font-size: 16px;
					font-style: normal;
					font-weight: 900;
					line-height: 16px;
					/* 100% */
					letter-spacing: -0.4px;
				}
			}

			.menu-item-active {
				box-shadow: $box_shadow;
				background: $jbfontbacColor;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				border-radius: $bt_bo_radius;
			}
		}

		.content-main {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			gap: 24px;

			.sbt-item {
				width: 283px;
				/* height: 336px; */
				border-radius: 6px;
				border: 1px solid rgba(255, 255, 255, 0.10);
				box-shadow: $box_shadow;
				
				overflow: hidden;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				position: relative;
				z-index: 0;

				.isAcquire {
					position: absolute;
					right: 5%;
					top: 5%;
					width: 38px;
					height: 38px;

					@screen md {
						width: 28px;
						height: 28px;

					}
				}

				.nft-img {
					border-radius: 6px;
					overflow: hidden;
					width: 281px;
					/* height: 281px; */
					/* min-height: 281px;
					max-height: 281px; */

					img {
						border-radius: 6px;
						width: 100%;
						/* height: 100%; */
						object-fit: cover;
					}
				}

				.nft-name {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 54px;

					.isKt {
						margin-right: 10px;
						width: 24px;
						height: 24px;
					}

					span {
						color: $fontcolor;
						text-align: center;
						font-size: 14px;
						font-style: normal;
						font-weight: 590;
						line-height: 14px;
						/* 100% */
						letter-spacing: -0.4px;
					}
				}

				.nft-name-md {
					display: none;
					color: $fontcolor;

					.nft-detail-md {
						padding: 0 8px 8px;

						h1 {
							.isKt {
								margin-right: 10px;
								width: 24px;
								height: 24px;
							}

							color: $fontcolor;
							font-size: 14px;
							font-weight: 700;
							line-height: 16px;
							/* 100% */
							letter-spacing: -0.4px;
						}

						p {
							margin: 0;
							color: $fontcolor;
							font-size: 12px;
							font-weight: 400;
							line-height: 16px;
							/* 133.333% */
							letter-spacing: -0.8px;
						}
					}

					.nft-claim-md {
						width: 100%;
						height: 36px;
						display: flex;
						align-items: center;
						justify-content: center;

						span {
							display: inline-block;
							width: 100%;
							height: 100%;
							text-align: center;
							line-height: 36px;
							border-radius: 0px 0px 16px 16px;
							border: 1px solid rgba(255, 255, 255, 0.10);
							background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
							border: none;
						}
					}
				}

				.detail-btn {
					width: 100%;
					position: absolute;
					bottom: -142px;
					left: 0;
					z-index: 2;
					overflow: hidden;
					transition: all .3s;

					.detail-box {
						height: 100px;
						padding: 16px 12px;
						border: 1px solid rgba(255, 255, 255, 0.10);
						border-radius: 16px 16px 0px 0px;
						border-left: none;
						border-right: none;
						border-bottom: none;
						background: rgba(255, 255, 255, 0.09);
						backdrop-filter: blur(15px);

						span {
							color: $fontcolor;
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 14px;
							border: none;
							/* 100% */
						}

						p {
							margin: 0;
							margin-top: 10px;
							color: $fontcolor;
							font-size: 14px;
							font-style: normal;
							font-weight: 300;
							line-height: 18px;
							/* 128.571% */
						}
					}

					.btn-box {
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 42px;
						border-radius: 0px 0px 16px 16px;
						background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);

						/* backdrop-filter: blur(15px); */
						span {
							display: inline-block;
							width: 100%;
							height: 100%;
							color: $fontcolor;
							text-align: center;
							font-size: 14px;
							font-style: normal;
							font-weight: 590;
							line-height: 42px;
							/* 100% */
							letter-spacing: -0.4px;
						}
					}
				}

				&:hover {
					.detail-btn {
						bottom: 0;
						/* transform: translateY(0); */
					}
				}
			}
		}
	}
</style>