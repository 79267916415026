<template>
  <div class="cost-performance">
    <Header></Header>

    <div class="cost-content md:px-12">
      <Breadcrumb :routes="routes"></Breadcrumb>
      <div class="table-box">
        <ExcellentTable></ExcellentTable>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import ExcellentTable from '@/components/excellent/ExcellentTable.vue'

export default {
  name: 'Excellent',
  components: {
    Header,
    Footer,
    Breadcrumb,
    ExcellentTable
  },
  data() {
    return {
      routes: [
        {
          path: '/',
          breadcrumbName: 'Home',
        },
        {
          path: '/Excellent',
          breadcrumbName: 'Excellent Team',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.cost-performance {
  color: #fff;
  .cost-content {
    min-height: 700px;
    padding: 14px 75px;
    .table-box {
      max-width: 1200px;
      margin: auto;
      @screen md {
        margin-top: 30px;
      }
    }
  }
  @screen md {
    /* padding-top: 55px; */
  }
}
</style>