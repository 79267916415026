<template>
	<div class="campaign-box">
		<div class="campaign-card campaign-card-1 ">
			<img class="bimg" src="/img/case/b_1.png" alt="" srcset="" />
		</div>
		
		<div class="campaign-card-2-box flex-between" v-for="(item, index) in teamList" :key="item.key">
			<div class="campaign-card" :class="index_==0? 'campaign-card-2':'campaign-card-3'"  v-for="(item_, index_) in item" :key="item_.key">
				<div class="campaign-card-img">
					<img  :src="item_.lvimg" alt="" srcset="" />
				</div>
				
				<div class="campaign-card-name">
					<p class="card-1-1">{{ item_.name }}</p>
					<p class="card-1-3">
						{{ item_.good_at }}
					</p>
				</div>

			</div>
		</div>
		
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	
    /* viai18n-disable */
    import $VcaseFromMessages from "./caseFrom.messages.json";
  const $defaultObject = {
		name: 'Name',
		data() {
			return {

			}
		},
		computed: {
			teamList() {
				return [
					[
						{ name: this.$VcaseFrom("成功案例-G女士_459a","成功案例-G女士希腊粉卡"),lvimg:'/img/case/1.jpg', good_at: this.$VcaseFrom("客户G女士，33_5fd3","客户G女士，33岁，本科，需求自由通行29个欧洲申根国和老人养老问题，故考虑希腊粉卡项目。") },
						{ name: this.$VcaseFrom("成功案例-H先生_aec2","成功案例-H先生希腊粉卡"),lvimg:'/img/case/2.jpg', good_at: this.$VcaseFrom("客户H先生，27_ddbf","客户H先生，27岁，本科。考虑自由通行欧洲和父母养老，故考虑希腊身份办理。") },
					],
					[
						{ name: this.$VcaseFrom("成功案例-W先生_068d","成功案例-W先生圣卢西亚护照"),lvimg:'/img/case/3.png', good_at: this.$VcaseFrom("客户W先生，43_cc46","客户W先生，43岁，研究生学历，企业主。主要想出行自由方便，故考虑圣卢西亚护照。") },
						{ name: this.$VcaseFrom("成功案例-W先生_aa11","成功案例-W先生香港身份"),lvimg:'/img/case/4.jpg', good_at: this.$VcaseFrom("客户W先生，40_e16b","客户W先生，40岁，浙江大学博士，骨科副主任医师，想让孩子未来参加华侨生考试，故考虑做香港高才通计划。") },
					],
					[
						{ name: this.$VcaseFrom("成功案例-Q女士_5a13","成功案例-Q女士新加坡自雇EP"),lvimg:'/img/case/5.jpg', good_at: this.$VcaseFrom("客户Q女士，39_8271","客户Q女士，39岁，武汉大学本科，企业主。客户主要想资产分散，同时考虑孩子出国读书。故匹配新加坡PIC自雇EP项目方案。") },
						{ name: this.$VcaseFrom("成功案例-Z女士_c6d7","成功案例-Z女士加拿大枫叶卡"),lvimg:'/img/case/6.jpg', good_at: this.$VcaseFrom("客户Z女士，40_2665","客户Z女士，40岁，研究生学历，雅思CLB5，企业行政人员。主要想让孩子接受加拿大教育，故考虑加拿大枫叶卡办理。") },
					],
				]
			},
		},
		mounted() {

		},
		methods: {

		}
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VcaseFrom(key,origin){
      const messages = $VcaseFromMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VcaseFrom(key,origin){
      const messages = $VcaseFromMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

	.bimg{
		width:100%;
		height:100%;
		border-radius: 10px;
	}

	/*头部*/
	.campaign-box {
		position: relative;
		color: $fontcolor;
		
		.campaign-card {
			
			flex: 1;
			//border: 2px solid rgba(46, 42, 65, 1);
			border-radius: 20px;
			position: relative;
			top: 0;
			text-align: center;
			//padding: 20px 0 0px;
			
			
			/*
			background: radial-gradient(67.76% 72.76% at 50% 10.33%, #000000 23%, rgba(4, 1, 19, 0.5) 66%, #3D54CA 128%),
				linear-gradient(0deg, #2E2A41, #2E2A41),
				linear-gradient(0deg, #D7CCF6 -8.91%, rgba(19, 8, 48, 0) 45.33%);
			*/
			@screen md {
				padding: 40px 0;
			}

			&:hover {
				transition: all 1s;
				top: -20px;
				&::after {
					opacity: 1;
				}
				box-shadow: $box_shadow;
			}

			.tip-box {
				position: absolute;
				top: 0px;
				right: 0px;
				.open, .closed {
					padding: 4px 15px;
		
					font-size: 16px;
					font-weight: 590;
					display: inline-block;
					border-radius: 0px 10px;
					/* border: 1px solid rgba(255, 255, 255, 0.20); */
				}
				.open {
					background: linear-gradient(131deg, #C4923E -1.25%, #E4BE68 28.42%, #F8DA83 53.61%, #FEE07E 63.92%, #F3C433 82.64%, #E0AE2D 93.52%);
				}
				.closed {
					border-radius: 0px 10px;
					background: #6c6c6c;
				}
			}

			.card-1-1 {
				margin: 0;
				text-align: center;
				
				font-size: 60px;
				font-weight: 800;
				line-height: 60px;
				letter-spacing: 0.01em;



				@screen md {
					
					font-size: 32px;
					font-style: normal;
					font-weight: 800;
					line-height: 120%;
					/* 38.4px */
					letter-spacing: 0.32px;
				}

			}

			.card-1-2 {
				padding: 10px;

				img {
					width: 32px;
					height: 32px;
					margin-right: 10px;
				}

				span {
					font-size: 26px;
					font-weight: 590;
					line-height: 40px;
					letter-spacing: 0px;
					text-align: left;


				}

				@screen md {
					padding: 16px 0;

					img {
						width: 20px;
						height: 20px;
					}

					span {

						
						font-size: 16px;
						font-style: normal;
						font-weight: 590;
						line-height: 24px;
						/* 150% */
					}
				}
			}

			.card-1-3 {
				padding: 10px;
				//padding-bottom: 80px;
				
				font-size: 24px;
				font-weight: 510;
				line-height: 29px;
				letter-spacing: 0px;
				text-align: center;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				
				margin: 0;

				@screen md {
					padding-bottom: 40px;

					
					font-size: 16px;
					font-style: normal;
					font-weight: 510;
					line-height: normal;
				}

			}

			.ant-btn-primary {
				position: absolute; /* 绝对定位 */
				bottom: 10px; /* 距离容器底部为0像素 */
				left: 50%; /* 左边界与容器宽度的一半重合 */
				transform: translateX(-50%); /* 向左移动自身宽度的一半，达到水平居中*/
				
				width: 420px;
				height: 56px;
				box-sizing: border-box;
				border-radius: 100px;
				line-height: 56px;
				border: 0;
				background: $bt_bg_color;

				
				font-size: 24px;
				font-weight: 700;

				@screen md {
					width: 240px;
					height: 42px;
					line-height: 42px;
					font-size: 14px;
				}
				
				&:hover {
					box-shadow: $box_shadow;
					background: $jbfontbacColor;
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					font-weight: bold;
				}
			}


			.campaign-card-img{
				position:relative;
				width:100%;
				height:100%;
				top:0px;
				left:0px;
				border-radius: 100px;

				img{
					width:100%;
					height:100%;
					border-radius: 10px;
				}
			}
		}

	}
	
	/*列表*/
	.campaign-card-2-box {
		margin-top: 60px;
		

		.campaign-card-2,
		.campaign-card-3 {
			padding: 0 0 60px 0;
			box-shadow: $box_shadow;
			
			@screen md {
				box-shadow: $box_shadow;
			}
			
			.card-1-1 {
				margin: 5px;
				font-size: 16px;
				font-weight: 800;
				line-height: 20px;
				letter-spacing: 0em;
				text-align: center;


				span:nth-of-type(1) {
					background-image: linear-gradient(to right, #33C4F2, #1F35FD);
					-webkit-background-clip: text;
					background-clip: text;
					color: transparent;
				}



				@screen md {

					
					font-size: 26px;
					font-style: normal;
					font-weight: 800;
					line-height: 120%;
					/* 38.4px */
				}
			}

			.card-1-2 {
				padding: 20px 0;

				img {
					width: 40px;
					height: 40px;
					border-radius: 100px;

				}

				span {
					
					font-size: 16px;
					font-weight: 800;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: left;

					background-image: linear-gradient(to right, #33C4F2, #1F35FD);
					-webkit-background-clip: text;
					background-clip: text;
					color: transparent;
				}

				@screen md {
					padding: 16px 0;

					img {
						width: 24px;
						height: 24px;
					}

					span {
						
						font-size: 16px;
						font-style: normal;
						font-weight: 800;
						line-height: 120%;
						/* 24px */
					}
				}
			}

			.card-1-3 {
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0px;

				@screen md {
					margin: 0;
					padding: 5px;

					
					font-size: 16px;
					font-style: normal;
					font-weight: 510;
					line-height: normal;
				}

			}

			.ant-btn-primary {
				position: absolute; /* 绝对定位 */
				bottom: 10px; /* 距离容器底部为0像素 */
				left: 50%; /* 左边界与容器宽度的一半重合 */
				transform: translateX(-50%); /* 向左移动自身宽度的一半，达到水平居中*/
			
				width: 150px;
				height: 40px;
				box-sizing: border-box;
				border-radius: 150px;
				line-height: 40px;
				border: 0;
				background: $bt_bg_color;

				
				font-size: 16px;
				font-weight: 700;

				@screen md {
					width: 240px;
					height: 42px;
					line-height: 42px;
					font-size: 14px;
				}
				
				&:hover {
					box-shadow: $box_shadow;
					background: $jbfontbacColor;
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					font-weight: bold;
				}
			}

		}

		.campaign-card-2 {

			//border: unset;
			//background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/FAUCET-box1.png') no-repeat;
			background-size: 100% 100%;
			// background: radial-gradient(89.29% 89.29% at 49.94% 15.38%, #000000 0%, rgba(4, 1, 19, 0) 39.95%, #ca9f3dbd 100%),
			// 	linear-gradient(0deg, #2E2A41, #2E2A41),
			// 	linear-gradient(0deg, #F6EACC -5.36%, rgba(48, 10, 8, 0) 54.53%);.
			
			&:hover {
				transition: all 0.5s;
				box-shadow: 0px 50px 100px 0px rgb(246,234,204 ,0.37);
				top: -10px;
			
				&::after {
					opacity: 1;
				}
				box-shadow: $box_shadow;
			}
		}

		.campaign-card-3 {

			//border: unset;
			margin-left: 34px;
			//background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/FAUCET-box2.png') no-repeat;
			background-size: 100% 100%;
			// background: radial-gradient(81.46% 81.46% at 49.94% 21.57%, #000000 0%, rgba(4, 1, 19, 0) 39.26%, #62ca3d69 100%),
			// 	linear-gradient(0deg, #2E2A41, #2E2A41),
			// 	linear-gradient(0deg, #E6F6CC -7.14%, rgba(19, 8, 48, 0) 50%);
			&:hover {
				transition: all 0.5s;
				box-shadow: 0px 50px 100px 0px rgb(230,246,204 ,0.43);
				top: -10px;
			
				&::after {
					opacity: 1;
				}
				box-shadow: $box_shadow;
			}
		}



		@screen md {
			margin-top: 0;
			display: flex;
			flex-direction: column;

			.campaign-card-2,
			.campaign-card-3 {
				width: 100%;
				margin-top: 20px;
				flex: unset;
			}

			.campaign-card-3 {
				margin: 0;
				margin-top: 20px;
			}
		}

		.campaign-card-name{
			border-radius: 15px;
			width:100%;
			box-shadow: $box_shadow;
			background-color: $bt_bg_color;
			color: $bt_ft_color;
			position: absolute; /* 绝对定位 */
			bottom: 0px; /* 距离容器底部为0像素 */
		}

	}
</style>