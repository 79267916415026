import router from "../../router";
import store from '../../store'
const web3 = window.web3 ? new Web3(window.ethereum) : '';
import { setAddress } from '@/utils/auth.js';
import mdialog from '@/mdialog'




export async function getSign(dataToSign = "", address = store.state.user.address ) {
  console.log("dataToSign==>", dataToSign);
  try {
    let signedMsg = await web3.eth.personal.sign(dataToSign, address);
    return signedMsg;
  } catch (e) {
    console.log(`getSign2数据进行签名-err`, e);
    return false;
  }
}




//检查环境
export function checkEnv() {
	if (!window.web3) {
		return {
			code: false,
			msg: 'no Web3'
		}
	}
	if (!ethereum) {
		return {
			code: false,
			msg: 'no ethereum'
		}
	}
	return {
		code: true,
	}
}


/**
 * 登录
 * @returns
 */
export async function enable() {
	try {
		const { code } = checkEnv()
		
		if (!code){
			mdialog.install();
			// return false;
		} 

		let account = await ethereum.request({
			method: 'eth_requestAccounts'
		});
		if (account) {
			let accounts = await web3.eth.getAccounts();
			let myAccount = accounts[0];
			
			setAddress(myAccount)
			console.log('myAccount===>', myAccount)
			store.commit('SET_ADDRESS', myAccount);
			return {
				code: true
			};
		}
	} catch (e) {
		console.log('登录err===>', e)
		if (e === "User rejected provider access") {
			//用户不想登录!  退出
			return {
				code: false
			}
		} else {
			// code:-32002    未登录
			return {
				code: false
			}
		}
	}
}

//ethereum 事件监听
export function registerListener() {
	if (!ethereum) return;
	// 链ID
	ethereum.on("chainChanged", newChainId => {
		console.log("chainChanged===>", newChainId, parseInt(newChainId));
		router.push('/');
		location.reload();
	});
	// 监听连接
	ethereum.on("connect", connectInfo => {
		console.log("connect===>", connectInfo, connectInfo.chainId, parseInt(connectInfo.chainId));
	});
	// 地址改变
	ethereum.on("accountsChanged", accounts => {
		let newAddress = "";
		if (accounts.length > 0) {
			newAddress = accounts[0];
		}
		store.commit("user/SET_ADDRESS", newAddress);
		router.push('/pres');
		location.reload();
		console.log('切换账户===>', newAddress)
	});
}

//查询网络
export async function eth_chainId({ chainIdArr }) {
	try {
		let chainId = await ethereum.request({
			method: "eth_chainId"
		});
		console.log('chainIdArr===>', chainIdArr)
		console.log(`查询网络eth_chainId===>`, chainId, parseInt(chainId))
		if (chainIdArr.includes(parseInt(chainId))) {
			return {
				code: true,
				msg: '',
				chainId: parseInt(chainId),
			}
		}
		return {
			code: false,
			msg: 'network',
			chainId: parseInt(chainId),
		}
	} catch (e) {
		return {
			code: false,
			msg: 'error'
		}
	}
}

//查询网络
export async function getChainId() {
	try {
		let chainId = await ethereum.request({
			method: "eth_chainId"
		});
		console.log(`查询网络eth_chainId===>`, chainId, parseInt(chainId))
		return parseInt(chainId)
	} catch (e) {
		console.log(e)
		return {
			code: false,
			msg: 'error'
		}
	}
}


/**
 * 获取区块时间
 * @returns  时间搓（S）
 */
export async function getBlockTimestamp() {
	try {
		let num = await web3.eth.getBlockNumber();
		let res = await web3.eth.getBlock(num);
		console.log('getBlockTimestamp===>', res)
		return res.timestamp
	} catch (error) {
		console.log('获取区块时间--->', error)
	}

}

/**
 * 获取交易详情
 */
export async function getTranDetail(transactionHash) {
	try {
		let transaction = await web3.eth.getTransaction(transactionHash);
		if (!transaction) {
			transaction = await web3.eth.getTransaction(transactionHash);
		}
		let transactionReceipt = await web3.eth.getTransactionReceipt(transactionHash);
		if (!transaction) return;
		transaction = Object.assign(transaction, transactionReceipt);
		console.log('获取交易详情===>', transaction)
		return transaction;
		// if (Object.keys(transaction["logs"]).length == 0) {
		//   console.log('暂无logs');
		//   return {};
		// }
		// const { topics, data } = transaction["logs"].pop();
	} catch (error) {
		console.log('获取交易详情--->', error);
	}
}


export async function disconnect() {
	try {
		if (!ethereum) return false;
		web3.currentProvider.disconnect()
		
	} catch (e) {
		console.log('登录err===>', e)
		if (e === "User rejected provider access") {
			//用户不想登录!  退出
			return {
				code: false
			}
		} else {
			// code:-32002    未登录
			return {
				code: false
			}
		}
	}
}

export const connectWallet = async () => {
	if (window.ethereum) { //check if Metamask is installed
		try {
			const address = await window.ethereum.enable(); //connect Metamask
			const obj = {
				connectedStatus: true,
				status: "",
				address: address
			}
			return obj;

		} catch (error) {
			return {
				connectedStatus: false,
				status: "🦊 Connect to Metamask using the button on the top right."
			}
		}

	} else {
		return {
			connectedStatus: false,
			status: "🦊 You must install Metamask into your browser: https://metamask.io/download.html"
		}
	}
};
