<template>
  <a-drawer placement="top" :closable="false" :visible="visible" @close="onClose" :zIndex="999">
    <div class="drawer-menu-list">
      <div class="menu-box">
        <div class="menu-w">
          <div class="menu-item" v-for="(item, index) in menu" :key="item.key">
            <div class="menu-item-box" v-if="activeMenu === index">
              
              <div class="menu-list-item">
                <div class="second-menu" v-for="(val, index) in item.children" :key="index">
                  <div class="menu-li">
                    <p class="second-name" v-show="val.secondName">{{ val.secondName }}</p>
                    <div class="menu-li-box" v-for="(liItem, index) in val.children" :key="liItem.key" :class="{ disabled: liItem.disabled }" @click="toPage(liItem)">
                      <span>{{ liItem.name }}</span>
                      <div v-if="liItem.new" class="dot"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-drawer>
</template>
<script>

    /* viai18n-disable */
    import $VHeadMennuMessages from "./HeadMennu.messages.json";
  const $defaultObject = {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    activeMenu: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    menu() {
      return [
        {
          name: this.$VHeadMennu("业务范围","业务范围"),
          key: 'market',
          children: [
            {
              secondName: '',
              children: [
				{ name: this.$VHeadMennu("全球移民","全球移民"), key: 'Migrate', route: 'Migrate', new: true },
				{ name: this.$VHeadMennu("全球留学","全球留学"), key: 'Study', route: 'Study', new: true  },
				{ name: this.$VHeadMennu("全球签证","全球签证"), key: 'Visa', route: 'Visa', new: true  },
				{ name: this.$VHeadMennu("全球公民身份规划","全球公民身份规划"), key: 'CitizenPassport', route: 'CitizenPassport'},
				{ name: this.$VHeadMennu("国际财税风控规划","国际财税风控规划"), key: 'TaxGuardianSpectrum', route: 'TaxGuardianSpectrum' },
				{ name: this.$VHeadMennu("子女国际教育规划","子女国际教育规划"), key: 'EdAdvantage', route: 'EdAdvantage' },
				{ name: this.$VHeadMennu("尖端医疗养老规划","尖端医疗养老规划"), key: 'MediCareFuturoNexus', route: 'MediCareFuturoNexus' },
				{ name: this.$VHeadMennu("全球资产配置规划","全球资产配置规划"), key: 'WealthNavigator', route: 'WealthNavigator' },
				{ name: this.$VHeadMennu("家族财富传承规划","家族财富传承规划"), key: 'HeritageGuardAlliance', route: 'HeritageGuardAlliance' },
              ]
            }
          ]
        },
        {
          name: this.$VHeadMennu("服务优势","服务优势"),
          key: 'ecosystem',
          children: [
            {
              secondName: '',
              children: [
				{ name: this.$VHeadMennu("全球卓越律师团队","全球卓越律师团队"), key: 'Excellent', route: 'Excellent' },
				{ name: this.$VHeadMennu("创新资产管理","创新资产管理"), key: 'Property', route: 'Property' },
              ]
            }
          ]
        },
        {
          name: this.$VHeadMennu("成功案例","成功案例"),
          key: 'Case',
          children: [
            {
              secondName: '',
              children: [
                { name: this.$VHeadMennu("成功案例","成功案例"), key: 'Case', route: 'Case'}
              ]
            }
          ]
        },
        {
          name: this.$VHeadMennu("公司简介","公司简介"),
          key: 'network',
          children: [
            {
			  secondName: '',
              children: [
                { name: this.$VHeadMennu("公司简介","公司简介"), key: 'Company', route: 'Company'},
                { name: this.$VHeadMennu("团队介绍","团队介绍"), key: 'Team', route: 'Team'},
                { name: this.$VHeadMennu("联系我们","联系我们"), key: 'Contact', route: 'Contact' },
              ]
            }
          ]
        },
      ]
    }
  },
  watch: {
    show(newVal, oldVal) {
      this.visible = this.$props.show
    }
  },
  created() {
    this.visible = this.$props.show
  },
  mounted() {

  },
  methods: {
    onClose() {
      this.$emit('onClose', false)
      this.visible = false;
    },
    toPage(item) {
      if (item.disabled) return
      if (!item.route && !item.href) return
      if (item.href) {
        window.open(item.href)
      } else {
        this.$router.push({ name: item.route })
      }
    }
  },
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VHeadMennu(key,origin){
      const messages = $VHeadMennuMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VHeadMennu(key,origin){
      const messages = $VHeadMennuMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss">
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件


.head-menu {
  width: 100%;
  height: 100%;
}

.ant-drawer-content-wrapper {
  height: auto !important;
}

.ant-drawer-content {
  background-color: transparent !important;
  color: $fontcolor;

  .ant-drawer-body {
    padding: 0;
  }

  .drawer-menu-list {
    padding-top: 80px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(28px);

    .menu-box {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 32px 0;
      .menu-w {
        min-width: 520px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .menu-item {
        display: flex;
        align-items: flex-start;
        justify-content: center;
		
        .menu-item-box {
          display: flex;
          flex-direction: column;

          h1 {
            color: rgba(255, 255, 255, 0.80);
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
            /* 100% */
            letter-spacing: 0.24px;
            margin: 0;
            margin-bottom: 32px;
          }

          .menu-list-item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 60px;
          }

          .second-menu {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .second-name {
			color: rgba(255, 255, 255, 0.80);
              font-size: 12px;
              font-weight: 600;
              line-height: 12px;
              margin: 0;
              margin-bottom: 24px;
            }

            .menu-li {
              display: flex;
              flex-direction: column;
              gap: 12px;

              span {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                cursor: pointer;
				
                &:hover {
                  background: $jbfontbacColor;
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
				  font-weight: 900;
                }
              }
              .menu-li-box {
                display: flex;
                align-items: flex-start;
                gap: 3px;
                .dot {
                  width: 6px;
                  height: 6px;
                  background-color: #4CE4F9;
                  border-radius: 50%;
                }
              }

              .disabled {
                opacity: .5;
                cursor: no-drop;

                &:hover {
                  background: rgba(255, 255, 255, 0.60);
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
				  color:red;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>