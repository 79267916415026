<template>
	<div class="mydialog">
		<transition name="model">
			<div v-show="value" @click="close" class="model_01" @touchmove.prevent @mousewheel.prevent>
				<div class="middle-box md:px-12"  @click.stop="">
					<a-icon class="iconguanbi" type="close" @click="close" />
					<div class="heads" v-if="titles">{{titles}}</div>
					<div class="content">
						<slot></slot>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		name: "mydialog",
		data() {
			return {}
		},
		watch: {},
		props: {
			//组件显隐
			value: {
				type: Boolean,
				default: false
			},
			
			titles: {
				type: String,
				default: ''
			},
		},
		mounted() {},
		methods: {
			dropFn() {
				this.$emit('nextstep');
			},
			close(state = false) {
				this.$emit('closed');
				this.$emit("input", false);
			},
		},
	}
</script>

<style scoped lang="scss">
	/*中部*/
	.middle-box {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: #1A1A1D;
		text-align: center;
		overflow: hidden;
		padding: 26px;
		box-sizing: border-box;

		min-width: 480px;
		min-height: 268px;
		box-sizing: border-box;
		
		border-radius: 20px;
		border: 1px solid rgba(255, 255, 255, .2);


		.iconguanbi {
			position: absolute;
			right: 16px;
			top: 16px;
		}
		
		
		@screen md {
			min-width: auto;
			width: 90%;
			box-sizing: border-box;
			min-height: 268px;
		}
	}
	
	

	.heads {
		
		font-weight: 400;
		color: rgba(255, 255, 255, .2);

		background: linear-gradient(20deg, #C39F59 0%, #F0EABA 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		// border-bottom: 2px solid $bdc2;
	}

	.content {
		padding: 0px 0px 20px;
		
		font-weight: 500;
		color: rgba(255, 255, 255, .6);
		// background-color: $card-color1;
	}



	/*遮罩*/
	.model_01 {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .5);
		transition: opacity .5s;
		z-index: 1001;
	}

	/*遮罩背景*/
	/*遮罩背景动画*/
	.model-enter,
	.model-leave-to {
		opacity: 0;
	}

	.model-leave-active {
		transition: opacity .5s;
	}
</style>