<template>
	<div class="teamFrom">
		<Header></Header>
		<div class="teamFrom-box md:px-12">
			<teamFrom></teamFrom>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import Breadcrumb from '@/components/common/Breadcrumb.vue'
	import teamFrom from '@/components/team/teamFrom.vue'
	import { mapState } from 'vuex'
	export default {
		name: 'Team',
		components: {
			Header,
			Footer,
			Breadcrumb,
			teamFrom,
		},
		data() {
			return {
				isShow: false,
				routes: [{
						path: '/',
						breadcrumbName: 'Home',
					},

					{
						path: '/Team',
						breadcrumbName: 'Team',
					},
				],
			}
		},
		computed: {
			...mapState(['isConnect', 'address']),
		},
		mounted() {
			// 0x09128f1081771c9ad474363ff9cf3845977e39a2&code=S0tCRkxkX1I3OGZWZ1VhZVJmZS1zMldXUWh1aUJ3Mk1JUU9hREhQVWFMQndoOjE3MDIwMzE3MjYxNDA6MTowOmFjOjE
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件


	.teamFrom {
		position: relative;
		  background-color: $bacColor;
		  color: $fontcolor;

		.teamFrom-box {
			padding: 60px 75px;

			@screen md {
				padding: 24px 15px;
			}
		}


		.supply-title {
			.title {
				text-align: center;
				margin-top: 42px;
				margin-bottom: 26px;

				h1 {
					margin: 0;
					font-size: 32px;
					color: #fff;
					font-weight: 700;

					@screen md {
						font-size: 20px;
						text-align: center;
					}
				}

				h3 {
					margin: 0;
					padding-top: 16px;
					display: flex;
					justify-content: center;

					span {
						font-size: 24px;
						color: rgba(255, 255, 255, .3);
						font-weight: 700;
					}

					@screen md {
						flex-direction: column;

						span {
							font-size: 16px;
							text-align: center;
							font-weight: 500;
						}
					}
				}



				@screen md {
					display: block;
					margin-top: 32px;
					margin-bottom: 16px;


				}

			}

			p {
				text-align: center;
				color: rgba(255, 255, 255, 0.60);
				font-size: 20px;
				font-weight: 700;
				font-style: normal;
				line-height: 32.5px;
				/* 162.5% */
				letter-spacing: 2px;
				margin: 0;

				@screen md {
					font-size: 10px;
					line-height: 18px;
					letter-spacing: 0.1px;
				}
			}
		}

		.CampaignCard-box {
			display: flex;
			justify-content: center;
		}
	}
</style>