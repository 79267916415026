<template>
	<div class="all-medal-content md:flex-col md:gap-16">
		<div class="content-menu md:w-full md:min-w-full md:max-w-full md:flex-row">
			<div class="menu-item md:w-auto md:px-20 md:py-2 md:h-36 "
				:class="{ 'menu-item-active': active === 'mglx' }" @click="changeActive('mglx')">

				<span>{{$VStudyContent("美国留学")}}</span>
			</div>
			<div class="menu-item md:w-auto md:px-20 md:py-2 md:h-36 "
				:class="{ 'menu-item-active': active === 'yglx' }" @click="changeActive('yglx')">
				<span>{{$VStudyContent("英国留学")}}</span>
			</div>
			
		</div>
		<div class="content-main md:grid md:grid-cols-1">
			<template v-for="(item, index) in medalList" >
				<studyCard :item="item"></studyCard>
			</template>
		</div>
		</div>
</template>

<script>
	import {  getConfig } from '@/utils/active.api.js'
	import { mapState } from 'vuex'
	import studyCard from '../../components/study/studyCard.vue';
	
    /* viai18n-disable */
    import $VStudyContentMessages from "./StudyContent.messages.json";
  const $defaultObject = {
		components: { studyCard},

		data() {
			return {
				popupItem: {},
				active: 'mglx',
			};
		},
		computed: {
			...mapState(['isConnect', 'address', 'sbtMedal']),
			medalList() {
				
				if(this.active == 'mglx'){
					return {
						'mggz': {
							"SBTName": this.$VStudyContent("美国高中","美国高中"),
							"SBTIntro": this.$VStudyContent("美国高中留学申请_148c","美国高中留学申请攻略"),
							"Icon": 'img/study/mggz.jpg',
							"condition":[
								{key:'1',value:this.$VStudyContent("1、在读证明和成_cd66","1、在读证明和成绩单，GPA越高越好")},
								{key:'2',value:this.$VStudyContent("2、语言要求：T_06ce","2、语言要求：TOEFL")},
								{key:'3',value:this.$VStudyContent("3、经济担保","3、经济担保")},
								{key:'4',value:this.$VStudyContent("4、面试","4、面试")},
								{key:'5',value:this.$VStudyContent("5、现任老师的推_a496","5、现任老师的推荐信")},
								{key:'5',value:this.$VStudyContent("6、学校要求的申_0d6a","6、学校要求的申请费")},
								{key:'5',value:this.$VStudyContent("7、填好个人资料_cb7c","7、填好个人资料申请表")},
								{key:'5',value:this.$VStudyContent("8、护照首页的复_6e3a","8、护照首页的复印页")},

							],
							"flowpath":[
								{key:'1',value:this.$VStudyContent("初三下学期（4-_a416","初三下学期（4-5月）→留学计划规划，强化英语，资金准备→（6月）选择合适的学校→（7-8月）参加考试培训或自行学习→（9月）参加TOEFL考试→（10月）准备填写资料父母表格、学生表格、推荐信→（11月）参加SSAT考试→（12月）提交申请资料→次年（1-4月）拿到学校offer，缴纳押金→次年（5-7月）签证准备次年→（8月）开启美国留学之旅。")},
							]
						},
						'mgbk': {
							"SBTName": this.$VStudyContent("美国本科","美国本科"),
							"SBTIntro": this.$VStudyContent("申请美国本科攻略","申请美国本科攻略"),
							"Icon": 'img/study/mgbk.jpg',
							"condition":[
								{key:'1',value:this.$VStudyContent("学术要求：GPA_0cf7","学术要求：GPA要求:3.0以上")},
								{key:'2',value:this.$VStudyContent("推荐信：一般需要_1329","推荐信：一般需要提供1至2封英文的推荐信")},
								{key:'2',value:this.$VStudyContent("语言成绩：托福9_b144","语言成绩：托福90分以上")},
								{key:'2',value:this.$VStudyContent("个人称述:提供一_6175","个人称述: 提供一至数篇的个人陈述，其中包括简历、教育程度、留学计划等，面试（可能会有）")},
							],
							"flowpath":[
								{key:'1',value:this.$VStudyContent("7-8月了解学校_de59","7-8月了解学校，初步确定选校的方案→9-10月准备申请资料→11-12月递交申请→次年1-5月收到offer，确定院校及专业，缴纳费用→次年6-7月准备签证→次年8月开启美国留学之旅。")},
	
							]
						},
						'mgyjs': {
							"SBTName": this.$VStudyContent("美国研究生","美国研究生"),
							"SBTIntro": this.$VStudyContent("申请美国研究生攻_abf6","申请美国研究生攻略"),
							"Icon": 'img/study/mgyjs.jpg',
							"condition":[
								{key:'1',value:this.$VStudyContent("专业成绩：本科成_8faa","专业成绩：本科成绩必须达到75%以上；部分大学要求达到90%以上")},
								{key:'2',value:this.$VStudyContent("标准化考试成绩：_e706","标准化考试成绩：TOEFL需至少达到总分80")},
								{key:'2',value:this.$VStudyContent("学术成绩：理工科_9699","学术成绩：理工科申请者来说，建议提供GRE；商科申请者需提供GMAT")},
								{key:'2',value:this.$VStudyContent("实习经验：美国大_8e7a","实习经验：美国大学非常重视申请者的相关工作经验和课题研究")},
								{key:'2',value:this.$VStudyContent("申请材料：美国究_a775","申请材料：美国究生申请需要提供成绩单，在读证明，简历，2-3封推荐信和个人陈述等相关资料")},
							],
							"flowpath":[
								{key:'1',value:this.$VStudyContent("2-5月确定大致_81aa","2-5月确定大致的申请方向，准备TOEFL，GRE，TOEFL考试→6-8月准备相关实习经验资料月到→次年1月准备资料，递交申请→次年2月-4月准备面试，补件等，收到offer，缴纳定金→次年5-6月准备签证资料，并面签→次年7-9月出行准备，开启美国留学之旅")},
							]
						}
					}
				}else if(this.active == 'yglx'){
					return {
						'yggz': {
							"SBTName": this.$VStudyContent("英国高中","英国高中"),
							"SBTIntro": this.$VStudyContent("英国高中申请攻略","英国高中申请攻略"),
							"Icon": 'img/study/yggz.jpg',
							"condition":[
								{key:'1',value:this.$VStudyContent("1、在读证明(中_aaf6","1、在读证明(中英文版)")},
								{key:'2',value:this.$VStudyContent("2、过去3年成绩_7d37","2、过去3年成绩单(必须加盖学校公章)")},
								{key:'3',value:this.$VStudyContent("3、现任老师的推_8a81","3、现任老师的推荐信")},
								{key:'4',value:this.$VStudyContent("4、填好个人资料_8a57","4、填好个人资料申请表")},
								{key:'4',value:this.$VStudyContent("5、学校要求的申_600e","5、学校要求的申请费")},
								{key:'4',value:this.$VStudyContent("6、护照首页以及_74e3","6、护照首页以及英国签证的复印页")},
								{key:'4',value:this.$VStudyContent("7、监护表格","7、监护表格")},
								{key:'5',value:this.$VStudyContent("注意事项:国际学_dd1e","注意事项: 国际学生申请赴英国留学读高中，一般不需要提供雅思成绩（如有更好），英国高中为 GCSE 课程，两年的 GCSE 和两年的 A-level(大学预科)，一般两年为一个阶段，中间不能插班或转学。")}
							],
							"flowpath":[
								{key:'1',value:this.$VStudyContent("初三下学期4月准_0666","初三下学期4月准备申请材料→5月选择合适的学校→6-9月递交申请，缴纳申请费和注册费→次年2月参加学校的入学笔试和面试→次年3-5月拿到录取通知书，缴纳学校押金→次年6-8月选择监护人，联系监护人→次年8-9月准备签证，踏上留学之路")},
							]
						},
						'ygbk': {
							"SBTName": this.$VStudyContent("英国本科","英国本科"),
							"SBTIntro": this.$VStudyContent("英国本科申请攻略","英国本科申请攻略"),
							"Icon": 'img/study/ygbk.jpg',
							"condition":[
								{key:'1',value:this.$VStudyContent("适合对象：结束英_5ea6","适合对象：结束英国预科课程或国内本科在读，学习成绩70分以上")},
								{key:'2',value:this.$VStudyContent("预科课程申请者为_b6a0","预科课程申请者为国内高二或高三的学生，平均分70-85分")},
								{key:'3',value:this.$VStudyContent("升读本科的学生必_1c15","升读本科的学生必须有英国预科课程背景，或为国内大一或大二的学生，需提供高中成绩及大学期间成绩，一般要求均分在80分以上")},
								{key:'4',value:this.$VStudyContent("语言要求：本科I_a0b2","语言要求：本科IELTS要求为6.0-7.0，预科IELTS要求为4.5-6.0")},
								{key:'4',value:this.$VStudyContent("推荐信要求：提供_3d40","推荐信要求：提供现任老师的推荐信一封或是提供现任老师的邮箱，由老师直接提交推荐信")},
								{key:'4',value:this.$VStudyContent("入学时间：每年1_9b6c","入学时间：每年1月、9月")},
								{key:'5',value:this.$VStudyContent("注意事项:所有申_ee8d","注意事项: 所有申请英国大学本科的学生都需要通过UCAS申请，包括native，最多能申请5个专业，如果都没有满意的学校，最后的clearing阶段还可选择一所学校。")}
							],
							"flowpath":[
								{key:'1',value:this.$VStudyContent("1-5月了解院校_6f32","1-5月了解院校，准备雅思→6-8月参加考试，准备材料，选择院校和专业→9-12月递交申请材料→次年1-2月陆续收到学校conditional offer→次年3-5月提交雅思成绩或申请语言强化课程→次年6-9月支付学费押金等，申请CAS，办理签证，开始英国留学")},
							]
						},
						'ygyjs': {
							"SBTName": this.$VStudyContent("英国研究生","英国研究生"),
							"SBTIntro": this.$VStudyContent("英国研究生申请攻_3e29","英国研究生申请攻略"),
							"Icon": 'img/study/ygyjs.jpg',
							"condition":[
								{key:'1',value:this.$VStudyContent("TOP20以前:","TOP20以前:")},
								{key:'2',value:this.$VStudyContent("重点大学：平均分_6485","重点大学：平均分80-85分，雅思6.5以上")},
								{key:'3',value:this.$VStudyContent("普通大学：平均分_af05","普通大学：平均分85-90分，雅思6.5以上")},
								{key:'4',value:'TOP21-50:'},
								{key:'4',value:this.$VStudyContent("重点大学：平均分_f1dd","重点大学：平均分75-80分，雅思6.0-6.5")},
								{key:'4',value:this.$VStudyContent("普通大学：平均分_8c1f","普通大学：平均分80-85分，雅思6.0-6.5")},
								{key:'4',value:this.$VStudyContent("专科毕业：3年以_d339","专科毕业：3年以上工作经验，平均分80左右，雅思6.0-6.5")},
								{key:'4',value:this.$VStudyContent("入学时间：每年1_9b6c","入学时间：每年1月、9月")},
								{key:'5',value:this.$VStudyContent("注意事项:1.若_f97d","注意事项: 1. 若学历不够或专业不符，经英国研究生学院认可后，可先读一年硕士预科，再攻读硕士学位. 2. 英国商科专业竞争激励，且截止日期都比较早，所以建议学生申请商科的学生早做准备")}
							],
							"flowpath":[
								{key:'1',value:this.$VStudyContent("次年2月了解院校_69b2","次年2月了解院校及专业信息，参加雅思培训→次年3-6月完成文书推荐信，参加雅思考试→次年7-9月各院校申请提交→次年10-12月跟进申请进度→第三年1-3月根据录取结果定校，交押金→次年3-5月提交雅思成绩或申请语言强化课程→次年6-9月申请CAS，办理签证，留学英国")},
							]
						},
					}
				}else if(this.active == 'jndlx'){
					return {
						'jianada': {
							"SBTName": this.$VStudyContent("加拿大企业家移民","加拿大企业家移民"),
							"SBTIntro": this.$VStudyContent("加拿大联邦政府与_6bc7","加拿大联邦政府与加拿大Saskatchewan省签署的省提名移民计划的协议。"),
							"Icon": 'img/study/jianada.jpg',
							"condition":[
								{key:'1',value:this.$VStudyContent("1、夫妻双方资产_693a","1、夫妻双方资产达到50万加币以上")},
								{key:'2',value:this.$VStudyContent("2、主申请人有至_69b9","2、主申请人有至少3年以上经商管理经验")},
								{key:'3',value:this.$VStudyContent("3、符合萨省EO_b985","3、符合萨省EOI评分标准")},
								{key:'4',value:this.$VStudyContent("4、创业要求：在_d675","4、创业要求：在里贾纳或萨斯卡通投资30万加币并创造二名就业机会；或在萨省其他地区投资20万加币并创造一名就业机会")},
								{key:'5',value:this.$VStudyContent("4、参与生意的管_1ce0","4、参与生意的管理")}
							],
							"flowpath":[
								{key:'1',value:this.$VStudyContent("1、签约","1、签约")},
								{key:'1',value:this.$VStudyContent("2、考察萨省","2、考察萨省")},
								{key:'1',value:this.$VStudyContent("3、申请EOI，_cac5","3、申请EOI，获得邀请函")},
								{key:'1',value:this.$VStudyContent("4、缴纳第三方调_556c","4、缴纳第三方调查费，获申请费打款通知")},
								{key:'1',value:this.$VStudyContent("5、获得第三方调_6727","5、获得第三方调查报告")},
								{key:'1',value:this.$VStudyContent("6、递交MNP报_af88","6、递交MNP报告到萨省移民局")},
								{key:'1',value:this.$VStudyContent("7、获得工作签证","7、获得工作签证")},
								{key:'1',value:this.$VStudyContent("8、创立萨省企业_dac1","8、创立萨省企业，准备联邦申请文件")},
								{key:'1',value:this.$VStudyContent("9、获得省提名","9、获得省提名")},
								{key:'1',value:this.$VStudyContent("10、获得联邦档_05b4","10、获得联邦档案号")},
								{key:'1',value:this.$VStudyContent("11、获得体检表","11、获得体检表")},
								{key:'1',value:this.$VStudyContent("12、获得取签信_7476","12、获得取签信、枫叶卡")},
							]
						},
						'meiguo': {
							"SBTName": this.$VStudyContent("美国EB1-C移_d1be","美国EB1-C移民"),
							"SBTIntro": this.$VStudyContent("以工作关系为基础_d42a","以工作关系为基础的移民申请"),
							"Icon": 'img/study/meiguo.jpg',
							"condition":[
								{key:'1',value:this.$VStudyContent("1、证明申请人在_61dc","1、证明申请人在中国公司企业主或者高管至少1年")},
								{key:'2',value:this.$VStudyContent("2、中国公司年营_d63c","2、中国公司年营业额需再100万美金以上")},
								{key:'2',value:this.$VStudyContent("3、中国公司雇佣_8cbf","3、中国公司雇佣人员需达到20人")},
							],
							"flowpath":[
								{key:'1',value:this.$VStudyContent("1、咨询、评估及_2c54","1、咨询、评估及签约；")},
								{key:'1',value:this.$VStudyContent("2、登陆6天5夜_10c7","2、登陆6天5夜考察美国生意")},
								{key:'1',value:this.$VStudyContent("3、考察完成，达_9b11","3、考察完成，达成生意收购")},
								{key:'1',value:this.$VStudyContent("4、准备申请材料_5a2c","4、准备申请材料递交I-140申请")},
								{key:'1',value:this.$VStudyContent("5、I-140通_b1d5","5、I-140通过。等待永居身份获批")},
							]
						}
					}
				}else if(this.active == 'qt'){
					return []
				}
			},
		},
		created() {
			this.active = this.$route.query.type || 'mglx'
			this.getMedalWall()
		},
		mounted() {
			this.init()
		},
		watch: {
			'$route.query': {
				handler(newQuery, oldQuery) {
					if (this.$route.query.type) {
						this.active = this.$route.query.type
						this.getMedalWall()
					}
					const currentRoute = this.$route;
					if (currentRoute.query.type) {
						this.$router.replace({
							query: {} // 更新后的查询参数对象
						});
					}
				},
				immediate: true, // 是否立即执行回调
				deep: true, // 是否深度监听
			},
		},
		methods: {
			init(){
				getConfig();
				
			},
			changeActive(active) {
				this.active = active;
			},
			
			getMedalWall() {
				console.log(this.active)
			}
		},
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VStudyContent(key,origin){
      const messages = $VStudyContentMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VStudyContent(key,origin){
      const messages = $VStudyContentMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件



	.all-medal-content {
		/* max-width: 1590px; */
		margin: auto;
		overflow: hidden;
		position: relative;
		z-index: 1;
		min-height: calc(100vh - 80px);
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 50px 75px 80px;
		display: flex;
		gap: 40px;

		@screen md {
			padding: 16px;
		}

		.content-menu {
			/* margin-right: 40px; */
			width: 196px;
			min-width: 196px;
			max-width: 196px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;


			.menu-item {
				cursor: pointer;
				width: 100%;
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 12px;
				border-radius: 2px;
				transition: all .3s;
				

				img {
					width: 24px;
					margin-right: 16px;
				}

				span {
					display: inline-block;
					color: $fontcolor;
					font-size: 16px;
					font-style: normal;
					font-weight: 900;
					line-height: 16px;
					/* 100% */
					letter-spacing: -0.4px;
				}
			}

			.menu-item-active {
				box-shadow: $box_shadow;
				background: $jbfontbacColor;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				border-radius: $bt_bo_radius;
			}
		}

		.content-main {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			gap: 24px;

			.sbt-item {
				width: 283px;
				/* height: 336px; */
				border-radius: 6px;
				border: 1px solid rgba(255, 255, 255, 0.10);
				box-shadow: $box_shadow;
				
				overflow: hidden;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				position: relative;
				z-index: 0;

				.isAcquire {
					position: absolute;
					right: 5%;
					top: 5%;
					width: 38px;
					height: 38px;

					@screen md {
						width: 28px;
						height: 28px;

					}
				}

				.nft-img {
					border-radius: 6px;
					overflow: hidden;
					width: 281px;
					/* height: 281px; */
					/* min-height: 281px;
					max-height: 281px; */

					img {
						border-radius: 6px;
						width: 100%;
						/* height: 100%; */
						object-fit: cover;
					}
				}

				.nft-name {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 54px;

					.isKt {
						margin-right: 10px;
						width: 24px;
						height: 24px;
					}

					span {
						color: $fontcolor;
						text-align: center;
						font-size: 14px;
						font-style: normal;
						font-weight: 590;
						line-height: 20px;
						/* 100% */
						letter-spacing: -0.4px;
					}
				}

				.nft-name-md {
					display: none;
					color: $fontcolor;

					.nft-detail-md {
						padding: 0 8px 8px;

						h1 {
							.isKt {
								margin-right: 10px;
								width: 24px;
								height: 24px;
							}

							color: $fontcolor;
							font-size: 14px;
							font-weight: 700;
							line-height: 16px;
							/* 100% */
							letter-spacing: -0.4px;
						}

						p {
							margin: 0;
							color: $fontcolor;
							font-size: 12px;
							font-weight: 400;
							line-height: 16px;
							/* 133.333% */
							letter-spacing: -0.8px;
						}
					}

					.nft-claim-md {
						width: 100%;
						height: 36px;
						display: flex;
						align-items: center;
						justify-content: center;

						span {
							display: inline-block;
							width: 100%;
							height: 100%;
							text-align: center;
							line-height: 36px;
							border-radius: 0px 0px 16px 16px;
							border: 1px solid rgba(255, 255, 255, 0.10);
							background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
							border: none;
						}
					}
				}

				.detail-btn {
					width: 100%;
					position: absolute;
					bottom: -142px;
					left: 0;
					z-index: 2;
					overflow: hidden;
					transition: all .3s;

					.detail-box {
						height: 100px;
						padding: 16px 12px;
						border: 1px solid rgba(255, 255, 255, 0.10);
						border-radius: 16px 16px 0px 0px;
						border-left: none;
						border-right: none;
						border-bottom: none;
						background: rgba(255, 255, 255, 0.09);
						backdrop-filter: blur(15px);

						span {
							color: $fontcolor;
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 20px;
							border: none;
							/* 100% */
						}

						p {
							margin: 0;
							margin-top: 10px;
							color: $fontcolor;
							font-size: 14px;
							font-style: normal;
							font-weight: 300;
							line-height: 18px;
							/* 128.571% */
						}
					}

					.btn-box {
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 42px;
						border-radius: 0px 0px 16px 16px;
						background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);

						/* backdrop-filter: blur(15px); */
						span {
							display: inline-block;
							width: 100%;
							height: 100%;
							color: $fontcolor;
							text-align: center;
							font-size: 14px;
							font-style: normal;
							font-weight: 590;
							line-height: 42px;
							/* 100% */
							letter-spacing: -0.4px;
						}
					}
				}

				&:hover {
					.detail-btn {
						bottom: 0;
						/* transform: translateY(0); */
					}
				}
			}
		}
	}
</style>