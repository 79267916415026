import Prompt from "./Prompt.vue";
import Vue from "vue";

let instance;
export default {
  install() {
    function CommPrompt(prompt) {
      // 判断是否已经创建
      if (instance) {
        // 消息弹窗再次调用移除上次调用
        document.body.removeChild(instance.vm.$el.parentNode);
      }
      const PromptConstructor = Vue.extend(Prompt);
      instance = new PromptConstructor({
        propsData: {
          prompt,
        },
      });
      instance.vm = instance.$mount();
      // 创建节点
      document.body.appendChild(instance.vm.$el);
      // 打开弹窗
      instance.vm.showPrompt();
    }
    // 挂载在 vue 实例 this.$prompt 调用
    Vue.prototype.$prompt = CommPrompt;
  },
};
