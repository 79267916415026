<template>
  <div class="c-dapp md:px-12">
    <img class="static-bg-2" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/static-bg-2.webp" alt="">
    <div class="content">


      
     
	  
      <div class="why-work">

        <div class="low-coding md:text-20 md:leading-32">
          <Language :text='$VDapp("核心价值观")'>
            <div slot="r1" slot-scope="data"></div>
          </Language> 
        </div>
        <div class="description md:text-12 md:leading-18 md:mt-15 md:px-20">{{$VDapp("真诚、智慧、多维_62c9")}}</div>
      </div>
      <div class="solutions md:block">
        <div class="items items-left">
          <div class="title font-bold">{{$VDapp("使命")}}</div>
          <div class="desc">{{$VDapp("致力于成为全球精_2e0e")}}</div>
        </div>
        <div class="dapp-triangle-img">
          <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/dapp-triangle.png" alt="">
        </div>
        <div class="items items-right">
          <div class="title font-bold">{{$VDapp("愿景")}}</div>
          <div class="desc">{{$VDapp("独树一帜，打造一_1577")}}</div>

          <img class="hidden md-bg-img-1 md:block" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/dapp-triangle.png" alt="">
        </div>
      </div>
      <div class="items md:mt-70" style="text-align: center; margin-top: 47px;">
        <div class="title font-bold md:px-60">{{$VDapp("可扩展解决方案支_7fcb")}}</div>
        <div class="desc md:px-50">{{$VDapp("我们不仅关注传统_569d")}}</div>
        <img class="md-bg-img-2 hidden md:block" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/dapp-triangle.png" alt="">

      </div>
    </div>
  </div>
</template>


<script>
import Language from '@/components/common/Language'

    /* viai18n-disable */
    import $VDappMessages from "./Dapp.messages.json";
  const $defaultObject = {
  components: {
    Language
  }
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VDapp(key,origin){
      const messages = $VDappMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VDapp(key,origin){
      const messages = $VDappMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

.c-dapp {
	color: $fontcolor;
  margin: 50px 0;
  position: relative;
  padding: 0 75px;

  .content {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    z-index: 100;
  }

  .static-bg-2 {
    position: absolute;
    bottom: -600px;
    right: 0;

    @screen md {
      bottom: 0;
    }
  }

  .header {
    height: 156px;
    width: 100%;
    background-image: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/dapp-header.png');
    position: relative;
    z-index: 100;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 20px;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @screen md {
      display: block;
      background-image: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/dapp-header-m.png');
      height: 293px;
    }

    div {
      flex: 1;

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
 

    }

    .text {
      font-size: 32px;
      line-height: 38px;

      width: 874px;
      padding-left: 57px;

      @screen md {
        width: auto;
      }
    }

    .desc {
      max-width: 480px;
      margin-left: 64px;
    }
  }

  .dapp-circle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 823px;
    top: -115px;
    z-index: -1;

    @screen md {
      width: 100%;
      top: 180px;
    }
  }

  .why-work {
    padding-top: 170px;
    @screen md {
      padding-top: 80px;
    }
  }

  .dot {
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 6px;
    background-color: #253bff;
    margin-right: 7px;
  }

  .question {
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .low-coding {
    font-size: 54px;
    font-weight: bold;
    line-height: 68px;
    text-align: center;

  }

  .description {
    font-size: 20px;
    line-height: 28px;

    text-align: center;
    margin-top: 15px;
  }

  .solutions {
    margin-top: 15px;
    display: flex;
    justify-content: center;

    align-items: center;
    position: relative;
    overflow: hidden;

	padding-bottom:10px;
	
    &::before {
      height: 1px;
      display: block;
      content: "";
      background: linear-gradient(90deg, #29292C 50%, rgba(41, 41, 44, 0.4) 89.57%);
      position: absolute;
      bottom: 0;
      width: 688px;
      left: 50%;
      width: 50%;
      z-index: 9;

      @screen md {
        display: none;
      }
    }

    &::after {
      height: 1px;
      display: block;
      content: "";
      position: absolute;
      right: 50%;
      bottom: 0;
      width: 50%;
      background: linear-gradient(270deg, #29292C 60%, rgba(41, 41, 44, 0.4) 89.57%);
      z-index: 9;

      @screen md {
        display: none;
      }
    }

    .dapp-triangle-img {
      position: relative;
      z-index: 10;

      @screen md {
        position: absolute;
        width: 317px;
        left: 50%;
        top: 0;
      }

      &::after {
        background-color: #000000;
        position: absolute;
        left: 1px;
        right: 1px;
        bottom: 0;
        content: "";
        display: block;
        z-index: 10;
        height: 1px;

        @screen md {
          background: linear-gradient(270deg, #29292C 60%, rgba(41, 41, 44, 0.4) 89.57%);
          left: -50%;
          right: 100%;
        }
      }
    }

    .items {
      max-width: 440px;
	  padding: 10px;
    }
  }

  .items {

    @screen md {

      &.items-left {
        padding-top: 80px;
        width: 70%;
      }

      &.items-right {
        padding-left: 30%;
        margin-top: 220px;
        position: relative;
        padding-bottom: 140px;

        &::after {
          background: linear-gradient(90deg, #29292C 50%, rgba(41, 41, 44, 0.4) 89.57%);
          position: absolute;
          left: 56%;
          right: 1px;
          bottom: 0;
          content: "";
          display: block;
          z-index: 10;
          height: 1px;
        }

        .md-bg-img-1 {
          position: absolute;
          right: 43%;
          bottom: 0;
        }
      }
    }
    .desc {
      font-size: 20px;
      line-height: 28px;
      margin-top: 34px;


      @screen md {
        font-size: 12px!important;
        line-height: 18px!important;
      }

      @media screen and (max-width: 1440px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .title {
      font-size: 32px;
      line-height: 42px;


      @screen md {
        font-size: 20px!important;
        line-height: 24px!important;
      }

      @media screen and (max-width: 1440px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .md-bg-img-2 {
      transform: rotateX(180deg);
      margin: 0 auto;
    }
  }
}</style>