<template>
	<div class="sbt-item md:w-full">

		<div class="nft-img h-281 md:w-full md:mb-12">
			<img :src="item.Icon" alt="">
		</div>
		
		<div class="nft-name md:hidden flex-center">
			<img src="/img/migrate/kt.png" class="isKt" alt="" srcset=""/>
			<span>{{item.SBTName}}</span>
		</div>
		
		<div class="detail-btn md:hidden">
			<div class="detail-box">
				<span>{{item.SBTName}}</span>
				<p>{{item.SBTIntro}}</p>
			</div>
			<div class="btn-box">
				<span @click="showClaim = true">{{$VmigrateCard("查看详情")}}</span>
			</div>
		</div>
		
		
		<div class="nft-name-md md:block">
			<div class="nft-detail-md">
				<h1 class="flex">
					<img src="/img/migrate/kt.png" class="isKt" alt="" srcset="" />
					<span>{{item.SBTName}}</span>
				</h1>
				<p>{{item.SBTIntro}}</p>
			</div>
			<div class="nft-claim-md">
				<span @click="showClaim = true">{{$VmigrateCard("查看详情")}}</span>
			</div>
		</div>
		
		<ClaimModal :item="item" :visible="showClaim" @closeClaim="closeClaim"></ClaimModal>
		<modal v-model="isShow"></modal>
	</div>
</template>

<script>
	import modal from '@/components/modal.vue'
	import ClaimModal from '@/components/migrate/ClaimModal.vue';
	import { mintNft, isMinted } from '@/web3utils/base/WodBuy.js'
	import { mapState } from 'vuex'
	
    /* viai18n-disable */
    import $VmigrateCardMessages from "./migrateCard.messages.json";
  const $defaultObject = {
		name: 'Name',
		components: { ClaimModal, modal },
		data() {
			return {
				isShow: false,
				isHave: false,
				showClaim: false,
			}
		},
		props: {
			item: {
				type: Object,
				default: () => {}
			},
		},
		computed: {
			...mapState(['isConnect', 'address', 'sbtMedal', 'nftAddress', 'config805']),
			nowId(){
				
			},
			//是否有资格
			nowSBT() {
				return Number(this.sbtMedal[this.item['ID']])
			},
		},
		mounted() {
			this.init()
		},
		methods: {
			async init() {
				const { type,id } = this.$route.query;
				if(id && id == this.item['ID']){
					this.showClaim = true
				}
				
				if (this.nowSBT >= 1) {
					const isMint = await isMinted(this.address, this.nftAddress[this.item['ID']], this.config805)
					if (isMint > 0) {
						this.isHave = true;
					} 
				}
			},
			openClaim() {
				this.showClaim = true
			},
			closeClaim(status) {
				this.showClaim = false
			},
		}
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VmigrateCard(key,origin){
      const messages = $VmigrateCardMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VmigrateCard(key,origin){
      const messages = $VmigrateCardMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

	.sbt-item {
		width: 283px;
		/* height: 336px; */
		border-radius: 6px;
		border: 1px solid rgba(255, 255, 255, 0.10);
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		z-index: 0;

		.isAcquire {
			position: absolute;
			right: 5%;
			top: 5%;
			width: 38px;
			height: 38px;

			@screen md {
				width: 28px;
				height: 28px;

			}
		}

		.nft-img {
			border-radius: 6px;
			overflow: hidden;
			width: 281px;
			/* height: 281px; */
			/* min-height: 281px;
			max-height: 281px; */

			img {
				border-radius: 6px;
				width: 100%;
				/* height: 100%; */
				object-fit: cover;
			}
		}

		.nft-name {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 54px;

			.isKt {
				margin-right: 10px;
				width: 24px;
				height: 24px;
			}

			span {
				color: $fontcolor;
				text-align: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 590;
				line-height: 14px;
				/* 100% */
				letter-spacing: -0.4px;
			}
		}

		.nft-name-md {
			display: none;
			color: #fff;
			width:100%;
			
			.nft-detail-md {
				padding: 0 8px 8px;

				h1 {
					.isKt {
						margin-right: 10px;
						width: 24px;
						height: 24px;
					}

					color: $fontcolor;
					font-size: 14px;
					font-weight: 700;
					line-height: 16px;
					/* 100% */
					letter-spacing: -0.4px;
				}

				p {
					margin: 0;
					color: $fontcolor;
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
					/* 133.333% */
					letter-spacing: -0.8px;
				}
			}

			.nft-claim-md {
				width: 100%;
				height: 36px;
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					display: inline-block;
					width: 100%;
					height: 100%;
					text-align: center;
					line-height: 36px;
					border-radius: 0px 0px 16px 16px;
					border: 1px solid rgba(255, 255, 255, 0.10);
					background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
					border: none;
				}
				.dis{
					background: rgba(36, 36, 36, 1) !important;
					color: rgba(90, 90, 90, 1);
				}
			}
		}

		.detail-btn {
			width: 100%;
			position: absolute;
			bottom: -242px;
			left: 0;
			z-index: 2;
			overflow: hidden;
			transition: all .3s;

			.detail-box {
				height: 100%;
				padding: 16px 12px;
				border: 1px solid rgba(255, 255, 255, 0.10);
				border-radius: 6px 6px 0px 0px;
				border-left: none;
				border-right: none;
				border-bottom: none;
				background: rgba(255, 255, 255, 0.09);
				backdrop-filter: blur(15px);

				span {
					color: $bt_ft_color;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 14px;
					border: none;
					/* 100% */
				}

				p {
					margin: 0;
					margin-top: 10px;
					color: $bt_ft_color;
					font-size: 14px;
					font-style: normal;
					font-weight: 300;
					line-height: 18px;
					/* 128.571% */
				}
			}

			.btn-box {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 42px;
				border-radius: 0px 0px 6px 6px;
				background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);

				/* backdrop-filter: blur(15px); */
				span {
					display: inline-block;
					width: 100%;
					height: 100%;
					color: #FFF;
					text-align: center;
					font-size: 14px;
					font-style: normal;
					font-weight: 590;
					line-height: 42px;
					/* 100% */
					letter-spacing: -0.4px;
				}
				.dis{
					background: rgba(36, 36, 36, 1) !important;
					color: rgba(90, 90, 90, 1);
				}
			}
		}

		&:hover {
			.detail-btn {
				bottom: 0;
				/* transform: translateY(0); */
			}
		}
	}
</style>