<template>
  <div class="comm-back">
    <a-breadcrumb separator=">" :routes="routes">
      <template slot="itemRender" slot-scope="{ route, params, routes, paths }">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ route.breadcrumbName }}
        </span>
        <router-link v-else :to="`${basePath}/${paths.join('/')}`">
          <a-icon type="environment" v-if="route.breadcrumbName == 'Home'" />
          <span>{{ route.breadcrumbName }}</span>
        </router-link>
      </template>
    </a-breadcrumb>
  </div>
</template>
<script>
export default {
  props: {
    routes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      basePath: '',
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

.comm-back {
  position: relative;
  z-index: 9;
  .ant-breadcrumb {

    .ant-breadcrumb-link {

      a,
      span {
        color: $fontcolor;
        font-size: 16px;

        @screen md {
          font-size: 10px
        }
      }

      i {
        transform: translateY(-3px);

        @screen md {
          font-size: 8px;
          transform: translateY(-1px);
        }
      }

      .anticon {
        margin-right: 13px !important;

        @screen md {
          margin-right: 5px !important;
        }
      }
    }

    :deep(.ant-breadcrumb-separator) {
      font-size: 10px;
      color: $fontcolor;
    }
  }
}
</style>
