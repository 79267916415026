import Vue from 'vue'
import copy from './v-copy';  // 粘贴指令
// 自定义指令集合 
const directives = {
  copy,
};

Object.keys(directives).forEach((key) => {
  Vue.directive(key, directives[key]);
});
