<template>
	<a-modal centered :visible="visible" :closable="false" :maskClosable="true" @cancel="afterClose" :footer="null"
		wrapClassName="blur-modal" :maskStyle="{ backdropFilter: 'blur(16px)' }">
		<div class="claim-modal md:p-24">
			<div class="close md:w-24 md:p-0" @click="afterClose">
				<img src="/img/ty/close.svg" alt="">
			</div>
			<div class="claim-content md:flex-col">
				<div class="claim-img md:hidden">
					<img :src="item.Icon" alt="">
				</div>
				<div class="claim-img-md md:block md:mx-auto mb-32">
					<img :src="item.Icon" alt="">
				</div>
				<div class="claim-detail md:text-center">
					<h1 class="detail-tit md:text-20 md:mb-16">
						{{item.SBTName}}
					</h1>
					<p class="md:text-12 md:leading-18 md:mb-16">
						{{item.SBTIntro}}
					</p>
					<div class="detail-li md:pt-16 md:border-t">
						<h1 class="condition_tj">{{$VClaimModal("项目申请条件")}}</h1>
						<div class="detail-item"  v-for="(item_, index) in item.condition">
							<span class="item-left">{{item_.value}}</span>
						</div>
						<h1 class="condition_tj">{{$VClaimModal("项目申请流程")}}</h1>
						<div class="detail-item"  v-for="(item_, index) in item.flowpath">
							<span class="item-left">{{item_.value}}</span>
						</div>
					</div>
				</div>
			</div>

			<template v-if="btnShow">
				<div class="progress-block" v-if="isProgress">
					<p>{{$VClaimModal("Upgradei_cab8")}}</p>
					<div class="progress-box">
						<div class="roits left"> </div>
						<a-tooltip placement="topRight">
							<template slot="title">
								Reach for the next star
							</template>
							<div class="roits right"> </div>
						</a-tooltip>

						<a-progress :stroke-color="{
						        from: '#34C5F3',
						        to: '#203DFD',
						      }" :show-info="false" :percent="progressNum[item.SBTName]" status="active" />
					</div>
					<p class="flex-between">
						<span>Deposit Duration (Days)</span>
						<span>{{progressNum[item.SBTName]}}/30</span>
					</p>
				</div>
				<div class="claim-btn md:px-8 md:mt-32" v-else>
					
				</div>
			</template>
			<template v-else>
				<div class="claim-btn md:px-8 md:mt-32">
					<a-button type="primary" @click="$router.push(route)">
						Go to Medal Wall
					</a-button>
				</div>
			</template>


		</div>
	</a-modal>

</template>
<script>
	import { getaddressinfo, getbstassert, getsign, getConfig } from '@/utils/active.api.js'
	import { verifyTweet2, twRtMwAuth, verifySendTweet } from '@/utils/twitter.api.js'
	import { mintNft, isMinted } from '@/web3utils/base/WodBuy.js'
	import { mapState } from 'vuex'
	
    /* viai18n-disable */
    import $VClaimModalMessages from "./ClaimModal.messages.json";
  const $defaultObject = {
		name: 'ClaimModal',
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			btnShow: {
				type: Boolean,
				default: true
			},
			item: {
				type: Object,
				default: () => {}
			},
			route: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				isHave: false, //是否拥有
				initLoading: false, //init加载
				isLoading: false, //按钮loading
				bstassert: {
					honorablecaption: "0",
					honorableteam: "0",
					outstandingcaption: "0",
					outstandingteam: "0",
					setterv1: "0",
					setterv2: "0",
					setterv3: "0",
					starstuddedcaption: "0",
				},
				status: 1,
				isTwitter: 0,
				claimed: false,
			}
		},
		computed: {
			// 
			...mapState(['addressinfo', 'address', 'sbtMedal', 'nftAddress', 'config805']),
			nowSBT() {
				return Number(this.sbtMedal[this.item['ID']])
			},
			startNum() {
				return {
					'Early Settlers Lv.1': this.addressinfo['v1star'],
					'Early Settlers Lv.2': this.addressinfo['v2star'],
					'Early Settlers Lv.3': this.addressinfo['v3star'],
				}
			},
			//进度条数值
			progressNum() {
				return {
					'Early Settlers Lv.1': this.addressinfo['setterv1day'] % 30,
					'Early Settlers Lv.2': this.addressinfo['setterv2day'] % 30,
					'Early Settlers Lv.3': this.addressinfo['setterv3day'] % 30,
				}
			},
			//是否显示进度条
			isProgress() {
				if (!this.isHave) return false
				const a = ['setterv1', 'setterv2', 'setterv3']
				if (a.indexOf(this.item['ID']) == -1) {
					return false
				}
				return true;
			},
			//按钮状态
			statusName() {
				if (!this.address) {
					return 'Connect wallet'
				}
				if (this.isHave) {
					return 'Claimed'
				}
				//没有获得  表示没有资格
				if (this.nowSBT < 1) {
					return 'Not eligible for claim'
				}

				//未转发 先转发
				if (this.isTwitter == 0) {
					return "Retweet to claim"
				}

				return 'Claim'
			},
			btnClass() {
				// 登录且转发twitter
				if (this.address) {
					if (this.isHave) return true
					if (this.nowSBT < 1) return true
				}
				return false
			},
		},

		watch: {
			address(val) {
				//没有地址时 关闭弹窗
				if (!val) {
					this.afterClose()
				}
			},
			visible(val) {
				if (val) {
					this.init();
				}
			}
		},
		async mounted() {

		},
		methods: {
			async init() {

			},
			
			//验证推特
			async verifyTweet() {
				const { success, msg, data: { status, claimed } } = await verifyTweet2()
				this.isTwitter = status;
				this.claimed = claimed;
			},
			afterClose() {
				this.$emit('closeClaim')
			},
			async claim() {
				if (!this.address) {
					this.goLogin();
				}
				if (this.isHave) return 
				if (this.nowSBT < 1) return
				
				//没有验证推特 前往
				if (this.isTwitter == 0) {
					this.isLoading = true;
					const res = await twRtMwAuth();
					this.isLoading = false;
					window.open(res.data.url);
					return
				}
				const nowID = this.item['ID'];
				//获取签名
				const { signedmsg, success, msg } = await getsign(nowID)
				if (signedmsg) {
					this.isLoading = true;
					
					//领取
					let mint = await mintNft({
						address: this.nftAddress[nowID],
						signedmsg: signedmsg
					}, this.config805);
					this.isLoading = false;
					if (mint) {
						//等待结果 然后查询是否领取
						const isMint = await isMinted(this.address, this.nftAddress[this.item['ID']], this.config805)
						console.log(isMint)
						if (isMint > 0) {
							this.$message.success({ content: 'Claim Successful', key: 'updatable' });
							this.afterClose(true)
						} else {
							this.$message.error({ content: 'Failed to Claim', key: 'updatable' });
						}
					}
				} else {
					this.$message.error(msg)
				}

			},
			async goLogin() {
				//获取登录
				let enables = await enable();
				if (!enables.code) {
					this.$message('Wallet not logged in')
					return;
				}
			},
		},
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VClaimModal(key,origin){
      const messages = $VClaimModalMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VClaimModal(key,origin){
      const messages = $VClaimModalMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped></style>
<style lang="scss">
	.blur-modal {
		.ant-modal {
			width: auto !important;
			max-width: none !important;

			.ant-modal-content {
				color: #fff;
				background-color: transparent;

				.ant-modal-body {
					padding: 0;

					.claim-modal {
						width: 860px;
						overflow: hidden;
						border-radius: 6px;
						border: 1px solid rgba(255, 255, 255, 0.20);
						background: rgba($color: #000000, $alpha: 0.1);
						backdrop-filter: blur(20px);
						padding: 40px;
						position: relative;

						.close {
							cursor: pointer;
							padding: 12px;
							position: absolute;
							top: 16px;
							right: 16px;

							img {
								width: 100%;
							}
						}

						.claim-content {
							display: flex;
							align-items: center;
							justify-content: space-between;

							/* gap: 40px; */
							.claim-img {
								width: 300px;
								min-width: 300px;
								max-width: 300px;
								margin-right: 40px;

								img {
									width: 100%;
									border-radius: 16px;
									border: 1px solid rgba(255, 255, 255, 0.10);
									object-fit: cover;
								}
							}

							.claim-img-md {
								display: none;
								width: 100px;

								img {
									width: 100%;
									border-radius: 16px;
									border: 1px solid rgba(255, 255, 255, 0.10);
									object-fit: cover;
								}
							}

							.claim-detail {
								.detail-tit {
									color: #FFF;
									font-size: 32px;
									font-style: normal;
									font-weight: 700;
									line-height: 32px;
									/* 100% */
									margin-bottom: 24px;
								}

								p {
									color: rgba(255, 255, 255, 0.60);
									font-size: 14px;
									font-style: normal;
									font-weight: 300;
									line-height: 20px;
									/* 142.857% */
									margin-bottom: 32px;
								}

								.detail-li {
									border-top: 0px solid #312D3A;
									display: flex;
									flex-direction: column;
									gap: 16px;

									.detail-item {
										display: flex;
										align-items: center;
										justify-content: space-between;

										span {
											font-size: 14px;
											font-style: normal;
											font-weight: 300;
											line-height: 20px !important;
											/* 100% */
										}

										.item-left {
											color: rgba(255, 255, 255, 0.60);
										}
									}
								}
							}
						}

						.progress-block {
							padding: 20px 0 0;

							p:nth-of-type(1) {
								margin: 0;
								background: var(--1, linear-gradient(270deg, #203DFD 0%, #34C5F3 100%));
								background-clip: text;
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;

								font-size: 16px;
								font-style: normal;
								font-weight: 600;
								line-height: 20px;
								/* 125% */
							}

							.progress-box {
								padding: 15px 0;
								position: relative;

								.roits {
									display: block;
									z-index: 2;
									position: absolute;
									top: 54%;
									transform: translateY(-50%);
									width: 14px;
									height: 14px;

									border-radius: 100%;
								}

								.left {
									left: 0;
									background: #34C5F3;
								}

								.right {
									right: 0;
									background: #fff;
								}


							}

							p:nth-of-type(2) {
								margin: 0;

								span:nth-of-type(1) {
									color: rgba(255, 255, 255, 0.80);
									font-size: 16px;
									font-style: normal;
									font-weight: 400;
									line-height: 12.207px;
									/* 76.293% */
								}

								span:nth-of-type(2) {
									color: #4F95FF;
									font-size: 16px;
									font-style: normal;
									font-weight: 400;
									line-height: 20px;
									/* 125% */
								}
							}
						}

						.claim-btn {
							margin-top: 48px;


							.ant-btn {
								width: 100%;
								height: 48px;
								cursor: pointer;
								display: flex;
								align-items: center;
								justify-content: center;
								overflow: hidden;
								border: unset;
								border-radius: 12px;
								background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
								display: inline-block;
								text-align: center;
								width: 100%;
								height: 100%;
								font-size: 16px;
								font-weight: 600;
								line-height: 48px;
							}

							.distable {
								cursor: no-drop;
								background: #242424 !important;
								color: #5A5A5A !important;
							}
						}

						@screen md {
							width: 337px;
						}
					}
				}
			}
		}
	}
	
	.condition_tj{
		color: #fff;
	}
</style>
<style lang="scss">
	.ant-rate {
		.ant-rate-star-second {
			svg {
				width: 15px;
				height: 15px;
			}
		}

		.ant-rate-star-full {
			.ant-rate-star-second {
				svg {
					color: rgba(51, 190, 244, 1) !important;
				}
			}
		}
	}
</style>