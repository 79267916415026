import request from "./request";
import store from "../store";
const baseURL = 'https://exapi.wodrpc.org' // 正式：https://exapi.wodrpc.org
// const baseURL = 'http://117.24.6.18:10000' // 测试

//获取个人信息
export async function getaddressinfo(data) {
	try {
		let res = await request({
			url: "/active/getaddressinfo",
			method: "post",
			params: {
				selfaddress: store.state.address,
			},
			baseURL
		});
		if (res.success) {
			store.commit('SET_ADDRESSINFO', res.data)
		}
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}



//获取团队信息
export async function getteaminfo(address = store.state.address) {
	try {
		if (!address) return
		let res = await request({
			url: "/active/getteaminfo",
			method: "post",
			params: {
				teamleader: address,
			},
			baseURL
		});
		if (res.success) {
			const { data: { teamscore, teamleader } } = res;
			if (teamleader == store.state.address) {
				store.commit('SET_ceatedTeam', teamscore || 0)
			} else {

				store.commit('SET_joinedTeam', teamscore || 0)
			}

		}
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//创建团队
export async function createTeam(params) {
	try {
		let res = await request({
			url: "/active/createTeam",
			method: "post",
			params: {
				selfaddress: store.state.address,
				...params,
			},
			baseURL
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

//团队成员列表
export async function getteammember(params) {
	try {
		let res = await request({
			url: "/active/getteammember",
			method: "post",
			params,
			baseURL
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}
//团队列表
export async function getteamlist(params) {
	try {
		let res = await request({
			url: "/active/getteamlist",
			method: "post",
			params,
			baseURL
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//加入团队
export async function jointeam(params) {
	try {
		let res = await request({
			url: "/active/jointeam",
			method: "post",
			params,
			baseURL
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//获取个人已激活勋章资产
export async function getbstassert() {
	try {
		let res = await request({
			url: "/active/getbstassert",
			method: "post",
			params: {
				selfaddress: store.state.address,
			},
			baseURL
		});
		if (res.success) {
			store.commit('SET_SBTMEDAL', res.data)
		}
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

//满足条件后 刷新激活等级和星星
export async function activestar() {
	try {
		let res = await request({
			url: "/active/activestar",
			method: "post",
			params: {
				selfaddress: store.state.address,
			},
			baseURL
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//获取最新的消息列表
export async function getusertoast(params) {
	try {
		let res = await request({
			url: "/active/getusertoast",
			method: "post",
			params: {
				selfaddress: store.state.address,
				...params,
			},
			baseURL
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}
//个人资产
export async function getuserWalletBalance(params) {
	try {
		let res = await request({
			url: "/active/getuserWalletBalance",
			method: "post",
			params: {
				selfaddress: store.state.address,
				...params,
			},
			baseURL
		});
		if (res.success) {
			store.commit('SET_ALLBALANCE', res.msg)
			store.commit('SET_BALANCE', res.data)
		}
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

//修改团队名称
export async function renameteam(params) {
	try {
		let res = await request({
			url: "/active/renameteam",
			method: "post",
			params: {
				selfaddress: store.state.address,
				...params,
			},
			baseURL
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

//配置
export async function getConfig(params) {
	try {
		let res = await request({
			url: "/chain/getConfig",
			method: "post",
			params: {
				selfaddress: store.state.address,
				...params,
			},
			baseURL
		});
		if (res.length > 0) {
			let a = {};
			res.forEach(item => {
				a[item.idx] = item;
				if (item.idx == 805) {
					store.commit('SET_805', item.val);
				}
			})
			store.commit('SET_CONFIG', a)
		}

		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//获取领取勋章的签名
export async function getsign(type) {

	const ntfType = {
		'setterv1': 1,
		'setterv2': 2,
		'setterv3': 3,
		'outstandingcaption': 4,
		'outstandingteam': 5,
		'honorablecaption': 6,
		'honorableteam': 7,
		'starstuddedcaption': 8,
	}
	try {
		let { data, success,msg } = await request({
			url: "active/getsign",
			method: "post",
			params: {
				address: store.state.address,
				assertcontract: ntfType[type],
			},
			baseURL
		});
		if (success) {
			const a = JSON.parse(data)
			return {
				success,
				msg,
				...a
			}
		} else {
			return { signedmsg: false, msg,}
		}
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}