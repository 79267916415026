<template>
  <div class="chip-item">
    <div class="item-list">
      <div class="group-item">
        <div class="item-icon">
          <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/Group-1.png" alt="">
        </div>
        <div class="item-content">
          <div class="item-tit">
            <span>{{$VChipItem("A100代币的用_2914")}}</span>
          </div>
          <div class="item-li">
            <p>{{$VChipItem("质押可参与A10_860c")}}<br>{{$VChipItem("持有额定数目的A_eb38")}}</p>
          </div>
        </div>
      </div>
      <div class="group-item">
        <div class="item-icon">
          <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/Group-2.png" alt="">
        </div>
        <div class="item-content">
          <div class="item-tit">
            <span>{{$VChipItem("成果")}}</span>
          </div>
          <div class="item-li">
            <p class="li">{{$VChipItem("大幅提高链上TV_6a45")}}<br>{{$VChipItem("(100台A10_3d7f")}}</p>
            <p class="li">{{$VChipItem("提高芯片利用效率")}}</p>
            <p class="li">{{$VChipItem("为AI芯片上链提_58c2")}}</p>
          </div>
        </div>
      </div>
      <div class="group-item">
        <div class="item-icon">
          <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/Group-3.png" alt="">
        </div>
        <div class="item-content">
          <div class="item-tit">
            <span>{{$VChipItem("未来")}}</span>
          </div>
          <div class="item-li">
            <p>{{$VChipItem("其他型号，如H8_6af9")}}<br>{{$VChipItem("也可使用上述相同_eaeb")}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

    /* viai18n-disable */
    import $VChipItemMessages from "./ChipItem.messages.json";
  const $defaultObject = {
  
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VChipItem(key,origin){
      const messages = $VChipItemMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VChipItem(key,origin){
      const messages = $VChipItemMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
.chip-item {
  margin-top: 70px;
  .item-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    .group-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 30px;
      width: 50%;
      @screen md {
        width: 100%;
      }
      .item-icon {
        width: 42px;
        margin-right: 30px;
        img {
          width: 100%;
        }
      }
      .item-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .item-tit {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 20px;
          @screen md {
            font-size: 20px;
          }
        }
        .item-li {
          max-width: 487px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.60);
          line-height: 22px;
          @screen md {
            font-size: 12px;
          }
        }
        .li {
          position: relative;
          padding-left: 25px;
          &::after {
            display: block;
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #7EA2FF;
            position: absolute;
            left: 0px;
            top: 6px;
          }
        }
      }
    }
  }
}
</style>