export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $lang() {
          return this.$store.state.lang
        }
      }
    });
  }
}