<template>
  <div class="all-medal-wall">
    <Header></Header>
    <StudyContent></StudyContent>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import StudyContent from './StudyContent.vue'

export default {
  name: 'Study',
  components: {
    Header,
    Footer,
    StudyContent,
  },
}
</script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件



.all-medal-wall {
  position: relative;
  color: $fontcolor;
  .static-bg-1 {
    position: absolute;
    top: -99px;
    left: 0;
    z-index: 0;
  }
}
</style>