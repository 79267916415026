<template>
  <div class="c-cost-table">
	<div class="topdiv">{{$VExcellentTable("专业律师vs.文_14bb")}}</div>
  


  
    <a-table :columns="columns" :data-source="lists" :pagination="false" bordered>
      <span slot="comm" slot-scope="text">{{ text }}</span>
      <span slot="approximate" slot-scope="text">{{ text }}</span>
      <span slot="provider">{{$VExcellentTable("特征")}}</span>
      <span slot="approximate">
        <Language :text='$VExcellentTable("专业律师")'>
          <span slot="r1" slot-scope="data">{{ data.value }}<br></span>
          <span slot="r2" slot-scope="data">{{ data.value }}</span>
        </Language>
      </span>
      <span slot="scalability">{{$VExcellentTable("文案团队")}}</span>
    </a-table>

	<div class="topdiv">{{$VExcellentTable("选择移民律师，为_c48e")}}</div>
  </div>
</template>
<script>
import Language from '@/components/common/Language.vue';

    /* viai18n-disable */
    import $VExcellentTableMessages from "./ExcellentTable.messages.json";
  const $defaultObject = {
  components: {
    Language,
  },
  data() {
    return {
      columns: [
        {
          dataIndex: 'provider',
          key: 'provider',
          slots: { title: 'provider' },
          scopedSlots: { customRender: 'comm' },
        },
        {
          dataIndex: 'approximate',
          key: 'approximate',
          slots: { title: 'approximate' },
          scopedSlots: { customRender: 'approximate' },
        },
        {
          dataIndex: 'scalability',
          key: 'scalability',
          slots: { title: 'scalability' },
          scopedSlots: { customRender: 'comm' },
        }
      ],
    }
  },
  computed: {
	lists() {
		return [
			{
			  key: '1',
			  provider: this.$VExcellentTable("法律专业度","法律专业度"),
			  approximate: this.$VExcellentTable("深厚法律知识储备_0cac","深厚法律知识储备，精准解读政策，规避陷阱"),
			  scalability: this.$VExcellentTable("熟悉移民政策，但_a6d9","熟悉移民政策，但处理复杂法律问题能力有限"),
			},
			{
			  key: '2',
			  provider: this.$VExcellentTable("定制化方案","定制化方案"),
			  approximate: this.$VExcellentTable("全面评估个案，量_d7cb","全面评估个案，量身定制方案，突出优势"),
			  scalability: this.$VExcellentTable("文案模板化，难以_390d","文案模板化，难以精准匹配个别情况"),
			},
			{
			  key: '3',
			  provider: this.$VExcellentTable("文案撰写水平","文案撰写水平"),
			  approximate: this.$VExcellentTable("专业、严谨、逻辑_36e1","专业、严谨、逻辑清晰，展现最佳申请形象"),
			  scalability: this.$VExcellentTable("文案水平参差不齐_8b4e","文案水平参差不齐，说服力有限"),
			},
			{
			  key: '4',
			  provider: this.$VExcellentTable("合法途径指引","合法途径指引"),
			  approximate: this.$VExcellentTable("避免非法陷阱，保_cea5","避免非法陷阱，保障安全可靠"),
			  scalability: this.$VExcellentTable("无法辨别非法途径_dc9e","无法辨别非法途径，风险较高"),
			},
			{
			  key: '5',
			  provider: this.$VExcellentTable("全程支持","全程支持"),
			  approximate: this.$VExcellentTable("提供一对一咨询，_95a6","提供一对一咨询，应对突发状况"),
			  scalability: this.$VExcellentTable("沟通渠道有限，无_a002","沟通渠道有限，无法提供全方位支持"),
			}
		]
	}
  },
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VExcellentTable(key,origin){
      const messages = $VExcellentTableMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VExcellentTable(key,origin){
      const messages = $VExcellentTableMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

.topdiv{
	width: 100%;
	color: $fontcolor;
	font-weight:bold;
	font-size:24px;
	text-align:center;
	padding:30px;
	@screen md {
	  padding: 10px;
	  font-size:20px;
	}
}
.c-cost-table {
  width: 100%;
  color: $fontcolor;
  :deep(.ant-table-wrapper) {
    /* * {

    } */
    table {
      width: 100%;

      border-radius: 10px;
      overflow: hidden;
      box-shadow: $box_shadow;
	  
      .ant-table-thead {
        tr {
          th {
            width: 33%;
            padding: 25px 60px;
            border-color: #222428;
            border-right: none;
            @screen md {
              padding: 5px 10px;
            }
          }
          >th:first-of-type {
            text-align: left;
            border-bottom-left-radius: 10px;
          }
          >th:last-of-type {
            text-align: center;
            border-bottom-right-radius: 10px;
          }
        }
      }
      .ant-table-tbody {
        tr {
          
          td {
            padding: 20px;
            border-color: #222428;
            text-align: center;
            @screen md {
              padding: 10px;
            }
          }
          >td:first-of-type {
            text-align: left;
            padding-left: 60px;
            @screen md {
              padding-left: 5px;
            }
          }
          >td:last-of-type {
            border-right: none;
          }
        }
        tr:last-of-type {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          td {
            border: 1px solid #1F7691;
          }
          td:first-of-type {
            border-right-color: #222428;
          }
          td:nth-of-type(2) {
            border-left: none;
            border-right-color: #222428;
          }
          td:last-of-type {
            border-left: none;
          }
        }
      }
      .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) td {
        background-color: transparent;
        /* border: 1px solid #1F7691;
        border-color: #1F7691; */
      }
      /* .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) td:first-of-type {
        border-right-color: #222428;
      } */
      /* .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) td:nth-of-type(2) {
        border-left: none;
        border-right-color: #222428;
      } */
      /* .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) td:last-of-type {
        border-left: none;
      } */
      .ant-table-tbody > tr:last-of-type {
        td:first-of-type {
          border-bottom-left-radius: 10px;
        }
        td:last-of-type {
          border-bottom-right-radius: 10px;
        }
      }
      .ant-table-thead > tr > th {
		background-color: transparent;
		box-shadow: $box_shadow;
        font-weight: bold;
		font-size:26px;
        text-align: center;
		
      }
    }

  }
}
	  
	  
</style>