<template>
	<div class="dialog-box" v-if="isShow">
		<div class="dialog-error">
			<a-icon class="iconguanbi" type="close" @click.stop="close" />
			<h3> Please link a wallet to use, recommended to download the following wallets</h3>
			<h3> </h3>
			<div class="mdialog_content ">
				<ul class="walletList">
					<li>
						<div class="left flex">
							<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/metamask3.png" />
							<span>(PC)</span>
						</div>
						<a href="https://metamask.io">Download</a>
					</li>
					<li>
						<div class="left">
							<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/tp3.png" />
							<span>(Mobile)</span>
						</div>
						<a href="https://www.tokenpocket.pro">Download</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	export default {
		name: "mdialog",
		data() {
			return {
				abc: " ",
			};
		},
		props: {
			// 插件类型：
			// receive  收益领取成功
			// confirm  确认弹框
			// noenv  没有环境
			// chainIdError
			// setInput
			type: {
				type: String,
				default: "receive",
			},
			// 提示title
			title: {
				type: String,
				default: "Prompt",
			},
			// 提示信息
			msg: {
				type: String,
				default: "",
			},
			// 是否显示提示框
			isShow: {
				type: Boolean,
				default: false,
			},
			// confirm插件接收的确认事件
			success: {
				type: Function,
				default: () => {
					console.log("success");
				},
			},
			// confirm插件接收的取消事件
			cancel: {
				type: Function,
				default: () => {
					console.log("cancel");
				},
			},
		},
		computed: {
			...mapState({
				locale: (state) => state.common.locale,
			}),
		},
		mounted() {},
		methods: {
			// 关闭弹窗
			close() {
				this.isShow = false;
				let tip_alert = document.getElementById("dialog-box");
				tip_alert.parentNode.removeChild(tip_alert);
			},
			// alert 插件点击阴影区域关闭弹窗
			closeMask() {
				if (this.type === "alert") {
					this.close();
				}
			},
			// confirm 插件点击取消触发的事件
			cancelEvent() {
				this.cancel();
				this.close();
			},
			// confirm 插件点击确定触发的事件
			successEvent() {
				this.success();
				this.close();
			},
		},
	};
</script>
<style lang='scss' scoped>
	.dialog-box {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 99;
		background: rgba(1, 1, 1, .8);
	}

	.dialog-error {

		position: absolute;
		top: 50%;
		left: 50%;
		transition: all 1s;
		transform: translate(-50%, -50%);

		background: #0B0B0B;
		padding: 30px;
		border: 1px solid #33C4F2;
		border-radius: 10px;
		width: 420px;

		.iconguanbi {
			position: absolute;
			right: 20px;
			top: 20px;
			z-index: 1;
			color: #fff;
		}

		h3 {
			font-size: 16px;
			margin: 0;
			color: #fff;
		}

		.mdialog_content {
			p {
				font-size: 16px;
				margin: 0;
				color: #fff;
			}

			.walletList {

				li {
					padding-top: 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				.left {
					display: inline-flex;
					align-items: center;
					height: 36px;

					img {
						width: 189px;

					}

					span {
						display: inline-block;
						color: #fff;
						padding-left: 10px;
						/* padding-bottom: 8px; */
					}
				}


				a {
					border-radius: 15px;
					font-size: 14px;
					color: #fff;
					font-weight: 400;
					padding: 4px 20px;
					background: linear-gradient(to right, #203dfc, #33c4f2);
					border: none;
				}
			}
		}
		@screen md {
			width: 90%;
			height: unset !important;
			padding: 30px;
			.iconguanbi{
				font-size: 16px;
			}
			.walletList {
				.left {
					img {
						width: 100px !important;
					}

					span {
						font-size: 12px;
						padding-bottom: 4px !important;
					}
				}

				a {
					/* margin-top: 5px; */
					color: #fff;
					font-size: 10px !important;
					font-weight: 400;
					padding: 2px 10px !important;
				}
			}
		}

	}
</style>