import {
	Select,
	Dropdown,
	Menu,
	Breadcrumb,
	notification  ,
	Icon,
	Form,
	Input,
	Button,
	Radio,
	message,
	Table,
	Tooltip,
	Progress,
	Popover,
	Carousel,
	Pagination ,
	Modal,
	Rate,
	Slider,
	Drawer,
	Collapse
} from 'ant-design-vue';

import 'ant-design-vue/lib/select/style';
import 'ant-design-vue/lib/dropdown/style';
import 'ant-design-vue/lib/menu/style';
import 'ant-design-vue/lib/breadcrumb/style';
import 'ant-design-vue/lib/icon/style';
import 'ant-design-vue/lib/form/style';
import 'ant-design-vue/lib/input/style';
import 'ant-design-vue/lib/button/style';
import 'ant-design-vue/lib/radio/style';
import 'ant-design-vue/lib/message/style';
import 'ant-design-vue/lib/table/style';
import 'ant-design-vue/lib/notification/style';
import 'ant-design-vue/lib/progress/style';
import 'ant-design-vue/lib/tooltip/style';
import 'ant-design-vue/lib/carousel/style';
import 'ant-design-vue/lib/modal/style';
import 'ant-design-vue/lib/pagination/style';
import 'ant-design-vue/lib/popover/style';
import 'ant-design-vue/lib/rate/style';
import 'ant-design-vue/lib/slider/style';
import 'ant-design-vue/lib/drawer/style';
import 'ant-design-vue/lib/collapse/style';

export default {
	install(Vue) {
		Vue.component(Select.name, Select);
		Vue.use(Select);
		Vue.component(Dropdown.name, Dropdown);
		Vue.use(Dropdown);
		Vue.component(Menu.name, Menu);
		Vue.use(Menu);
		Vue.use(Breadcrumb);
		Vue.use(Icon);
		Vue.use(Form);
		Vue.use(Input);
		Vue.use(Button);
		Vue.use(Radio);
		Vue.use(Table);
		Vue.use(Tooltip);
		Vue.use(Progress);
		Vue.use(Popover);
		Vue.use(notification);
		Vue.use(Carousel);
		Vue.use(Modal);
		Vue.use(Rate);
		Vue.use(Slider);
		Vue.use(Pagination);
		Vue.use(Drawer);
		Vue.use(Collapse);
		Vue.prototype.$message = message;
		Vue.prototype.$notification = notification;
	}
}