import Cookies from "js-cookie";
// import store from "../store";

export function getAddress() {
  let a = localStorage.getItem('address');
  let b = Cookies.get('address');
  return a || b;
}

export function setAddress(token) {
  localStorage.setItem('address', token);
  Cookies.set('address', token);
}

