<template>
	<div class="campaign-box">
		<div class="campaign-card campaign-card-1 ">
			<p class="card-1-1">{{$VteamFrom("在C&RAMGl_d259")}}<br>{{$VteamFrom("我们知道我们的员_8195")}}</p>
			<div class="card-1-2 flex-center">
				<span>{{$VteamFrom("我们努力招募、培_49e2")}}<br>{{$VteamFrom("我们所做的一切都_85d2")}}</span>
			</div>
			<p class="card-1-3">
				
			</p>
			<a-button type="primary" @click="contact_()">{{$VteamFrom("现在咨询")}}</a-button>
			
		</div>
		
		<div class="campaign-card-2-box flex-between" v-for="(item, index) in teamList" :key="item.key">
			<div class="campaign-card" :class="index_==0? 'campaign-card-2':'campaign-card-3'"  v-for="(item_, index_) in item" :key="item_.key">
				<div class="campaign-card-img">
					<img  :src="item_.lvimg" alt="" srcset="" />
				</div>
				<div class="tip-box">
					<span class="open" v-if="true">{{ item_.lv }}</span>
					<span class="closed" v-else>Closed</span>
				</div>
				<p class="card-1-1">{{ item_.name }}</p>
				
				<p class="card-1-3">
					{{ item_.good_at }}
				</p>
				<a-button type="primary" @click="contact_()">{{$VteamFrom("立即咨询")}}</a-button>
			</div>
		</div>
		
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	
    /* viai18n-disable */
    import $VteamFromMessages from "./teamFrom.messages.json";
  const $defaultObject = {
		name: 'Name',
		data() {
			return {

			}
		},
		computed: {
			teamList() {
				return [
					[
						{ name: this.$VteamFrom("斯蒂芬·W·格林","斯蒂芬·W·格林"), lv: this.$VteamFrom("移民法专家","移民法专家"),lvimg:'/img/team/1_1.jpg', good_at: this.$VteamFrom("在移民的各个领域_6ded","在移民的各个领域拥有丰富的经验。斯蒂芬多年来被认为是移民法领域的领先从业者。斯蒂芬被认为是该领域的主题专家，经常被邀请作为小组成员、主持人和讲师发言") },
						{ name: this.$VteamFrom("索莎娜·T·格林","索莎娜·T·格林"), lv: this.$VteamFrom("公民法专家","公民法专家"),lvimg:'/img/team/1_2.jpg', good_at: this.$VteamFrom("国际律师协会的官_7cd1","国际律师协会的官方研究合作伙伴，以及移民法专业的《最佳律师》杂志。《钱伯斯与合作伙伴》将 Shoshana 评为全球和全国公认的从业者。") },
						{ name: this.$VteamFrom("希莱特·斯坦因","希莱特·斯坦因"), lv: this.$VteamFrom("业务总监","业务总监"),lvimg:'/img/team/1_3.jpg', good_at: this.$VteamFrom("拥有超过14年专_bff1","拥有超过 14 年专门从事移民和公民法领域的经验，多次被评为“值得关注的领先律师”，实践者自己的同行和同事的卓越表现”。") },
					],
					[
						{ name: this.$VteamFrom("克莱尔·麦克莱恩","克莱尔·麦克莱恩"), lv: this.$VteamFrom("助理律师","助理律师"),lvimg:'/img/team/2_1.jpg', good_at: this.$VteamFrom("协助客户处理各种_04ae","协助客户处理各种公民身份和移民法事务，包括临时工作许可证和学习许可证、永久居留和公民身份申请、复杂的入境事务以及与移民相关的合规问题。") },
						{ name: this.$VteamFrom("迈克尔·休斯","迈克尔·休斯"), lv: this.$VteamFrom("助理律师","助理律师"),lvimg:'/img/team/2_2.jpg', good_at: this.$VteamFrom("从事劳动力市场影_0c04","从事劳动力市场影响评估申请领域，并协助面临服务部和移民、难民和公民部合规检查的雇主。") },
						{ name: this.$VteamFrom("乔纳森·格林","乔纳森·格林"), lv: this.$VteamFrom("助理律师","助理律师"),lvimg:'/img/team/2_3.jpg', good_at: this.$VteamFrom("协助企业进行长期_c520","协助企业进行长期劳动力规划，包括企业员工调动、联邦技术工人申请、投资者申请和工作许可证。协助企业客户处理与国际流动计划和临时外籍工人计划相关的合规事务。") },
					],
				]
			},
		},
		mounted() {

		},
		methods: {
			contact_(){
				_MEIQIA('showPanel');
			},
		}
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VteamFrom(key,origin){
      const messages = $VteamFromMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VteamFrom(key,origin){
      const messages = $VteamFromMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

	/*头部*/
	.campaign-box {
		position: relative;
		color: $fontcolor;
		
		.campaign-card {
			
			flex: 1;
			//border: 2px solid rgba(46, 42, 65, 1);
			border-radius: 20px;
			position: relative;
			top: 0;
			text-align: center;
			padding: 60px 0 50px;
			
			
			/*
			background: radial-gradient(67.76% 72.76% at 50% 10.33%, #000000 23%, rgba(4, 1, 19, 0.5) 66%, #3D54CA 128%),
				linear-gradient(0deg, #2E2A41, #2E2A41),
				linear-gradient(0deg, #D7CCF6 -8.91%, rgba(19, 8, 48, 0) 45.33%);
			*/
			@screen md {
				padding: 40px 0;
			}

			&:hover {
				transition: all 1s;
				top: -20px;
				&::after {
					opacity: 1;
				}
				box-shadow: $box_shadow;
			}

			.tip-box {
				position: absolute;
				top: 0px;
				right: 0px;
				.open, .closed {
					padding: 4px 15px;
		
					font-size: 16px;
					font-weight: 590;
					display: inline-block;
					border-radius: 0px 10px;
					/* border: 1px solid rgba(255, 255, 255, 0.20); */
				}
				.open {
					background: linear-gradient(131deg, #C4923E -1.25%, #E4BE68 28.42%, #F8DA83 53.61%, #FEE07E 63.92%, #F3C433 82.64%, #E0AE2D 93.52%);
				}
				.closed {
					border-radius: 0px 10px;
					background: #6c6c6c;
				}
			}

			.card-1-1 {
				margin: 0;
				text-align: center;
				
				font-size: 60px;
				font-weight: 800;
				line-height: 60px;
				letter-spacing: 0.01em;



				@screen md {
					
					font-size: 32px;
					font-style: normal;
					font-weight: 800;
					line-height: 120%;
					/* 38.4px */
					letter-spacing: 0.32px;
				}

			}

			.card-1-2 {
				padding: 10px;

				img {
					width: 32px;
					height: 32px;
					margin-right: 10px;
				}

				span {
					font-size: 26px;
					font-weight: 590;
					line-height: 40px;
					letter-spacing: 0px;
					text-align: left;


				}

				@screen md {
					padding: 16px 0;

					img {
						width: 20px;
						height: 20px;
					}

					span {

						
						font-size: 16px;
						font-style: normal;
						font-weight: 590;
						line-height: 24px;
						/* 150% */
					}
				}
			}

			.card-1-3 {
				padding: 10px;
				padding-bottom: 80px;
				
				font-size: 24px;
				font-weight: 510;
				line-height: 29px;
				letter-spacing: 0px;
				text-align: center;
				
				margin: 0;

				@screen md {
					padding-bottom: 40px;

					
					font-size: 16px;
					font-style: normal;
					font-weight: 510;
					line-height: normal;
				}

			}

			.ant-btn-primary {
				position: absolute; /* 绝对定位 */
				bottom: 10px; /* 距离容器底部为0像素 */
				left: 50%; /* 左边界与容器宽度的一半重合 */
				transform: translateX(-50%); /* 向左移动自身宽度的一半，达到水平居中*/
				
				width: 420px;
				height: 56px;
				box-sizing: border-box;
				border-radius: 100px;
				line-height: 56px;
				border: 0;
				background: $bt_bg_color;

				
				font-size: 24px;
				font-weight: 700;

				@screen md {
					width: 240px;
					height: 42px;
					line-height: 42px;
					font-size: 14px;
				}
				
				&:hover {
					box-shadow: $box_shadow;
					background: $jbfontbacColor;
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					font-weight: bold;
				}
			}


			.campaign-card-img{
				position:relative;
				width:100%;
				height:100%;
				top:0px;
				left:0px;
				border-radius: 100px;

				img{
					width:100%;
					height:100%;
					border-radius: 10px;
				}
			}
		}

	}
	
	/*列表*/
	.campaign-card-2-box {
		margin-top: 60px;
		

		.campaign-card-2,
		.campaign-card-3 {
			padding: 0 0 60px 0;
			box-shadow: $box_shadow;
			
			@screen md {
				box-shadow: $box_shadow;
			}
			
			.card-1-1 {
				margin: 5px;
				font-size: 26px;
				font-weight: 800;
				line-height: 26px;
				letter-spacing: 0em;
				text-align: center;


				span:nth-of-type(1) {
					background-image: linear-gradient(to right, #33C4F2, #1F35FD);
					-webkit-background-clip: text;
					background-clip: text;
					color: transparent;
				}



				@screen md {

					
					font-size: 26px;
					font-style: normal;
					font-weight: 800;
					line-height: 120%;
					/* 38.4px */
				}
			}

			.card-1-2 {
				padding: 20px 0;

				img {
					width: 40px;
					height: 40px;
					border-radius: 100px;

				}

				span {
					
					font-size: 16px;
					font-weight: 800;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: left;

					background-image: linear-gradient(to right, #33C4F2, #1F35FD);
					-webkit-background-clip: text;
					background-clip: text;
					color: transparent;
				}

				@screen md {
					padding: 16px 0;

					img {
						width: 24px;
						height: 24px;
					}

					span {
						
						font-size: 16px;
						font-style: normal;
						font-weight: 800;
						line-height: 120%;
						/* 24px */
					}
				}
			}

			.card-1-3 {
				padding: 10px;
				
				font-size: 12px;
				line-height: 20px;
				letter-spacing: 0px;

				
				min-height:110px;

				@screen md {
					margin: 0;
					padding: 10px;

					
					font-size: 16px;
					font-style: normal;
					font-weight: 510;
					line-height: normal;
				}

			}

			.ant-btn-primary {
				position: absolute; /* 绝对定位 */
				bottom: 10px; /* 距离容器底部为0像素 */
				left: 50%; /* 左边界与容器宽度的一半重合 */
				transform: translateX(-50%); /* 向左移动自身宽度的一半，达到水平居中*/
			
				width: 150px;
				height: 40px;
				box-sizing: border-box;
				border-radius: 150px;
				line-height: 40px;
				border: 0;
				background: $bt_bg_color;

				
				font-size: 16px;
				font-weight: 700;

				@screen md {
					width: 240px;
					height: 42px;
					line-height: 42px;
					font-size: 14px;
				}
				
				&:hover {
					box-shadow: $box_shadow;
					background: $jbfontbacColor;
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					font-weight: bold;
				}
			}

		}

		.campaign-card-2 {

			//border: unset;
			//background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/FAUCET-box1.png') no-repeat;
			background-size: 100% 100%;
			// background: radial-gradient(89.29% 89.29% at 49.94% 15.38%, #000000 0%, rgba(4, 1, 19, 0) 39.95%, #ca9f3dbd 100%),
			// 	linear-gradient(0deg, #2E2A41, #2E2A41),
			// 	linear-gradient(0deg, #F6EACC -5.36%, rgba(48, 10, 8, 0) 54.53%);.
			
			&:hover {
				transition: all 0.5s;
				box-shadow: 0px 50px 100px 0px rgb(246,234,204 ,0.37);
				top: -10px;
			
				&::after {
					opacity: 1;
				}
				box-shadow: $box_shadow;
			}
		}

		.campaign-card-3 {

			//border: unset;
			margin-left: 34px;
			//background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/FAUCET-box2.png') no-repeat;
			background-size: 100% 100%;
			// background: radial-gradient(81.46% 81.46% at 49.94% 21.57%, #000000 0%, rgba(4, 1, 19, 0) 39.26%, #62ca3d69 100%),
			// 	linear-gradient(0deg, #2E2A41, #2E2A41),
			// 	linear-gradient(0deg, #E6F6CC -7.14%, rgba(19, 8, 48, 0) 50%);
			&:hover {
				transition: all 0.5s;
				box-shadow: 0px 50px 100px 0px rgb(230,246,204 ,0.43);
				top: -10px;
			
				&::after {
					opacity: 1;
				}
				box-shadow: $box_shadow;
			}
		}



		@screen md {
			margin-top: 0;
			display: flex;
			flex-direction: column;

			.campaign-card-2,
			.campaign-card-3 {
				width: 100%;
				margin-top: 20px;
				flex: unset;
			}

			.campaign-card-3 {
				margin: 0;
				margin-top: 20px;
			}
		}



	}
</style>