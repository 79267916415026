<template>
  <div class="c-zkp-tit">
    <div class="zkp-title">
      
      
      <Language :text='$VZkpTit("[r1]BP-F_217d")'>
        <span slot="r1" class="light-span" slot-scope="data">{{ data.value }}</span>
        <span slot="r2" slot-scope="data">{{ data.value }}</span>
      </Language>
      
    </div>
  </div>
</template>
<script>
import Language from '@/components/common/Language.vue';

    /* viai18n-disable */
    import $VZkpTitMessages from "./ZkpTit.messages.json";
  const $defaultObject = {
  components: {
    Language
  }
  
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VZkpTit(key,origin){
      const messages = $VZkpTitMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VZkpTit(key,origin){
      const messages = $VZkpTitMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
.c-zkp-tit {
  margin-top: 97px;
  margin-bottom: 70px;
  @screen md {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .zkp-title {
    max-width: 1050px;
    span {
      font-size: 64px;
      font-weight: 700;
      @screen md {
        font-size: 30px;
      }
    }
    .light-span {
      background-image: linear-gradient(to right, #33C4F2, #1F35FD);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
}
</style>