import Vue from 'vue';
import Vuex from 'vuex';
import persistedstate from "vuex-persistedstate"
import { defaultLang } from '../../config';
import { setAddress, getAddress } from '@/utils/auth.js';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		lang: window.localStorage.getItem('lang') || defaultLang,
		locales: [{
				value: 'en',
				text: 'EN'
			},
			{
				value: 'zh',
				text: 'ZH'
			},
		],
		isConnect: false,
		address: getAddress(), //用户地址
		addressinfo: {},
		sbtMedal: {},
		walletBalance: [],
		allBalance: 0, //总资产
		requestName: {},
		ceatedTeam: 0, //总资产
		joinedTeam: 0, //总资产

		nftAddress: {
			outstandingcaption: "0x650D0bDe3fB6eFA668f416B598A517772021A533",
			outstandingteam: "0x0b2cAf1f2b3644e657b97E2Abb6AD7D89e45fce8",
			honorablecaption: "0x3c01716cD3a5F7D3e7AdA9f132F45a2F1342f5FA",
			honorableteam: "0x88c4fB942517a4FA3C92aC96c71F3dDBED2444B0",
			setterv1: "0xA1e40E250a2560ac98e1190952Ced940b11F1524",
			setterv2: "0xe2C103a59f1A5E574dFF36Ac22344672D37800bc",
			setterv3: "0xF60aD1EcFb2Cd19E7Be2C812fdC7Bd415528fF08",
			starstuddedcaption: "0xadE6147319C9B1Aa465Ee31374Acc5E251F1f03E",
		},
		config805:'',
		config:{},



	},
	getters: {
		currentLang(state) {
			return state.lang
		},

	},
	mutations: {
		//清理数据
		CLEAR(state) {
			setAddress('')
			state.address = '';
			state.allBalance = 0;
			state.ceatedTeam = 0;
			state.joinedTeam = 0;
			state.addressinfo = {};
			state.sbtMedal = {};
			state.walletBalance = [];
		},
		SET_LANG(state, data) {
			state.lang = data;
		},
		setRequest(state, data) {
			state.requestName[data.name] = data.status
		},
		// 保存网络环境
		SET_NETWORK: (state, network) => {
			state.network = network;
		},
		// 保存地址
		SET_ADDRESS: (state, address) => {
			console.log('address', address)
			state.address = address;
		},
		// 保存地址
		SET_ADDRESSINFO: (state, addressinfo) => {
			state.addressinfo = addressinfo;
		},
		// 保存勋章
		SET_SBTMEDAL: (state, sbtMedal) => {
			state.sbtMedal = sbtMedal;
		},
		SET_BALANCE: (state, walletBalance) => {
			state.walletBalance = walletBalance;
		},
		SET_ALLBALANCE: (state, allBalance) => {
			state.allBalance = allBalance;
		},
		SET_ceatedTeam: (state, ceatedTeam) => {
			state.ceatedTeam = ceatedTeam;
		},
		SET_joinedTeam: (state, joinedTeam) => {
			state.joinedTeam = joinedTeam;
		},
		SET_805: (state, config805) => {
			state.config805 = config805;
		},
		SET_CONFIG: (state, config) => {
			state.config = config;
		},
	},
	actions: {
		setLang({ commit }, payload) {
			commit("SET_LANG", payload)
		},
	},
	plugins: [
		persistedstate({
			storage: window.sessionStorage,
			reducer(val) {
				return val
			}
		})
	]
})