<template>
  <div class="c-supplu-form">
    <div class="form">
      <div class="form-title">
        <span>{{$VAccessFrom("请提供以下任意一_7c30")}}</span>
      </div>
      <div class="form-table">
        <a-form :form="form">
          <a-form-item>
            <div class="form-input">
              <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/discord-icon.png" alt="">
              <a-input :placeholder='$VAccessFrom("Discord账_3a11")' v-decorator="['discord', { rules: [{ required: true, message: $VAccessFrom('请输入Disco_b7af') }] }]" />
            </div>
          </a-form-item>
          <a-form-item>
            <div class="form-input">
              <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/twitter-icon.png" alt="">
              <a-input :placeholder='$VAccessFrom("Twitter账_f7ea")' v-decorator="['twitter', { rules: [{ required: true, message: $VAccessFrom('请输入Twitt_0f78') }] }]" />
            </div>
          </a-form-item>
          <a-form-item>
            <div class="form-input">
              <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/email-icon.png" alt="" style="width: 24px; margin-left: -3px;margin-top: 4px;">
              <a-input :placeholder='$VAccessFrom("Email账号")' v-decorator="['email', { rules: [{ required: true, message: $VAccessFrom('请输入邮箱') }] }]" />
            </div>
          </a-form-item>
        </a-form>
      </div>

      <div class="form-title form-use-title">
        <span>{{$VAccessFrom("您对BP-FLA_f91b")}}</span>
      </div>
      <div class="form-table">
        <a-form :form="form2">
          <a-form-item style="margin: 0;">
            <div class="form-radio">
              <a-radio-group v-decorator="['comment', { rules: [{ required: true, message: $VAccessFrom('请选择!') }] }]">
                <a-radio value="trading">{{$VAccessFrom("训练")}}</a-radio>
                <a-radio value="inference">{{$VAccessFrom("理论")}}</a-radio>
                <a-radio value="Equally interested in both">{{$VAccessFrom("同样感兴趣")}}</a-radio>
              </a-radio-group>
            </div>
          </a-form-item>
        </a-form>
      </div>

      <div class="form-title form-txt-title">
        <span>{{$VAccessFrom("您需要哪些硬件（_9890")}}</span>
      </div>

      <div class="form-table py-0">
        <a-textarea v-model="supply" :placeholder='$VAccessFrom("在此键入...._dd45")' :rows="3" />
      </div>
      <div class="submit">
        <a-button type="primary" @click="handleSubmit">{{$VAccessFrom("提交")}}</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { add_opinion } from '@/request/api';


    /* viai18n-disable */
    import $VAccessFromMessages from "./AccessFrom.messages.json";
  const $defaultObject = {
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      form2: this.$form.createForm(this, { name: 'coordinated' }),
      supply: ''
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        this.form2.validateFields(async (err2, values2) => {
          if (err || err2 || this.loading) {
            console.log('Received values of form: ', values, values2);
            return
          }
          if (this.$store.state.requestName.add_opinion) {
            this.$message.info(this.$VAccessFrom("操作频繁！","操作频繁！"));
            return
          }
          let obj = {
            ...values,
            ...values2,
            supply: this.supply
          }
          
          
          try {
            const msg = this.$message.loading('Loading...', 0);
            this.loading = true
            // 请求
            let res = await add_opinion(obj)
            if (res.data == 1) {
              // 状态
              this.loading = false
              setTimeout(msg, 0);
              this.$message.success(this.$VAccessFrom("提交成功","提交成功"));
  
              // 节流
              this.$store.commit('setRequest', {name: 'add_opinion', status: true})
              setTimeout(() => {
                this.$store.commit('setRequest', {name: 'add_opinion', status: false})
              }, 600000);
            }
          } catch (error) {
            // 状态
            this.loading = false
            this.$message.info(this.$VAccessFrom("提交失败","提交失败"));
          }
        })
      });
    },
  },
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VAccessFrom(key,origin){
      const messages = $VAccessFromMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VAccessFrom(key,origin){
      const messages = $VAccessFromMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
.c-supplu-form {
  margin-top: 80px;

  .form {
    max-width: 353px;
    margin: auto;

    .form-title {
      font-size: 14px;
      color: #fff;
      line-height: 22px;
      white-space: nowrap;
      margin-bottom: 30px;
      @screen md {
        white-space: normal;
      }

      span {
        display: inline-block;
        padding-bottom: 10px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          width: 25px;
          height: 3px;
          background-color: #33c4f2;
        }
      }
    }
    .form-use-title {
      margin-top: 40px;
      span::after {
        background-color: #4e65f9;
      }
    }
    .form-txt-title {
      margin-top: 40px;
      span::after {
        background-color: #203dfc;
      }
    }
    .form-table {
      border-radius: 10px;
      border: 2px solid rgba(51, 196, 242, 0.60);
      padding-top: 16px;
      .ant-form-item {
        margin-bottom: 35px;
        &:last-of-type(.ant-form-item) {
          margin: 0;
        }
        .form-input {
          position: relative;
          img {
            width: 18px;
            position: absolute;
            left: 21px;
            top: 50%;
            transform: translateY(-50%);
          }
          &::after {
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            background: linear-gradient(270deg, rgba(255, 254, 254, 0.00) 0%, #33C4F2 49.14%, rgba(255, 255, 255, 0.00) 99.28%);
          }
        }
        .form-radio {
          /* background-color: red; */
          display: flex;
          .ant-radio-group {
            width: 100%;
            display: flex;
            flex-direction: column;
            .ant-radio-wrapper {
              margin: 0;
              padding-bottom: 17px;
              padding-left: 23px;
              margin-bottom: 23px;
              position: relative;
              color: rgba(255, 255, 255, 0.80);
              &::after {
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 1px;
                background: linear-gradient(270deg, rgba(255, 254, 254, 0.00) 0%, #33C4F2 49.14%, rgba(255, 255, 255, 0.00) 99.28%);
              }
              :deep(.ant-radio-inner) {
                width: 20px;
                height: 20px;
                background-color: transparent;
                border-color: #1F7691;
              }
              :deep(.ant-radio-inner::after) {
                background-color: #33C4F2;
                width: 20px;
                height: 20px;
                top: -1px;
                left: -1px;
                border-radius: 50%;
              }
            }
          }
        }
        input {
          width: 100%;
          background: transparent;
          border: none;
          padding-bottom: 6px;
          font-size: 14px;
          color: #fff;
          padding-left: 54px;
        }
        :deep(.ant-form-explain) {
          padding-left: 21px;
          margin-top: 3px;
          position: absolute;
        }
        .ant-input:focus {
          border: none;
          box-shadow: none;
        }
        
      }
      textarea {
        background-color: transparent;
        border: none;
        box-shadow: none;
        resize:none;
        color: #fff;
      }
    }
    .ant-radio {
      background-color: #203dfc !important;
      
    }
    
  }
  .submit {
    margin-top: 36px;
    height: 50px;
    border-radius: 10px;
    background: linear-gradient(to right, #203dfc, #33c4f2);
    .ant-btn-primary {
      width: 100%;
      height: 100%;
      font-size: 20px;
      color: #fff;
      font-weight: 400;
      background-color: transparent;
      border: none;
    }
  }
}
</style>