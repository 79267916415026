<template>
	<div class="c-header">
	
		
		<div class="content md:px-12">
			<div class="logo" @click="toHome">
				<img src="/img/logo/logo.png" alt="">
			</div>
			<div class="flex items-center md:hidden">
				
				<div class="menu-head" v-for="(item, index) in menuList" :key="index" v-if="item.isNavPc">
					<div class="menu-item-box">
						<span :ref="!item.route ? `menuitem` : 'menulink'" @click="toRoute(item)">{{ item.name }}</span>
						
						<div v-if="item.new" class="dot"></div>
					</div>
				</div>
			</div>
			
			
			<div class="connect-select">
				<div class="connect-box" @click="contact_()">
					<span>{{$VHeader("联系我们")}}</span>
				</div>
				<a-popover trigger="hover">

				</a-popover>
				
				<a-select class="plac-select md:hidden " dropdownClassName="plac-dropdown"
					v-model="currentlang">
					<a-select-option class="plac-select-item" v-for="lang in locales" :key="lang.value">
						{{ lang.text }}
					</a-select-option>
				</a-select>
				<i class="iconfont icon-menu text-24 hidden md:inline-block" :class="[visible ? 'text-253bff' : '']"
					@click="visible = !visible"></i>
			</div>
		</div>
		
		
		<HeadMenu :show="showMenu" @onClose="onClose" :activeMenu="activeMenu"></HeadMenu>
		
		
		
		
		<transition name="rotate-menu">
			<div class="mobile-header-menu pb-40 " v-if="visible">
				<a-menu mode="inline" :open-keys.sync="openKeys">
					<a-sub-menu v-for="(item, index) in menuList" :key="item.key">
						<template slot="title">
							<span class="title">
								<div class="title-box">
									<span>{{ item.name }}</span>
									<div v-if="item.new" class="dot"></div>
								</div>
								
							</span>
						</template>
						<a-menu-item v-if="item.href && item.children.length === 0" :disabled="item.disabled">
							<a v-if="item.href" :href="item.href" target="_blank">
								<div class="dot-box">
									<span>{{ item.name }}<div v-if="item.new" class="dot"></div></span> 
								</div>	
							</a>
							<a v-else @click="toPage(item.route)">
								<div class="dot-box">
									<span>{{ item.name }}<div v-if="item.new" class="dot"></div></span> 
								</div>
							</a>
						</a-menu-item>
						<a-menu-item v-else v-for="(v, k) in item.children" :key="v.key" :disabled="v.disabled">
							<a v-if="v.href" :href="v.href" target="_blank">
								<div class="dot-box">
									<span>{{ v.name }}<div v-if="v.new" class="dot"></div></span> 
								</div>
							</a>
							<a v-else @click="toPage(v.route)">
								<div class="dot-box">
									<span>{{ v.name }}<div v-if="v.new" class="dot"></div></span> 
								</div>
							</a>
						</a-menu-item>
					</a-sub-menu>
				</a-menu>
				<div class="line"></div>
				
				
				<a-menu mode="inline" :open-keys.sync="langList" @click="selectLang">
					<a-sub-menu key="lang">
						<template slot="title">
							<span class="title ">{{$VHeader("当前语言")}}<span
									class="lang-en">{{ currentlang.toUpperCase() }}</span></span>
						</template>
						<a-menu-item v-for="(v, k) in locales" :key="k" class="bg-transparent">
							<div :class="[v.value === currentlang ? 'lang-selected' : '']" class="flex items-center">
								{{ v.text }} <img class="ml-15 icon-check invisible"
									src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/icon-check.png" alt="">
							</div>
						</a-menu-item>
					</a-sub-menu>
				</a-menu>
				<div class="line"></div>
			</div>
		</transition>
	</div>
</template>

<script>


	import Button from '@/components/Button.vue';
	import { enable, disconnect } from "@/web3utils/base/index.js";
	import { mapState } from 'vuex'
	import ModelBridgeCampaign from './openShow/ModelBridgeCampaign.vue'
	import HeadMenu from './HeadMenu/HeadMennu.vue'
	
    /* viai18n-disable */
    import $VHeaderMessages from "./Header.messages.json";
  const $defaultObject = {
		components: {
			Button,
			ModelBridgeCampaign,
			HeadMenu
		},
		filters: {
			addressF(v) {
				if (!v) {
					return "-";
				}
				return v.replace(/^([A-Za-z0-9]{5})[A-Za-z0-9]{33}([A-Za-z0-9]{4})$/, "$1…$2");
			},
		},
		computed: {
			...mapState(['locales', 'lang', 'walletBalance', 'addressinfo', 'allBalance', 'ceatedTeam',
				'joinedTeam'
			]),
			isShowConnect() {
				const arr = [
					'Airdrop',
					'InvLogin',
					'Faucet',
					'Setting',
					'MedalWall',
					'AllMedalWall',
					'Campaign',
					'BridgeCampaign',
					'Dashboard',
					'InviteTeam',
				]
				if (arr.indexOf(this.$route.name) != -1) {
					return true
				}
				return false
			},
			menuList() {
				return [
					{
						name: this.$VHeader("业务范围","业务范围"),
						isNavPc: true,
						new: true,
						key: 'market',
						children: [
							{ name: this.$VHeader("全球移民","全球移民"), key: 'Migrate', route: 'Migrate', new: true  },
							{ name: this.$VHeader("全球留学","全球留学"), key: 'Study', route: 'Study', new: true  },
							{ name: this.$VHeader("全球签证","全球签证"), key: 'Visa', route: 'Visa', new: true  },
							{ name: this.$VHeader("全球公民身份规划","全球公民身份规划"), key: 'CitizenPassport', route: 'CitizenPassport' },
							{ name: this.$VHeader("国际财税风控规划","国际财税风控规划"), key: 'TaxGuardianSpectrum', route: 'TaxGuardianSpectrum' },
							{ name: this.$VHeader("子女国际教育规划","子女国际教育规划"), key: 'EdAdvantage', route: 'EdAdvantage' },
							{ name: this.$VHeader("尖端医疗养老规划","尖端医疗养老规划"), key: 'MediCareFuturoNexus', route: 'MediCareFuturoNexus' },
							{ name: this.$VHeader("全球资产配置规划","全球资产配置规划"), key: 'WealthNavigator', route: 'WealthNavigator' },
							{ name: this.$VHeader("家族财富传承规划","家族财富传承规划"), key: 'HeritageGuardAlliance', route: 'HeritageGuardAlliance' },
						]
					},
					{
						name: this.$VHeader("服务优势","服务优势"),
						isNavPc: true,
						key: 'ecosystem',
						children: [
							{ name: this.$VHeader("全球卓越律师团队","全球卓越律师团队"), key: 'Excellent', route: 'Excellent' },
							{ name: this.$VHeader("创新资产管理","创新资产管理"), key: 'Property', route: 'Property' },
						]
					},
					{
						name: this.$VHeader("成功案例","成功案例"),
						key: 'Case',
						isNavPc: true,
						children: [
							{ name: this.$VHeader("成功案例","成功案例"), key: 'Case', route: 'Case'}
						]
					},
					{
						name: this.$VHeader("公司简介","公司简介"),
						isNavPc: true,
						key: 'network',
						children: [
							{ name: this.$VHeader("公司简介","公司简介"), key: 'Company', route: 'Company'},
							{ name: this.$VHeader("团队介绍","团队介绍"), key: 'Team', route: 'Team'},
							{ name: this.$VHeader("联系我们","联系我们"), key: 'Contact', route: 'Contact' },
						]
					}
				]
			},

			currentlang: {
				get() {
					return this.lang
				},
				set(v) {
					this.$store.dispatch('setLang', v);
					window.localStorage.setItem('lang', v);
				}
			},
			coinIcon() {
				return {
					"USDT": 'https://wodswap.io/usdt.png',
					"ETH": 'https://wodswap.io/eth.png',
					"WBTC": 'https://wodswap.io/wbtc.png',
					"EON": 'https://wodswap.io/eon.png',
					"WOD": 'https://wodswap.io/wod.png',
					"SWAP-LP": 'https://wodswap.io/images/favicon.png',
				}
			}

		},
		data() {
			return {
				popoverShow: false,
				isFaucet: false,
				visible: false,
				openKeys: [],
				langList: ['lang'],
				showMenu: false,
				activeMenu: 0
			}
		},

		async mounted() {
			this.init();
			const PopupFaucet = sessionStorage.getItem("isPopupFaucet");
			if (!PopupFaucet) {
				if (this.$route.path == '/') {
					this.isFaucet = true;
				}
			}
			// console.log(this.$refs)
			this.$refs.menuitem.forEach((item, index) => {
				item.addEventListener('mouseenter', (e) => {
					this.activeMenu = index
					this.showMenu = true
				})
			})
			this.$refs.menulink.forEach((item, index) => {
				item.addEventListener('mouseenter', (e) => {
					this.showMenu = false
				})
			})
			
		},
		methods: {
			toBrideg() {
				window.open('https://bridge.wodrpc.org/#/index')
			},
			toDash() {
				if (this.$route.name == 'Dashboard') return
				this.$router.push('/Dashboard')
			},
			async init() {

			},
			toRoute(item) {
				if (!item.route || this.$route.name == item.route) return
				this.$router.push({ name: item.route })
			},
			onClose() {
				this.showMenu = false
				this.isFaucet = false
			},
			disconnect() {
				this.$store.commit('CLEAR', '');
				disconnect()
				window.location.reload();
			},
			closeFaucet() {
				sessionStorage.setItem("isPopupFaucet", true);
				this.isFaucet = false
			},

			selectLang({ item }) {
				console.log(item);
				this.currentlang = this.locales[item.index].value;
			},
			toPage(name, item = {}) {
				if (this.$route.name == name) return
				if (!name) {
					window.open(item.href)
				} else {
					this.$router.push({ name: name })
				}
			},
			toHome() {
				if (this.$route.name == 'Home') return
				this.$router.push({ name: 'Home' })
			},
			contact_(){
				_MEIQIA('showPanel');
			},
		}
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VHeader(key,origin){
      const messages = $VHeaderMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VHeader(key,origin){
      const messages = $VHeaderMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss">
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

	.c-header {
		width: 100%;
		height: 80px;
		border-bottom: 1px solid #312d3a;
		position: sticky;
		top: 0;
		background-color: $tm_background_color;
		backdrop-filter: blur(30px);
		z-index: 1000;


		@screen md {
			height: 55px;
			position: sticky;
			left: 0;
			right: 0;
			top: 0;
			backdrop-filter: blur(30px);
		}

		&>.content {
			height: 80px;
			display: flex;
			align-items: center;
			padding: 0 45px;
			justify-content: space-between;
			gap: 20px;

			.items-center {
				display: flex;
				gap: 40px;
			}

			@screen md {
				height: 55px;
			}

			.logo {
				
				display: flex;
				align-items: center;
				img{
					height: 80px;
					width: 80px; 
					max-width: 80px;
				}
				@screen md {
					img {
						height: 50px;
						width: 50px; 
						max-width: 50px;
					}
				}
			}

			.menu-item {
				font-size: 16px;
				text-decoration: none;
				color: rgba(255, 255, 255, 0.6);
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 62px;
					margin-left: 6px;
				}
			}

			.menu-head {
				.menu-item-box {
					display: flex;
					align-items: flex-start;
					gap: 3px;
					img {
						width: 42px;
						/* height: 26px; */
					}
					.dot {
						width: 6px;
						height: 6px;
						background-color: #4CE4F9;
						border-radius: 50%;
					}
				}
				span {
					cursor: pointer;
					color: $fontcolor;
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
					/* 150% */
				}
				
				&:hover span {
                  background: $jbfontbacColor;
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
				  font-weight: 900;
				}
			}

			.select {
				height: 32px;
				background-color: #121821;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 101px;
				border-radius: 30px;
				color: #ffffff;
				font-size: 14px;
				font-weight: 600;
				cursor: pointer;

				img {
					margin-left: 10px;
				}
			}
		}

		
		.connect-select {
			display: flex;
			align-items: center;
			gap: 17px;
			margin-right: 10px;
			position: relative;
			font-weight: bold;
			
			.connect-box {

				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				
				background-color: $bt_bg_color;
				color: $bt_ft_color;
				border-radius: $bt_bo_radius;
				height: $bt_bo_height;
				
				
				border: 0 none;
				padding: 0 17px;

				cursor: pointer;
				font-size: 16px;

				backdrop-filter: blur(100px);
				
				
				&:hover {
					.popover-Box {
						display: block;
					}
					background: $jbfontbacColor;
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					box-shadow: $box_shadow;
					
				}
	
				span {
					white-space: nowrap;
				}

				.img-box {
					margin-left: 10px;
					width: 20px;
					height: 20px;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}

			.faucet {
				background: linear-gradient(90deg, #2D41FA 0%, #4CE4F9 100%) !important;
			}


			.LoginConnect {
				position: relative;

				.disconnect {
					position: absolute;

				}
			}
		}
		
		
		.plac-select {
			min-width: 80px;
			/* margin-left: 17px; */
			color: $bt_ft_color;
			font-weight: bold;
			border-radius: 30px;
			backdrop-filter: blur(100px);
			
			
			.plac-select-item{
				background-color: $bt_bg_color;
			}
			
			.ant-select-selection {
				border-radius: 30px;
				border: 0 none;
				padding: 0 20px;
			
				background-color: $bt_bg_color;

				
				.ant-select-arrow {
					color: $bt_ft_color;
					right: 20px;
					top: 42%;
					font-size:14px;
				}

			}

			
			
			&:hover {
				.ant-select-selection {
					box-shadow: $box_shadow;
					.ant-select-selection-selected-value {
						background: $jbfontbacColor;
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
					background-color: transparent;
					.ant-select-arrow {
						color: $bt_bg_color;
					}
				}
			}
		}
		
		.mobile-header-menu {
			position: fixed;
			top: 55px;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 1000;
			transform-origin: center top;
			background: $phonecdbacColor;
			color: $fontcolor;
			.ant-menu {
				border-right: none;
			
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				.ant-menu-submenu-title {
					&:hover {
						box-shadow: $box_shadow;
						color: $fontcolor;
						border-radius: $bt_bo_radius;
						font-weight: bold;
					}

					&:active {
						background-color: #dbdada;
					}
				}
				
				.ant-menu-submenu {
					.title {
						display: flex;
						align-content: center;
						.title-box {
							position: relative;
							.dot {
								position: absolute;
								top: 50%;
								right: -8px;
								width: 6px;
								height: 6px;
								background-color: $bt_bg_color;
								border-radius: 50%;
								transform: translateY(-90%);
							}
						}

						img {
							width: 50px;
							margin-left: 10px;
						}
					}

					.ant-menu-submenu-arrow {
						
						&::after,
						&::before {
							background: $fontcolor !important;
						}
					}
				}
				
				.ant-menu-submenu-active {
					.ant-menu-submenu-arrow {
						&::before {
							background: #ffffff !important;
						}

						&::after {
							background: #ffffff !important;
						}
					}
					

					.ant-menu-submenu-title{
						.title{
							.title-box{
								span{
									
								}
							}
						}
					}
				}
				
				.ant-menu-submenu-open {
				
					.title {
				
						display: flex;
						align-content: center;

						img {
							width: 50px;
							margin-left: 10px;
						}
					}

					.ant-menu-submenu-arrow {


						&::before {
							background: #253BFF !important;
						}

						&::after {
							background: #253BFF !important;
						}
					}
				}
				
				.ant-menu-submenu-selected {
					color: $botfontcolor;
				}

				.ant-menu-item {

					&:active {
						background-color: $bacColor;
					}

					&.ant-menu-item-disabled {

						&.ant-menu-item-selected {
							background-color: $bacColor;
						}

						>a {
							color: $botfontcolor;
						}
					}

					&.ant-menu-item-selected {
						background-color: $bacColor;

						&::after {
							display: none;
						}

						>a {
							color: $botfontcolor;
						}
					}

					>a {
						color: $botfontcolor;
					}
				}

				.title {
					font-size: 14px;
				}
			}

			.lang-item {
				height: 43px;
				display: flex;
				align-items: center;
				padding-left: 35px;
			}

			.lang-en {
				position: absolute;
				right: 40px;
				top: 0;
			}

			.lang-selected {
				.icon-check {
					visibility: visible !important;
				}
			}
		}

		.rotate-menu-enter-active,
		.rotate-menu-leave-active {
			transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
		}

		.rotate-menu-enter,
		.rotate-menu-leave-to {
			transform: rotateX(-90deg);
		}
	}

	.Faucet-entrance-box {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 99;
		background: rgba(1, 1, 1, .8);
	}
	.dot-box {
		display: flex;
		align-items: flex-start;
		span {
			position: relative;
			.dot {
				position: absolute;
				top: 50%;
				right: -10px;
				width: 6px;
				height: 6px;
				background-color: #4CE4F9;
				border-radius: 50%;
				transform: translateY(-90%);
			}
		}
		
	}

	.Faucet-entrance {
		position: absolute;
		top: 200px;
		left: 50%;
		transform: translateX(-50%);
		padding: 60px;
		padding-bottom: 0;



		width: 986px;
		background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/Faucetentrance1.webp') no-repeat;
		background-size: 100% 100%;
		border-radius: 15px;



		.iconguanbi {
			position: absolute;
			right: 20px;
			top: 20px;
			z-index: 1;
		}

		.faucet-bg {
			width: 458px;
			height: 100%;
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: 1;
		}

		.Faucet-entrance-bg {
			position: relative;
			z-index: 9;

			p {
				margin-bottom: 10px;
			}

			p:nth-of-type(1) {
				font-weight: bold;
				font-size: 40px;
				margin-bottom: 1px;
			}

			p:nth-of-type(2) {
				font-size: 36px;
				color: #4CE4F9;
				font-weight: bold;
				margin-bottom: 30px;
			}

			p:nth-of-type(3) {
				font-size: 16px;
				margin-bottom: 50px;
				color: rgba(255, 255, 255, 0.7);
			}

			p:nth-of-type(4) {
				cursor: pointer;
				text-decoration: underline;
				font-size: 16px;
				margin-bottom: 50px;
				padding-top: 60px;
				padding-left: 50px;
				color: rgba(255, 255, 255, 0.7);
			}

			.c-button {
				width: 324px;
				height: 84px;
				font-size: 30px;
				padding: 0 40px;
				border-radius: 50px;
			}
		}

		@screen md {
			width: 333px;
			height: unset !important;
			padding: 30px;

			.faucet-bg {
				width: 302px;
				height: 357px;
				bottom: unset;
				top: 10%;
			}


			.Faucet-entrance-bg {
				p:nth-of-type(1) {
					font-size: 36px;
				}

				p:nth-of-type(2) {
					font-size: 36px;
				}

				p:nth-of-type(3) {
					display: flex;
					flex-direction: column;
					text-align: left;
					font-size: 12px;
					color: #fff;
				}

				.c-button {
					margin-top: 50px;
					width: 168px;
					height: 44px;
					font-size: 14px;
					padding: 0 5px 0 20px;
					border-radius: 50px;

					.arrow {
						width: 38px;
						height: 38px;
						min-width: 38px;
					}
				}

				p:nth-of-type(4) {
					padding: 20px 20px 0;
					margin-bottom: 10px;
				}


			}
		}


	}

	
	.ant-select-dropdown.plac-dropdown {
		background-color: $tm_background_color_1;
		border-radius: $bt_bo_radius;
		backdrop-filter: blur(50px);
		
		.ant-select-dropdown-menu-item {
			color: $bt_ft_color;
			font-weight: 600;
			line-height: 20px;
			padding: 10px 20px;

			
			&.ant-select-dropdown-menu-item-active,
			&:hover {
				box-shadow: $box_shadow;
				background: $jbfontbacColor;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				font-weight: bold;
				border-radius: $bt_bo_radius;

			}
		}
		
		.ant-select-dropdown-menu-item-selected {
			box-shadow: $box_shadow;
			background: $jbfontbacColor;
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-weight: bold;
			border-radius: $bt_bo_radius;
		
		}
	}

	.ant-dropdown.header-menu {
		background-color: #111924;
		border-radius: 10px;
		min-width: 124px;

		.ant-dropdown-menu {
			padding: 0;
			background-color: #111924;
			border-radius: 10px;
			box-shadow: none;

			.ant-dropdown-menu-item {
				font-weight: 600;
				line-height: 20px;
				padding: 10px 20px;
				border-radius: 10px;

				&:hover {
					background: linear-gradient(90deg, #203DFC 0%, #33C2F2 100%);
				}

				&.ant-dropdown-menu-item-disabled {
					color: rgba(255, 255, 255, 0.2);

					&:hover {
						background: transparent !important;
					}

					>a {
						color: rgba(255, 255, 255, 0.2) !important;
						cursor: not-allowed;
					}
				}

				&:not(.ant-dropdown-menu-item-disabled):hover {
					background-color: #192431;
					border-radius: 10px;

					>a {
						color: white;

					}
				}

				>a {
					color: rgba(255, 255, 255, 0.6);
				}
			}
		}
	}


	.popover-Box {

		display: none;
		overflow: hidden;
		transition: all .3s;
		min-width: 320px;
		position: absolute;
		right: 0;
		top: 32px;
		@screen md{
			min-width: 320px;
			right: -50px;
		}
		.popover-card {
			padding: 20px;
			border-radius: 12px;
			border: 1px solid #2E2A41;
			background: linear-gradient(0deg, rgba(26, 26, 29, 0.5) 0%, rgba(26, 26, 29, 1) 100%), rgba(255, 255, 255, 0.03);
			box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.20);
			backdrop-filter: blur(10px);
		}

		.popover-head {
			.left {
				img {
					width: 28px;
					height: 28px;
				}

				span {
					color: #FFF;
					text-align: right;

					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
					/* 142.857% */
				}

			}

			.right {
				display: flex;
				padding: 4px 0px;
				align-items: center;
				justify-content: center;
				// border-radius: 100px;
				// border: 0.5px solid #C9C9C9;

				img {

					margin-left: 25px;
					width: 20px;
					height: 20px;

				}


				span {
					color: #C9C9C9;
					text-align: right;

					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					/* 142.857% */
				}
			}

		}

		.balance {
			padding: 20px 0;
			color: #FFF;

			font-size: 24px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		.balance-list-head {
			.left {
				color: #FFF;

				font-size: 12px;
				font-style: normal;
				font-weight: 510;
				line-height: 120%;
				/* 14.4px */
			}

			.right {
				display: flex;
				padding: 4px 12px;
				justify-content: center;
				align-items: center;
				gap: 4px;
				cursor: pointer;
				border-radius: 40px;
				/* border: 0.5px solid rgba(255, 255, 255, 0.30); */
				background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);

				color: var(--White, #FFF);
				text-align: center;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 120%;
				/* 14.4px */
				text-transform: capitalize;
			}
		}

		.balance-list {

			ul {
				padding: 7px 0;

				li {
					dl {
						margin: 0;

						dt {
							margin: 0;
						}

						dd {
							margin: 0;
						}
					}

					padding: 7px 0;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					.left {
						padding-top: 8px;

						img {
							border-radius: 100%;
							overflow: hidden;
							width: 24px;
							height: 24px;
						}

					}

					.center {
						flex: 1;
						text-align: left;
						padding: 0 10px;

						dt {
							color: var(--White, #FFF);
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
						}

						dd {
							padding-top: 5px;
							color: var(--White, #FFF);
							font-size: 14px;
							font-style: normal;
							font-weight: 300;
							line-height: 120%;
							/* 16.8px */
						}
					}

					.right {
						dt {
							color: #FFF;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
						}
					}
				}
			}
		}

		.points-box {
			ul {
				padding: 6px 0;
				margin: 0;

				li {
					padding: 6px 0;
					display: flex;
					justify-content: space-between;
					align-items: center;

					span {
						color: rgba(255, 255, 255, 0.7);
						font-size: 12px;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
					}
				}
			}
		}
	}
</style>
<style>
	.ant-popover {
		min-width: 320px;

		.ant-popover-inner-content {
			padding: 0;
		}

		.ant-popover-arrow {
			display: none;
		}

		.ant-popover-inner {
			background: unset;
		}
	}
</style>