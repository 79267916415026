<template>
	<div class="supply">
		<Header></Header>
		<div class="supply-content md:px-12">
			<Breadcrumb :routes="routes"></Breadcrumb>
			<div class="supply-title">
				<div class="title">
					<h1>{{$VContact("联系我们")}}</h1>
					<span>&nbsp;C&RAM Global</span>
				</div>
				<p>{{$VContact("在下方填写您的信_ae02")}}<br>{{$VContact("我们的使命是通过_1ced")}}</p>
			</div>
			<ContactFrom></ContactFrom>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Banner from '@/components/home/Banner.vue'
	import Footer from '@/components/Footer.vue'
	import Breadcrumb from '@/components/common/Breadcrumb.vue'
	import ContactFrom from '@/components/contact/ContactFrom.vue'

	
    /* viai18n-disable */
    import $VContactMessages from "./Contact.messages.json";
  const $defaultObject = {
		name: 'Contact',
		components: {
			Header,
			Footer,
			Banner,
			Breadcrumb,
			ContactFrom
		},
		data() {
			return {
				routes: [{
						path: '/',
						breadcrumbName: 'Home',
					},
					{
						path: '/Contact',
						breadcrumbName: 'Contact',
					},
				],
			}
		},
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VContact(key,origin){
      const messages = $VContactMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VContact(key,origin){
      const messages = $VContactMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

	.supply {
		/* height: 100vh; */
		position: relative;


		.supply-content {
		  background-color: $bacColor;
		  color: $fontcolor;
			padding: 14px 75px;

			.supply-title {
				.title {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 42px;
					margin-bottom: 26px;

					@screen md {
						display: block;
						margin-top: 32px;
						margin-bottom: 16px;
					}

					h1 {
						margin: 0;
						font-size: 64px;
						font-weight: 700;

						@screen md {
							font-size: 30px;
							text-align: center;
						}
					}

					span {
						font-size: 64px;
						font-weight: 800;
						background-image: linear-gradient(to right, #33C4F2, #1F35FD);
						-webkit-background-clip: text;
						background-clip: text;
						display: inline-block;
						color: transparent;
						@screen md {
							display: inline-block;
							width: 100%;
							font-size: 30px;
							text-align: center;
						}
					}
				}

				p {
					text-align: center;
					font-size: 20px;
					font-weight: 700;
					font-style: normal;
					line-height: 32.5px;
					/* 162.5% */
					letter-spacing: 2px;
					margin: 0;

					@screen md {
						font-size: 10px;
						line-height: 18px;
						letter-spacing: 0.1px;
					}
				}
			}
		}

	
	}
</style>