<template>
  <div class="chip-title-content">
    <div class="chip-title">
      <span>{{$VChipTitleContent("芯片")}}</span>
      <span>RWA</span>
    </div>
    <div class="chip-content">
      <p>{{$VChipTitleContent("将至少")}}<span>100 A100</span>{{$VChipTitleContent("机器设备资产上链")}}</p>
      <p>{{$VChipTitleContent("根据市场评估价格_d651")}}<span>1 A100 = 1 USDT</span></p>
    </div>
    <div class="chip-content" style="margin-bottom: 0;">
      <p>{{$VChipTitleContent("发行数量为市场评_3462")}}</p>
      <p>{{$VChipTitleContent("机器将由第三方机_048b")}}</p>
    </div>
  </div>
</template>
<script>

    /* viai18n-disable */
    import $VChipTitleContentMessages from "./ChipTitleContent.messages.json";
  const $defaultObject = {
  name: 'ChipTitleContent'
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VChipTitleContent(key,origin){
      const messages = $VChipTitleContentMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VChipTitleContent(key,origin){
      const messages = $VChipTitleContentMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
.chip-title-content {
  .chip-title {
    margin-top: 54px;
    margin-bottom: 30px;

    span {
      display: inline-block;
      font-size: 64px;
      color: #fff;
      font-weight: 700;
      @screen md {
        font-size: 40px;
        text-align: left;
      }
    }

    :last-child {
      margin-left: 24px;
      background-image: linear-gradient(to right, #33C4F2, #1F35FD);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
  .chip-content {
    position: relative;
    padding-top: 7px;
    margin-bottom: 40px;
    p {
      color: rgba(255, 255, 255, 0.60);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 35px;
      letter-spacing: 0.2px;
      margin: 0;
      /* margin-bottom: 20px; */
      span {
        color: #32BDF3;
      }
      @screen md {
        font-size: 10px;
        line-height: 20px;
      }
    }
    &::after {
      display: block;
      content: '';
      width: 25px;
      height: 3px;
      border-radius: 1px;
      background-color: #32BDF3;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  
}
</style>