import Vue from "vue";

 const addressFilter = (address) => {
	if (!address) {
		return "-";
	}
	return address.replace(/^([A-Za-z0-9]{6})[A-Za-z0-9]{30}([A-Za-z0-9]{6})$/, "$1……$2");
};


export const times = (time) => {
	if (!time) {
		return "-";
	}

	return time.replace("T", " ");
};

/**
 * 小数位保留
 * @param num    //当前数
 * @param digit  //保留位数
 * @param adapt  //是否补0
 * @returns {number}
 */
const integer = (num = 0, digit, adapt = !digit) => {
	try {
		digit = digit || 2;
		let strNum = num.toString().split(".");
		if (strNum.length == 1) {
			strNum.push('00000000000000000000000000000000000')
		} else {
			strNum[1] = strNum[1] + '00000000000000000000000000000000000';
		}
		if (adapt) {
			strNum = parseFloat(strNum[0] + '.' + strNum[1].substr(0, digit));
		} else {
			strNum = strNum[0] + '.' + strNum[1].substr(0, digit);
		}

		return strNum;
	} catch (e) {
		console.log(e)
		//TODO handle the exception
	}
}



/**金额按千位逗号分割
 * @method formatMoney(s,type)
 * 功能：
 * @param {String} s，需要格式化的金额数值.
 * @param {Boolean} type 判断格式化后的金额是否需要小数位.
 * @callback  返回格式化后的数值字符串
 */

const formatMoney = (number) => {
	// 数字转为字符串，并按照 .分割
	let arr = (number + '').split('.');
	let int = arr[0] + '';
	let fraction = arr[1] || '';
	// 多余的位数
	let f = int.length % 3;
	// 获取多余的位数，f可能是0， 即r可能是空字符串
	let r = int.substring(0, f);
	// 每三位添加','金额对应的字符
	for (let i = 0; i < Math.floor(int.length / 3); i++) {
		r += ',' + int.substring(f + i * 3, f + (i + 1) * 3);
	}
	// 多余的位数，上面
	if (f === 0) {
		r = r.substring(1);
	}
	// 调整部分和小数部分拼接
	return r + (!!fraction ? '.' + fraction : '');
}



let instance;
export default {
	install() {
		Vue.filter('addressFilter', addressFilter)
		Vue.filter('times', times)
		Vue.filter('integer', integer)
		Vue.filter('formatMoney', formatMoney)
	},
};