<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    tag: {
      type: String,
      default: "div",
    },
  },

  computed: {
    parts() {
      const text = this.text;
      const regx = RegExp(`\\[(.+?)\\](.*?)\\[\\/\\1\\]`, "g");

      return text
        .replace(regx, value => {
          return `#@#${value}#@#`;
        })
        .split("#@#")
        .map(value => {
          const matchs = regx.exec(value);

          return matchs ? [matchs[2], matchs[1]] : [value];
        });
    },
  },

  render(createElement) {
    return createElement(
      this.tag,
      {
        class: ["custom-text", this.tag === "div" && "inline-block"],
      },
      this.parts.map(part => {
        const value = part[0];
        const identifier = part[1];
        const scopedRender = identifier && this.$scopedSlots[identifier];

        return scopedRender ? scopedRender({ value: value }) : value;
      })
    );
  },
};
</script>