<template>
  <div class="c-supplu-form">
    <div class="form">
      <div class="form-title">
        <span>{{$VContactFrom("请提供以下信息：")}}</span>
      </div>
      <div class="form-table">
        <a-form :form="form">
          <a-form-item>
            <div class="form-input">
			  <span>{{$VContactFrom("姓名：")}}</span>
              <a-input :placeholder='$VContactFrom("您的姓名或称呼")'
                v-decorator="['name', { rules: [{ required: true, message: $VContactFrom('请输入您的姓名或_372b') }] }]" />
            </div>
          </a-form-item>
          <a-form-item>
            <div class="form-input">
              <span>{{$VContactFrom("电话：")}}</span>
              <a-input :placeholder='$VContactFrom("您的电话号码或联_f77c")'
                v-decorator="['phone', { rules: [{ required: true, message: $VContactFrom('请输入您的电话号_7f89') }] }]" />
            </div>
          </a-form-item>
          <a-form-item>
            <div class="form-input">
              <span>{{$VContactFrom("邮箱：")}}</span>
              <a-input :placeholder='$VContactFrom("您的电子邮箱地址")'
                v-decorator="['email', { rules: [{ required: false, message: $VContactFrom('请输入您的电子邮_92c0') }] }]" />
            </div>
          </a-form-item>
        </a-form>
      </div>

      <div class="form-title form-txt-title">
        <span>{{$VContactFrom("您需要我们提供的_1891")}}</span>
      </div>

      <div class="form-table py-0">
        <a-textarea v-model="supply" :placeholder='$VContactFrom("在此输入...._db5c")' :rows="5" />
      </div>
      <div class="submit">
        <a-button type="primary" @click="handleSubmit">{{$VContactFrom("提交")}}</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { add_feedback } from '@/request/api';


    /* viai18n-disable */
    import $VContactFromMessages from "./ContactFrom.messages.json";
  const $defaultObject = {
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      supply: ''
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (err || this.loading) {
          console.log('Received values of form: ', values);
          return
        }
        if (this.$store.state.requestName.add_feedback) {
          this.$message.info(this.$VContactFrom("操作频繁！","操作频繁！"));
          return
        }
        let obj = {
          ...values,
          supply: this.supply
        }

        
        try {
          const msg = this.$message.loading('Loading...', 0);
          this.loading = true
          // 请求
          let res = await add_feedback(obj)
          if (res.data == 1) {
            // 状态
            this.loading = false
            setTimeout(msg, 0);
            this.$message.success(this.$VContactFrom("提交成功","提交成功"));
  
            // 节流
            this.$store.commit('setRequest', { name: 'add_feedback', status: true })
            setTimeout(() => {
              this.$store.commit('setRequest', { name: 'add_feedback', status: false })
            }, 600000);
          }
        } catch (error) {
          // 状态
          this.loading = false
          this.$message.info(this.$VContactFrom("提交失败","提交失败"));
        }

      });
    },
  },
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VContactFrom(key,origin){
      const messages = $VContactFromMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VContactFrom(key,origin){
      const messages = $VContactFromMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

.c-supplu-form {
  margin-top: 80px;

  .form {
    max-width: 353px;
    margin: auto;

    .form-title {
      font-size: 14px;
      color: $fontcolor;
      line-height: 22px;
      white-space: nowrap;
      margin-bottom: 30px;

      @screen md {
        white-space: normal;
      }

      span {
        display: inline-block;
        padding-bottom: 10px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          width: 25px;
          height: 3px;
        }
      }
    }

    .form-txt-title {
      margin-top: 40px;

      span::after {
        color: $fontcolor;
      }
    }

    .form-table {
      border-radius: 10px;
      border: 2px solid rgba(51, 196, 242, 0.60);
      padding-top: 16px;

      .ant-form-item {
        margin-bottom: 35px;

        &:last-of-type(.ant-form-item) {
          margin: 0;
        }

        .form-input {
          position: relative;

          span {
            width: 100%;
            position: absolute;
            left: 21px;
            top: 50%;
            transform: translateY(-50%);
          }

          &::after {
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            background: linear-gradient(270deg, rgba(255, 254, 254, 0.00) 0%, #33C4F2 49.14%, rgba(255, 255, 255, 0.00) 99.28%);
          }
        }

        input {
          width: 100%;
          background: transparent;
          border: none;
          padding-bottom: 6px;
          font-size: 14px;
          padding-left: 80px;
        }

        :deep(.ant-form-explain) {
          padding-left: 21px;
          margin-top: 3px;
          position: absolute;
        }

        .ant-input:focus {
          border: none;
          box-shadow: none;
        }
      }

      textarea {
        background-color: transparent;
        border: none;
        box-shadow: none;
        resize: none;
      }
    }

  }

  .submit {
    margin-top: 36px;
	height: $bt_bo_height;

    .ant-btn-primary {
		width: 100%;
		height: 100%;
		font-size: 20px;
		background-color: $bt_bg_color;
		color: $bt_ft_color;
		font-weight: bold;
		
		&:hover {
			box-shadow: $box_shadow;
			background: $jbfontbacColor;
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			border-radius: $bt_bo_radius;
		}
    }
	

  }
}</style>