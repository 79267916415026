<template>
  <div class="c-zkp-item">
    <div class="item-list">
      <div class="zkp-item">
        <div class="item-tit">
          <div class="img-box">
            <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/zkai-icon1.png" alt="">
          </div>
          <span>{{$VZkpItem("数据预取和延迟隐_478c")}}</span>
        </div>
        <div class="item-content">
          <p>{{$VZkpItem("使用__ldg指_7329")}}</p>
          <p>{{$VZkpItem("使用warpsh_945b")}}</p>
        </div>
      </div>
      <div class="zkp-item">
        <div class="item-tit">
          <div class="img-box">
            <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/zkai-icon2.png" alt="" style="margin-top: -15px;">
          </div>
          <span>{{$VZkpItem("共享内存与局部内_89ff")}}</span>
        </div>
        <div class="item-content">
          <p>{{$VZkpItem("动态分配共享内存_a0d9")}}</p>
        </div>
      </div>
    </div>
    <div class="verify-item">
      <div class="verify-content">
        <div class="tit">
          <span>{{$VZkpItem("验证策略")}}</span>
        </div>
        <div class="content">
          <div class="content-icon md:hidden">
            <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/zkai-icon3.png" alt="">
          </div>
          <p>{{$VZkpItem("利用椭圆曲线同态_e3bc")}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

    /* viai18n-disable */
    import $VZkpItemMessages from "./ZkpItem.messages.json";
  const $defaultObject = {
  
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VZkpItem(key,origin){
      const messages = $VZkpItemMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VZkpItem(key,origin){
      const messages = $VZkpItemMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
.c-zkp-item {
  width: 100%;
  .item-list {
    margin-bottom: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @screen md {
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 50px;
    }
    .zkp-item {
      margin-right: 50px;
      flex: 1;
      @screen md {
        margin-right: 0;
        margin-bottom: 30px;
      }
      .item-tit {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .img-box {
          width: 120px;
          height: 120px;
          @screen md {
            height: 70px;
            width: 70px;
          }
          img {
            width: 100%;
          }
        }
        span {
          font-size: 24px;
          line-height: 40px;
          font-weight: 300;
          @screen md {
            font-size: 16px;
          }
        }
      }
      .item-content {
        margin-top: -20px;
        margin-left: 30px;
        @screen md {
          margin-top: -10px;
          margin-left: 10px;
        }
        p {
          margin: 0;
          font-size: 24px;
          color: rgba($color: #fff, $alpha: .6);
          font-weight: 200;
          @screen md {
            font-size: 16px;
          }
        }
  
      }
    }
    & .zkp-item:last-of-type {
      margin: 0;
    }
  }
  .verify-item {
    width: 100%;
    min-height: 200px;
    padding: 20px 50px;
    border-radius: 20px;
    background: url(https://wami-wod.oss-cn-beijing.aliyuncs.com/img/zkai-li.webp) no-repeat center;
    background-size: 100% auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @screen md {
      padding: 0 20px;
      background-size: auto 100%;
    }
    .verify-content {
      .tit {
        margin-bottom: 10px;
        span {
          font-size: 40px;
          font-weight: 600;
          @screen md {
            font-size: 24px;
          }
        }
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .content-icon {
          width: 120px;
          height: 50px;
          margin-left: -45px;
          @screen md {
            margin-left: -25px;
            width: 240px;
          }
          img {
            width: 100%;
            margin-top: -45px;
            @screen md {
              margin-top: -20px;
            }
          }
        }
        p {
          margin: 0;
          max-width: 830px;
          font-size: 24px;
          color: rgba($color: #fff, $alpha: .6);
          font-weight: 200;
          @screen md {
            max-width: 100%;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>