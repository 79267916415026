import request from "./request";
import store from "../store";

export async function twAuth(data) {
	try {
		let res = await request({
			url: "/api/twsrv/twAuth",
			method: "post",
			data,
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

export async function twFollowed(data) {
	try {
		let res = await request({
			url: "/api/twsrv/twFollowed",
			method: "post",
			data,
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}




//DC

export async function dcAuth(data) {
	try {
		let res = await request({
			url: "/api/twsrv/dcAuth",
			method: "post",
			data,
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

export async function dcFollowed(data) {
	try {
		let res = await request({
			url: "/api/twsrv/dcFollowed",
			method: "post",
			data,
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//白名单
export async function checkAddr(data) {
	try {
		let res = await request({
			url: "/api/twsrv/checkAddr",
			method: "post",
			data: {
				addr: store.state.address,
			},
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//验证状态
export async function checkStatus(data) {
	try {
		let res = await request({
			url: "/api/twsrv/checkStatus",
			method: "post",
			data,
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

//获取领取状态
export async function rewardLog(data) {
	try {
		let res = await request({
			url: "/api/twsrv/rewardCfg",
			method: "post",
			data,
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

//领取
export async function addRewardLog(data) {
	try {
		let res = await request({
			url: "/api/twsrv/addRewardLog",
			method: "post",
			data,
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}



//是否可领取
export async function checkRewardStatus(data) {
	try {
		let res = await request({
			url: "/api/twsrv/checkRewardStatus",
			method: "post",
			data,
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

//授权用户名
export async function userInfo(data) {
	try {
		let res = await request({
			url: "/api/twsrv/userInfo",
			method: "post",
			data,
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

//
export async function RewardStatusReq(data) {
	try {
		let res = await request({
			url: "/api/twsrv/RewardStatusReq",
			method: "post",
			data,
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//RT


// 转推授权/
export async function twRtAuth(data) {
	try {
		let res = await request({
			url: "/api/twsrv/twRtAuth",
			method: "post",
			data: {
				addr: store.state.address,
				...data
			},
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//查询转推状态
export async function verifyTweet(data) {
	try {
		let res = await request({
			url: "/api/twsrv/verifyTweet",
			method: "post",
			data: {
				addr: store.state.address,
			},
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


// 跨链活动转推授权/
export async function twRtMwAuth(data) {
	try {
		let res = await request({
			url: "/api/twsrv/twRtMwAuth",
			method: "post",
			data: {
				addr: store.state.address,
				...data
			},
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//跨链活动转推状态
export async function verifyTweet2(data) {
	try {
		let res = await request({
			url: "/api/twsrv/verifyTweet2",
			method: "post",
			data: {
				addr: store.state.address,
				...data
			},
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

export async function verifySendTweet(data) {
	try {
		let res = await request({
			url: "/api/twsrv/verifySendTweet",
			method: "post",
			data: {
				addr: store.state.address,
				...data
			},
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

//领取
export async function rtRewardClaim(data) {
	try {
		let res = await request({
			url: "/api/twsrv/rtRewardClaim",
			method: "post",
			data: {
				addr: store.state.address,
				...data
			},
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//发布twitter验证
export async function twStAuth(content) {
	
	// const str = `
	// 🚀 Hey Crypto Fam! 🌐 Just scored an awesome airdrop opportunity with BP-FLAC! @wod_global🎁
	// 💰 Bringing my assets over and forming a squad for EXTRA rewards. 🚀 
	
	// 🔗Join me! Use my invite link https://www.wod.ai/InvLogin?inviteCode=${code}  & code ${code}  to level up together.  ✨ 
	// #CryptoAirdrop`
	
	try {
		let res = await request({
			url: "/api/twsrv/twStAuth",
			method: "post",
			data: {
				addr: store.state.address,
				"content":content
			},
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}


//发布twitter
export async function SendTweet(data) {
	try {
		let res = await request({
			url: "/api/twsrv/SendTweet",
			method: "post",
			data: {
				addr: store.state.address,
				...data
			},
		});
		return res;
	} catch (e) {
		console.log(e);
		return false;
	}
}

