<template>
  <div class="chiprwa">
    <Header></Header>
    <div class="chip-content md:px-12">
      <div class="bg-box">
        <div class="bg-r md:hidden">
          <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/chip-bg.webp" alt="">
        </div>
        <div class="line-r"></div>
        <div class="circle-r md:hidden"></div>
        <div class="circle-l"></div>
        <div class="line-l"></div>
      </div>
      <div class='chip-main'>
        <Breadcrumb :routes="routes"></Breadcrumb>
        <ChipTitleContent></ChipTitleContent>
        <ChipItem></ChipItem>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import ChipTitleContent from '@/components/chipRwa/ChipTitleContent.vue'
import ChipItem from '@/components/chipRwa/ChipItem.vue'

export default {
  name: 'ChipRwa',
  components: {
    Header,
    Footer,
    Breadcrumb,
    ChipTitleContent,
    ChipItem,
  },
  data() {
    return {
      routes: [
        {
          path: '/',
          breadcrumbName: 'Home',
        },
        {
          path: '/chipRaw',
          breadcrumbName: 'Chip RWA',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.chiprwa {
  color: #fff;
  .chip-content {
    min-height: 1000px;
    padding: 14px 75px;
    position: relative;
    .bg-box {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .bg-r {
        position: absolute;
        top: 130px;
        right: -380px;
        width: 1253px;
        height: 694px;
        img {
          transform: rotate(-6.385deg);
          width: 100%;
        }
      }
      .line-r, .line-l {
        width: 617px;
        height: 4px;
        transform: rotate(-34.85deg);
        flex-shrink: 0;
        background: linear-gradient(90deg, rgba(34, 84, 244, 0.00) -3.57%, #2254F4 49.82%, rgba(34, 84, 244, 0.00) 102.11%);
        position: absolute;
        right: -300px;
        top: 30px;
      }
      .circle-r, .circle-l {
        width: 20px;
        height: 20px;
        background-color: #2254F4;
        border-radius: 50%;
        position: absolute;
        top: 115px;
        right: 203px;
      }
      .circle-l {
        width: 30px;
        height: 30px;
        background-color: rgba(34, 84, 244, 0.60);
        border-radius: 50%;
        top: 433px;
        left: -15px;
      }
      .line-l {
        left: -400px;
        top: 671px;
      }
    }
    .chip-main {
      max-width: 1140px;
      position: relative;
      z-index: 1;
    }
  }
  @screen md {
    /* padding-top: 55px; */
  }
}
</style>