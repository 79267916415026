import axios from 'axios'
import store from '../store'
import router from '../router'

const service = axios.create({
	baseURL: 'https://www.wod.ai', // 线上: https://www.wod.ai 测试：http://43.154.25.217:8888
	timeout: 15000 // request timeout
});

// request interceptor
service.interceptors.request.use(
	config => {
		config.headers = {
			'Content-Type': 'application/json',
		};
		return config;
	},
	error => {
		return Promise.reject(error)
	}
);

// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data;
		if (res.code == 'SYS-ERROR') {
			return false
		}
		if (res.code === 'notlogin') {
			router.push('/');
		} else {
			return res;
		}
	},
	error => {
 
		return Promise.reject(error)
	}
);

export default service