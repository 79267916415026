import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Supply from '../views/supply/Supply.vue'
import Access from '../views/access/Access.vue'
import ChipRwa from '../views/chipRwa/ChipRwa.vue'
import ZkpAi from '../views/zkpAi/ZkpAi.vue'
import Cost from '../views/cost/Cost.vue'

import Team from '../views/team/Team.vue'
import Company from '../views/company/Company.vue'
import Contact from '../views/contact/Contact.vue'
import Excellent from '../views/excellent/Excellent.vue'
import Case from '../views/case/Case.vue'
import Migrate from '../views/migrate/Migrate.vue'
import Study from '../views/study/Study.vue'
import Visa from '../views/visa/Visa.vue'
import Property from '../views/property/Property.vue'


import CitizenPassport from '../views/plan/CitizenPassport.vue'
import EdAdvantage from '../views/plan/EdAdvantage.vue'
import HeritageGuardAlliance from '../views/plan/HeritageGuardAlliance.vue'
import MediCareFuturoNexus from '../views/plan/MediCareFuturoNexus.vue'
import TaxGuardianSpectrum from '../views/plan/TaxGuardianSpectrum.vue'
import WealthNavigator from '../views/plan/WealthNavigator.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Team',//团队
    name: 'Team',
    component: Team
  },
  {
    path: '/Company',//公司简介
    name: 'Company',
    component: Company
  },
  {
    path: '/Contact',//联系我们
    name: 'Contact',
    component: Contact
  },
  {
    path: '/Excellent',//优秀律师团队
    name: 'Excellent',
    component: Excellent
  },
  {
    path: '/Case',//成功案例
    name: 'Case',
    component: Case
  },
  {
    path: '/Migrate',//移民
    name: 'Migrate',
    component: Migrate
  },
  {
    path: '/Study',//留学教育
    name: 'Study',
    component: Study
  },
  {
    path: '/Visa',//签证
    name: 'Visa',
    component: Visa
  },
  {
    path: '/Property',//数字资产管理
    name: 'Property',
    component: Property
  },
  
  {
    path: '/CitizenPassport',//全球公民身份规划
    name: 'CitizenPassport',
    component: CitizenPassport
  },  
  {
    path: '/EdAdvantage',//国际财税风控规划
    name: 'EdAdvantage',
    component: EdAdvantage
  },  
  {
    path: '/HeritageGuardAlliance',//子女国际教育规划
    name: 'HeritageGuardAlliance',
    component: HeritageGuardAlliance
  },  
  {
    path: '/MediCareFuturoNexus',//尖端医疗养老规划
    name: 'MediCareFuturoNexus',
    component: MediCareFuturoNexus
  },  
  {
    path: '/TaxGuardianSpectrum',//全球资产配置规划
    name: 'TaxGuardianSpectrum',
    component: TaxGuardianSpectrum
  },    
  {
    path: '/WealthNavigator',//家族财富传承规划
    name: 'WealthNavigator',
    component: WealthNavigator
  },  

  
  
  {
    path: '/Supply',
    name: 'Supply',
    component: Supply
  },
  {
    path: '/access',
    name: 'Access',
    component: Access
  },
  {
    path: '/chipRaw',
    name: 'ChipRaw',
    component: ChipRwa
  },
  {
    path: '/zkpAi',
    name: 'ZkpAi',
    component: ZkpAi
  },
  {
    path: '/Cost',
    name: 'Cost',
    component: Cost
  },

  {
    path: '/Airdrop',
    name: 'Airdrop',
    component: () => import("@/views/airdrop/Airdrop.vue")
  },
  {
    path: '/InvLogin',
    name: 'InvLogin',
    component: () => import("@/views/invLogin/InvLogin.vue")
  },
  {
    path: '/Faucet',
    name: 'Faucet',
    component: () => import("@/views/faucet/Faucet.vue")
  },
  {
    path: '/Setting',
    name: 'Setting',
    component: () => import("@/views/setting/Setting.vue")
  },
  {
    path: '/MedalWall',
    name: 'MedalWall',
    component: () => import("@/views/medalWall/MedalWall.vue")
  },
  {
    path: '/allMedalWall',
    name: 'AllMedalWall',
    component: () => import("@/views/allMedalWall/AllMedalWall.vue")
  },
  {
    path: '/Messages',
    name: 'Messages',
    component: () => import("@/views/messages/Messages.vue")
  },
  {
    path: '/Campaign',
    name: 'Campaign',
    component: () => import("@/views/campaign/Campaign.vue")
  },
  {
    path: '/BridgeCampaign',
    name: 'BridgeCampaign',
    component: () => import("@/views/campaign/BridgeCampaign.vue")
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import("@/views/dashboard/Dashboard.vue")
  },

  {
    path: '/InviteTeam',
    name: 'InviteTeam',
    component: () => import("@/views/inviteTeam/InviteTeam.vue")
  },

  {
    path: '/Guidance',
    name: 'Guidance',
    component: () => import("@/views/guidance/Guidance.vue")
  },

  // {
  //   path: '/about', 
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
