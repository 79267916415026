<template>
  <div class="zkpai">
    <Header></Header>
    <div class="zkpai-content md:px-12">
      <div class="bg-box md:hidden">
        <div class="img-box">
          <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/zkai-bg1.webp" alt="">
        </div>
        <div class="img-dt">
          <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/zkai-bg2.png" alt="">
        </div>
      </div>
      <div class='zkpai-main'>
        <Breadcrumb :routes="routes"></Breadcrumb>
        <ZkpTit></ZkpTit>
        <ZkpItem></ZkpItem>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import ZkpTit from '@/components/zkpAi/ZkpTit.vue'
import ZkpItem from '@/components/zkpAi/ZkpItem.vue'

export default {
  name: 'ZkpAi',
  components: {
    Header,
    Footer,
    Breadcrumb,
    ZkpTit,
    ZkpItem
  },
  data() {
    return {
      routes: [
        {
          path: '/',
          breadcrumbName: 'Home',
        },
        {
          path: '/zkpAi',
          breadcrumbName: 'ZKP Algorithm',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.zkpai {
  color: #fff;
  .zkpai-content {
    min-height: 1000px;
    padding: 14px 75px;
    position: relative;
    @screen md {
      min-height: 0;
      height: auto;
    }
    .bg-box {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .img-box {
        position: absolute;
        right: 0;
        top: -100px;
        width: 877px;
        img {
          width: 100%;
        }
      }
      .img-dt {
        position: absolute;
        right: 0;
        top: -70px;
        width: 634px;
        img {
          width: 100%;
        }
      }
    }
    .zkpai-main {
      max-width: 1240px;
      position: relative;
      z-index: 1;
    }
  }
  @screen md {
    /* padding-top: 55px; */
  }
}
</style>