<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	import { enable, disconnect } from "@/web3utils/base/index.js";
	import { getConfig} from '@/utils/active.api.js'
	export default {
		mounted() {
			getConfig();
			window.ethereum.on('accountsChanged', (accounts) => {

				this.$store.commit('CLEAR', '');
				window.location.reload();
				
			});
		},
	}
</script>

<style lang="scss">
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件
	* {
		padding: 0;
		margin: 0;
	}

	html,
	body {
		background-color: $bacColor !important;

		.h-w-6 {
			width: 6px !important;
			height: 6px !important;
		}
	}
	#app{
		&>div{
			background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/bg-1.webp') no-repeat;
			background-position:  0px  -99px;
			background-size: 975px 974px;
		}
	}
</style>