<template>
  <div class="c-footer md:px-12">
    <img class="logo" src="/img/logo/logo.png" alt="">
    <div class="flex justify-between mt-12 md:block">
      <div class="desc">{{$VFooter("C&RAMGlo_9025")}}</div>
      <div class="channels md:mt-40">
        <a href="" target="_blank"><img src="/img/wx/xcx.png" alt=""></a>
      </div>
    </div>
	
	
    <div class="copy-zhong md:mt-22">
      <div class="nav">
		<span>{{$VFooter("联系我们")}}</span>
		<div>
			<div>
				<span>{{$VFooter("联系电话:")}}{{configs.ContactPhone}} </span>
				<span>{{$VFooter("联系邮箱:")}}{{configs.ContactEmail}}</span>
			</div>
			<div>{{$VFooter("联系地址:四川省_050a")}}</div>
		</div>
      </div>
    </div>
	
    <div class="copy-right md:mt-22">
      <div class="name">Copyright © 2023 {{configs.WebsiteName}}</div>
      <div class="nav">
        <a class="menu-item" v-for="(item, index) in menuList" href="/#">{{ item
        }}</a>

      </div>
    </div>
  </div>
</template>
<script>
	import { configs } from '@/utils/configs.js';
import { mapState } from "vuex";

    /* viai18n-disable */
    import $VFooterMessages from "./Footer.messages.json";
  const $defaultObject = {
  computed: {
    ...mapState({
      count: state => state.count
    }),
    menuList() {
      return [this.$VFooter("隐私政策","隐私政策"), this.$VFooter("服务条款","服务条款"), this.$VFooter("用户协议","用户协议")]
    }
  },
	data() {
		return {
			configs: configs()
		}
	}
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VFooter(key,origin){
      const messages = $VFooterMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VFooter(key,origin){
      const messages = $VFooterMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

.c-footer {
  color: $fontcolor;
  max-width: 1920px;
  margin: 39px auto 0 auto;
  padding: 0 75px;
  position: relative;
  z-index: 9;

  .logo{
	height: 80px;
	@screen md {
		height: 50px;
	}
  }
  
  
  @media screen and (min-width: 2070px) {
    padding: 0;
  }

  .desc {
    font-size: 14px;
    line-height: 22px;
    max-width: 365px;
  }

  .channels {
    display: flex;
    align-items: center;

    img {
		border-radius:10px;
	  height:100px;
	  margin-left: 37px;
      @screen md {
        margin-left: 0;
        margin-right: 37px;
      }
    }
  }
  .copy-zhong {
	height:100%;
    min-height: 80px;
    border-top: $border_bottom;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;

    .name {
      font-size: 14px;
    }

    .nav {
		height:100%;
      .menu-item {
			height:100%;
        font-size: 16px;
        margin-left: 53px;
        text-decoration: none;
        position: relative;
        line-height: 24px;

        &.active,
        &:hover {
          color: $fontcolor;
        }
      }
    }
  }
  
  .copy-right {
	height:100%;
    min-height: 80px;
    border-top: $border_bottom;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;

    .name {
      font-size: 14px;
    }

    .nav {
		height:100%;
		white:100%;
		.menu-item {
			float:right;
			white-space: normal;
			height:100%;
			display: block;
			font-size: 14px;
			text-decoration: center;
			line-height: 24px;
			padding: 10px 20px;

			
			&.active,
			&:hover {
				box-shadow: $box_shadow;
				background: $jbfontbacColor;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				border-radius: $bt_bo_radius;
				font-weight: bold;
			}
		}
	  
		>a {
			color: $botfontcolor;
		}
    }
  }
}
</style>