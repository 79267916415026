<template>
	<div class="c-partnership">
		<div class="partner-title font-bold">{{$VPartnership("移民项目")}}</div>
		<div class="partner-list">
			<div class="list-content md:hidden" @click="$router.push('/Migrate')">
				<a @click="$router.push('/Migrate')" target="_blank"><img src="img/hb/1.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/2.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/3.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/4.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/5.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/6.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/7.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/8.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/9.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/10.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/11.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/12.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/13.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/14.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center px-40">
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/1.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/2.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/3.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/4.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/5.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/6.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/7.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/8.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/9.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/10.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/11.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/12.png" alt=""></a>
			</div>
			<div v-if="$device.mobile" class="mobile-logo flex justify-between items-center mt-40 px-40">
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/13.png" alt=""></a>
				<a  @click="$router.push('/Migrate')"  target="_blank"><img src="img/hb/14.png" alt=""></a>
			</div>

		</div>
	</div>
</template>


<script>
	import Button from '@/components/Button.vue'
	
    /* viai18n-disable */
    import $VPartnershipMessages from "./Partnership.messages.json";
  const $defaultObject = {
		components: {
			Button,
		}
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VPartnership(key,origin){
      const messages = $VPartnershipMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VPartnership(key,origin){
      const messages = $VPartnershipMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

	.c-partnership {
		color: $fontcolor;
		position: relative;

		.partner-title {
			font-size: 54px;
			line-height: 68px;

			text-align: center;
			margin-top: 149px;
			margin-bottom: 50px;

			@screen md {
				font-size: 24px;
				line-height: 32px;
				margin-top: 130px;
			}
		}

		.partner-list {
			margin: 0 auto;
			max-width: 1600px;

			.list-content {
				/* padding: 0 75px; */
				display: flex;
				flex-direction: row;
				align-items: stretch;
				justify-content: center;
				flex-wrap: wrap;
				align-content: space-around;
				gap: 26px;
				border-bottom: 1px solid rgba(58, 61, 66, 0.5);
				padding-bottom: 110px;

				a {
					width: 206px;
					height: 130px;
					display: flex;
					align-items: center;
					justify-content: center;
					/* margin: 0 30px; */
					/* margin-bottom: 28px; */

					img {
						/* max-height: 50px; */
						width: 60%;
					}
				}
			}

			.mobile-logo {
				a {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				img {
					max-width: 100px;
					/* max-height: 30px; */
				}
			}

			@screen md {
				img {
					transform: scale(1) !important;
					max-height: 38px;
				}
			}

			@media screen and (min-width: 2070px) {
				padding: 0;
			}

			@media screen and (max-width: 1440px) {
				img {
					transform: scale(0.7);
				}
			}
		}

		.c-button {
			width: 440px;
			margin-top: 120px;

			@screen md {
				margin-top: 80px;
				width: 280px;
			}

			&:hover {
				width: 490px;

				@screen md {
					width: 280px;
				}
			}

			&.ru {
				width: 350px;

				&:hover {
					width: 400px;

					@screen md {
						width: 350px;
					}
				}
			}
		}

		.productivity {

			padding: 93px 75px;
			box-sizing: border-box;
			max-width: 1920px;
			margin: 0 auto;

			@screen md {
				padding: 50px 12px;
			}

			.content {
				position: relative;
				z-index: 1;
				height: 637px;

				@screen md {
					height: 544px;
					box-sizing: border-box;
				}
			}

			@media screen and (min-width: 2070px) {
				padding: 93px 0;
			}

			&::after {
				background-image: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/productivity-bg.webp');
				display: block;
				height: 730px;
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				z-index: 0;
				background-repeat: no-repeat;
				background-size: cover;

				@screen md {
					height: 594px;
					background-image: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/productivity-bg-m.webp')
				}
			}

			.title {
				font-size: 54px;
				line-height: 68px;
				color: white;
				font-weight: bold;

				@screen md {
					font-size: 24px;
					line-height: 32px;
				}
			}

			.desc {
				font-size: 20px;
				line-height: 22px;
				color: rgba($color: #ffffff, $alpha: 0.6);
				margin-top: 12px;
				margin-bottom: 110px;

				@screen md {
					font-size: 12px;
					line-height: 20px;
					margin-bottom: 68px;
				}
			}

			.list--item {
				display: flex;
				align-items: center;
				font-size: 24px;
				line-height: 32px;
				max-width: 800px;
				flex: 1;
				position: relative;

				@screen md {
					padding-bottom: 20px;
				}

				&::after {
					display: block;
					content: '';
					height: 1px;
					background-color: rgba(255, 255, 255, 0.4);
					position: absolute;
					left: 0;
					right: 0;
					bottom: -30px;

					@screen md {
						bottom: 0;
					}
				}

				&+.list--item {
					margin-left: 80px;
				}

				img {
					margin-inline-end: 31px;
				}
			}
		}
	}
</style>