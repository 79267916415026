import Vue from 'vue'
import thawvs from './index.vue'
import store from '@/store'
import router from '@/router'

const inviter={};
const thawvsBox = Vue.extend(thawvs);

inviter.install = (data)=> {
  let instance = new thawvsBox({
    store,
    router,
    data
  }).$mount();
  document.body.appendChild(instance.$el);
  Vue.nextTick(() => {
    instance.isShow = true;
    // show 和弹窗组件里的show对应，用于控制显隐
  })
  // Vue.prototype.$inviter = inviter;
};
export default inviter
