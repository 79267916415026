<template>
  <div class="c-cost-table">
    <a-table :columns="columns" :data-source="data" :pagination="false" bordered>
      <span slot="comm" slot-scope="text">{{ text }}</span>
      <span slot="approximate" slot-scope="text">${{ text }}</span>
      <span slot="provider">{{$VCostTable("提供者")}}</span>
      <span slot="approximate">
        <Language :text='$VCostTable("[r1]机器学习_538d")'>
          <span slot="r1" slot-scope="data">{{ data.value }}<br></span>
          <span slot="r2" slot-scope="data">{{ data.value }}</span>
        </Language>
        
      </span>
      <span slot="scalability">{{$VCostTable("可扩展性")}}</span>
    </a-table>
  </div>
</template>
<script>
import Language from '@/components/common/Language.vue';

    /* viai18n-disable */
    import $VCostTableMessages from "./CostTable.messages.json";
  const $defaultObject = {
  components: {
    Language,
  },
  data() {
    return {
      columns: [
        {
          dataIndex: 'provider',
          key: 'provider',
          slots: { title: 'provider' },
          scopedSlots: { customRender: 'comm' },
        },
        {
          dataIndex: 'approximate',
          key: 'approximate',
          slots: { title: 'approximate' },
          scopedSlots: { customRender: 'approximate' },
        },
        {
          dataIndex: 'scalability',
          key: 'scalability',
          slots: { title: 'scalability' },
          scopedSlots: { customRender: 'comm' },
        }
      ],
      data: [
        {
          key: '1',
          provider: 'Ethereum',
          approximate: "15.700",
          scalability: 'Low',
        },
        {
          key: '2',
          provider: 'Truebit(+Ethereum)',
          approximate: '12',
          scalability: 'Low',
        },
        {
          key: '3',
          provider: 'GCP on-demand',
          approximate: '2.50',
          scalability: 'Medium',
        },
        {
          key: '4',
          provider: 'AWS on demand',
          approximate: '2',
          scalability: 'Medium',
        },
        {
          key: '5',
          provider: 'Golem Network',
          approximate: '1.20',
          scalability: 'Low',
        },
        {
          key: '6',
          provider: 'Vast.ai',
          approximate: '1.10',
          scalability: 'Low',
        },
        {
          key: '7',
          provider: 'AWS spot instances(unreliable)',
          approximate: '0.90',
          scalability: 'Medium',
        },
        {
          key: '8',
          provider: 'GCP spot instances(unreliable)',
          approximate: '0.75',
          scalability: 'Medium',
        },
        {
          key: '9',
          provider: 'Gensyn(projected)',
          approximate: '0.40',
          scalability: 'High',
        },
        {
          key: '10',
          provider: 'Single GPU in datacentre',
          approximate: '0.40',
          scalability: 'None',
        },
        {
          key: '11',
          provider: 'BP-FLAC(projected)',
          approximate: '0.36',
          scalability: 'High',
        },
        
      ]
    }
  },
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VCostTable(key,origin){
      const messages = $VCostTableMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VCostTable(key,origin){
      const messages = $VCostTableMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
.c-cost-table {
  width: 100%;
  :deep(.ant-table-wrapper) {
    /* * {
      color: #fff;
    } */
    table {
      width: 100%;
      color: #fff;
      border-radius: 20px;
      overflow: hidden;
      border: 1px solid #222428;
      border-color: #222428;
      .ant-table-thead {
        tr {
          th {
            width: 33%;
            padding: 25px 60px;
            border-color: #222428;
            border-right: none;
            @screen md {
              padding: 5px 10px;
            }
          }
          >th:first-of-type {
            text-align: left;
            border-bottom-left-radius: 20px;
          }
          >th:last-of-type {
            text-align: center;
            border-bottom-right-radius: 20px;
          }
        }
      }
      .ant-table-tbody {
        tr {
          
          td {
            padding: 20px;
            border-color: #222428;
            text-align: center;
            @screen md {
              padding: 10px;
            }
          }
          >td:first-of-type {
            text-align: left;
            padding-left: 60px;
            @screen md {
              padding-left: 5px;
            }
          }
          >td:last-of-type {
            border-right: none;
          }
        }
        tr:last-of-type {
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          td {
            border: 1px solid #1F7691;
          }
          td:first-of-type {
            border-right-color: #222428;
          }
          td:nth-of-type(2) {
            border-left: none;
            border-right-color: #222428;
          }
          td:last-of-type {
            border-left: none;
          }
        }
      }
      .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) td {
        background-color: transparent;
        /* border: 1px solid #1F7691;
        border-color: #1F7691; */
      }
      /* .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) td:first-of-type {
        border-right-color: #222428;
      } */
      /* .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) td:nth-of-type(2) {
        border-left: none;
        border-right-color: #222428;
      } */
      /* .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) td:last-of-type {
        border-left: none;
      } */
      .ant-table-tbody > tr:last-of-type {
        td:first-of-type {
          border-bottom-left-radius: 20px;
        }
        td:last-of-type {
          border-bottom-right-radius: 20px;
        }
      }
      .ant-table-thead > tr > th {
        background-color: #0E0E0E;
        color: #1F7691;
        font-weight: 700;
        text-align: center;
      }
    }

  }
}
</style>