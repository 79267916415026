import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "tailwindcss/tailwind.css";
import "@/assets/index.css";
import "@/assets/base.scss";
import store from './store'

import lang from './plugins/lang'
import ui from './plugins/ui'
import tools from './plugins/tools'
import filter from './filters/index.js'

import device from "vue-device-detector"

// 通用提示弹窗 命令式组件
import Prompt from './prompt/index'

 import calc from '@/utils/math.js'

 //   全局指令
import "@/directives/index.js"       

Vue.use(Prompt)

Vue.config.productionTip = false

Vue.use(lang);
Vue.use(filter);
Vue.use(tools);
Vue.use(ui);
Vue.use(device)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

