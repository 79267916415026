<template>
  <a-modal centered :visible="visible" :closable="false" :maskClosable="true" @cancel="afterClose" :footer="null"
    wrapClassName="blur-modal" :maskStyle="{ backdropFilter: 'blur(16px)' }">
    <div class="bridge-modal">
      <div class="close md:w-24 md:p-0" @click="afterClose">
        <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/close.svg" alt="">
      </div>
      <div class="bridge-content">
        <div class="content-left">
          <h1><span>BP-FLAC</span> <br> Bridge Campaign is Live!</h1>
          <p class="second-tit">Bridge $10/$100/$500 to claim the early settelers medal.</p>
          <span class="detail">The longer you deposit, the more tokens you deposit, the greater the weighting you receive
            forairdrops.</span>
        </div>
        <div class="content-right">
          <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/model-bridge-campaign.png" alt="">
        </div>
      </div>
      <div class="btn-box" @click="toPage">
        <span>Bridge Now</span>
      </div>
    </div>
  </a-modal>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    afterClose() {
      this.$emit('closeModal')
    },
    toPage() {
      this.$router.push({name: 'Campaign'})
    }
  },
}
</script>
<style lang="scss">
.blur-modal {
  .ant-modal {
    width: auto !important;
    max-width: none !important;

    .ant-modal-content {
      color: #fff;
      background-color: transparent;

      .ant-modal-body {
        padding: 0;

        .bridge-modal {
          width: 860px;
          overflow: hidden;
          border-radius: 20px;
          border: 1px solid rgba(255, 255, 255, 0.20);
          background: #1A1A1D;
          padding: 40px;
          position: relative;

          @screen md {
            width: 355px;
            padding: 24px;
          }

          .close {
            cursor: pointer;
            padding: 12px;
            position: absolute;
            top: 16px;
            right: 16px;

            img {
              width: 100%;
            }
          }

          .bridge-content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @screen md {
              flex-direction: column-reverse;
              gap: 20px;
            }


            .content-left {
              width: 100%;
              display: flex;
              flex-direction: column;

              h1 {
                width: 540px;
                color: #FFF;
                font-size: 40px;
                font-weight: 700;
                line-height: 51.056px;
                margin: 0;
                margin-bottom: 37px;
                @screen md {
                  width: 100%;
                  font-size: 24px;
                  line-height: 32px;
                  text-align: center;
                  margin-bottom: 20px;
                }

                span {
                  background-image: linear-gradient(to right, #33C4F2, #1F35FD);
                  background-clip: text;
                  color: transparent;
                  display: inline-block;
                }
              }

              .second-tit {
                display: inline-block;
                width: 405px;
                color: #FFF;
                font-size: 24px;
                font-weight: 300;
                line-height: 32px;
                margin: 0;
                margin-bottom: 36px;
                @screen md {
                  font-size: 14px;
                  width: 100%;
                  text-align: center;
                  line-height: 24px;
                  margin-bottom: 20px;
                }
              }

              .detail {
                display: inline-block;
                width: 335px;
                color: rgba(255, 255, 255, 0.60);
                font-size: 16px;
                font-weight: 300;
                line-height: 20px;
                margin-bottom: 48px;
                @screen md {
                  width: 100%;
                  font-size: 12px;
                  text-align: center;
                  line-height: 16px;
                  margin-bottom: 30px;
                }
                
              }

            }

            .content-right {
              /* position: absolute; */
              /* top: 78px; */
              /* right: 65px; */
              width: 300px;
              min-width: 300px;

              img {
                margin-left: -80px;
                width: 100%;

                @screen md {
                  margin: auto;
                  width: 214px;
                }
              }
            }
          }

          .btn-box {
            width: 100%;
            height: 48px;
            border-radius: 12px;
            background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            span {
              color: #FFF;
              font-size: 16px;
              font-weight: 700;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>