<template>
	<div class="all-medal-content md:flex-col md:gap-16">
		<div class="content-menu md:w-full md:min-w-full md:max-w-full md:flex-row">
			<div class="menu-item md:w-auto md:px-20 md:py-2 md:h-36 "
				:class="{ 'menu-item-active': active === 'yz' }" @click="changeActive('yz')">

				<span>{{$VMigrateContent("亚洲移民")}}</span>
			</div>
			<div class="menu-item md:w-auto md:px-20 md:py-2 md:h-36 "
				:class="{ 'menu-item-active': active === 'oz' }" @click="changeActive('oz')">
				<span>{{$VMigrateContent("欧洲移民")}}</span>
			</div>
			<div class="menu-item md:w-auto md:px-20 md:py-2 md:h-36 "
				:class="{ 'menu-item-active': active === 'bm' }" @click="changeActive('bm')">
				<span>{{$VMigrateContent("北美移民")}}</span>
			</div>
			
		</div>
		<div class="content-main md:grid md:grid-cols-1">
			<template v-for="(item, index) in medalList" >
				<migrateCard :item="item"></migrateCard>
			</template>
		</div>
		</div>
</template>

<script>
	import {  getConfig } from '@/utils/active.api.js'
	import { mapState } from 'vuex'
	import migrateCard from '../../components/migrate/migrateCard.vue';

	
    /* viai18n-disable */
    import $VMigrateContentMessages from "./MigrateContent.messages.json";
  const $defaultObject = {
		components: { migrateCard},

		data() {
			return {
				popupItem: {},
				active: 'yz',
			};
		},
		computed: {
			...mapState(['isConnect', 'address', 'sbtMedal']),
			medalList() {

				
				if(this.active == 'yz'){
					return {
						'xianggang': {
							"SBTName": this.$VMigrateContent("香港专才优才","香港专才优才"),
							"SBTIntro": this.$VMigrateContent("吸引具备香港特区_41ed","吸引具备香港特区所需而又缺乏的特别技能、知识或经验的内地居民"),
							"Icon": 'img/migrate/xianggang.png',
							"condition":[
								{key:'1',value:this.$VMigrateContent("1、本科学位/大_c5a2","1、本科学位/大专证书")},
								{key:'2',value:this.$VMigrateContent("2、已确实获得聘_fb9a","2、已确实获得聘用于香港企业")},
								{key:'3',value:this.$VMigrateContent("3、薪酬须与本港_f0ab","3、薪酬须与本港专业人士相同")},
								{key:'4',value:this.$VMigrateContent("4、三年或以上专_68d4","4、三年或以上专业或管理经验")},
								{key:'5',value:this.$VMigrateContent("5、无犯罪记录","5、无犯罪记录")}
							],
							"flowpath":[
								{key:'1',value:this.$VMigrateContent("1、免费咨询并签_f285","1、免费咨询并签订委托合约")},
								{key:'1',value:this.$VMigrateContent("2、准备材料匹配_2ffb","2、准备材料匹配合适雇主")},
								{key:'1',value:this.$VMigrateContent("3、签订雇员合约_d591","3、签订雇员合约，递交申请")},
								{key:'1',value:this.$VMigrateContent("4、获得批准函","4、获得批准函")},
								{key:'1',value:this.$VMigrateContent("5、办理“工作港_cc25","5、办理“工作港澳通行证”")},
								{key:'1',value:this.$VMigrateContent("6、入境并领取香_b320","6、入境并领取香港身份证")},
								{key:'1',value:this.$VMigrateContent("7、家人办理手续_17ed","7、家人办理手续，拿身份证")},
								{key:'1',value:this.$VMigrateContent("8、获得香港永久_1c65","8、获得香港永久性居民身份证")},
							]
						},
						'malai': {
							"SBTName": this.$VMigrateContent("马来第二家园","马来第二家园"),
							"SBTIntro": this.$VMigrateContent("马来西亚政府所推_a811","马来西亚政府所推广的一项移居政策"),
							"Icon": 'img/migrate/malai.jpg',
							"condition":[
								{key:'1',value:this.$VMigrateContent("1、50岁以下的_9d83","1、50岁以下的人群需要提供1万马币以上的收入证明；50万马币存款证明；在马来西亚有30万马币的存款；一年后可提取15万马币用于教育、购车和购房")},
								{key:'2',value:this.$VMigrateContent("2、50岁以上的_83b3","2、50岁以上的人群需要提供1万马币以上的收入证明；35万马币存款证明；在马来西亚有15万马币的存款；一年后可提取5万马币用于教育、购车和购房")},
							],
							"flowpath":[
								{key:'1',value:this.$VMigrateContent("1、准备材料，签_7dd5","1、准备材料，签订合同")},
								{key:'1',value:this.$VMigrateContent("2、提交申请，交_4704","2、提交申请，交翻译及认证费")},
								{key:'1',value:this.$VMigrateContent("3、等待批准函","3、等待批准函")},
								{key:'1',value:this.$VMigrateContent("4、换发签证，到_7407","4、换发签证，到马来西亚办理开户、购买保险和体检")},
								{key:'1',value:this.$VMigrateContent("5、成功取得第二_6921","5、成功取得第二家园身份")},
							]
						}
					}
				}else if(this.active == 'oz'){
					return {
						'xibanya': {
							"SBTName": this.$VMigrateContent("西班牙购房移民","西班牙购房移民"),
							"SBTIntro": this.$VMigrateContent("西班牙本土购买西_0a8f","西班牙本土购买西班牙房地产资本至少50万欧元，即可获得西班牙居留许可"),
							"Icon": 'img/migrate/xibanya.jpg',
							"condition":[
								{key:'1',value:this.$VMigrateContent("1、年满18岁","1、年满18岁")},
								{key:'2',value:this.$VMigrateContent("2、非欧盟公民","2、非欧盟公民")},
								{key:'3',value:this.$VMigrateContent("3、购买符合投资_55d8","3、购买符合投资要去数额的房产")},
								{key:'4',value:this.$VMigrateContent("4、无犯罪记录","4、无犯罪记录")},
								{key:'5',value:this.$VMigrateContent("5、购买私人健康_a22a","5、购买私人健康保险，有相应的经济能力")}
							],
							"flowpath":[
								{key:'1',value:this.$VMigrateContent("1、登陆西班牙考_763e","1、登陆西班牙考察")},
								{key:'1',value:this.$VMigrateContent("2、选购房产，缴_81a7","2、选购房产，缴纳定金")},
								{key:'1',value:this.$VMigrateContent("3、签订律师授权_4d0a","3、签订律师授权、开银行账户、税号等")},
								{key:'1',value:this.$VMigrateContent("4、回国后支付房_bbaf","4、回国后支付房款，办理房产过户及公证后递交申请文件")},
								{key:'1',value:this.$VMigrateContent("5、D类签证获批_e85e","5、D类签证获批后，有效期内登陆换取2年居留卡")},
								{key:'1',value:this.$VMigrateContent("6、获批后5年后_0021","6、获批后5年后满足居住要求换永久居留")},
								{key:'1',value:this.$VMigrateContent("7、获批10年后_6172","7、获批10年后满足要求可申请入籍")},
							]
						},
						'putaoya': {
							"SBTName": this.$VMigrateContent("葡萄牙购房投资移_690a","葡萄牙购房投资移民"),
							"SBTIntro": this.$VMigrateContent("通过购买房产建筑_c9e1","通过购买房产建筑年限已超过30年以上的，房产总值须达到35万欧或以上，即可申请黄金居留许可。"),
							"Icon": 'img/migrate/putaoya.jpg',
							"condition":[
								{key:'1',value:this.$VMigrateContent("1、年满18岁","1、年满18岁")},
								{key:'2',value:this.$VMigrateContent("2、非欧盟公民","2、非欧盟公民")},
								{key:'3',value:this.$VMigrateContent("3、所购房产持有_cf00","3、所购房产持有人")},
								{key:'4',value:this.$VMigrateContent("4、无犯罪记录","4、无犯罪记录")},
								{key:'5',value:this.$VMigrateContent("5、购买私人健康_a22a","5、购买私人健康保险，有相应的经济能力")}
							],
							"flowpath":[
								{key:'1',value:this.$VMigrateContent("1、准备商签","1、准备商签")},
								{key:'1',value:this.$VMigrateContent("2、入境考察房产","2、入境考察房产")},
								{key:'1',value:this.$VMigrateContent("3、回国后支付剩_1c60","3、回国后支付剩余房款、税费")},
								{key:'1',value:this.$VMigrateContent("4、准备文件及网_2a00","4、准备文件及网上预约")},
								{key:'1',value:this.$VMigrateContent("5、再次准备商签","5、再次准备商签")},
								{key:'1',value:this.$VMigrateContent("6、二次入境，办_c7e3","6、二次入境，办理移民相关事宜")},
								{key:'1',value:this.$VMigrateContent("7、等待获批、制_4ed9","7、等待获批、制作邮寄居留卡")},
							]
						}
					}
				}else if(this.active == 'bm'){
					return {
						'jianada': {
							"SBTName": this.$VMigrateContent("加拿大企业家移民","加拿大企业家移民"),
							"SBTIntro": this.$VMigrateContent("加拿大联邦政府与_6bc7","加拿大联邦政府与加拿大Saskatchewan省签署的省提名移民计划的协议。"),
							"Icon": 'img/migrate/jianada.jpg',
							"condition":[
								{key:'1',value:this.$VMigrateContent("1、夫妻双方资产_693a","1、夫妻双方资产达到50万加币以上")},
								{key:'2',value:this.$VMigrateContent("2、主申请人有至_69b9","2、主申请人有至少3年以上经商管理经验")},
								{key:'3',value:this.$VMigrateContent("3、符合萨省EO_b985","3、符合萨省EOI评分标准")},
								{key:'4',value:this.$VMigrateContent("4、创业要求：在_d675","4、创业要求：在里贾纳或萨斯卡通投资30万加币并创造二名就业机会；或在萨省其他地区投资20万加币并创造一名就业机会")},
								{key:'5',value:this.$VMigrateContent("5、参与生意的管_5320","5、参与生意的管理")}
							],
							"flowpath":[
								{key:'1',value:this.$VMigrateContent("1、签约","1、签约")},
								{key:'1',value:this.$VMigrateContent("2、考察萨省","2、考察萨省")},
								{key:'1',value:this.$VMigrateContent("3、申请EOI，_cac5","3、申请EOI，获得邀请函")},
								{key:'1',value:this.$VMigrateContent("4、缴纳第三方调_556c","4、缴纳第三方调查费，获申请费打款通知")},
								{key:'1',value:this.$VMigrateContent("5、获得第三方调_6727","5、获得第三方调查报告")},
								{key:'1',value:this.$VMigrateContent("6、递交MNP报_af88","6、递交MNP报告到萨省移民局")},
								{key:'1',value:this.$VMigrateContent("7、获得工作签证","7、获得工作签证")},
								{key:'1',value:this.$VMigrateContent("8、创立萨省企业_dac1","8、创立萨省企业，准备联邦申请文件")},
								{key:'1',value:this.$VMigrateContent("9、获得省提名","9、获得省提名")},
								{key:'1',value:this.$VMigrateContent("10、获得联邦档_05b4","10、获得联邦档案号")},
								{key:'1',value:this.$VMigrateContent("11、获得体检表","11、获得体检表")},
								{key:'1',value:this.$VMigrateContent("12、获得取签信_7476","12、获得取签信、枫叶卡")},
							]
						},
						'meiguo': {
							"SBTName": this.$VMigrateContent("美国EB1-C移_d1be","美国EB1-C移民"),
							"SBTIntro": this.$VMigrateContent("以工作关系为基础_d42a","以工作关系为基础的移民申请"),
							"Icon": 'img/migrate/meiguo.jpg',
							"condition":[
								{key:'1',value:this.$VMigrateContent("1、证明申请人在_61dc","1、证明申请人在中国公司企业主或者高管至少1年")},
								{key:'2',value:this.$VMigrateContent("2、中国公司年营_d63c","2、中国公司年营业额需再100万美金以上")},
								{key:'2',value:this.$VMigrateContent("3、中国公司雇佣_8cbf","3、中国公司雇佣人员需达到20人")},
							],
							"flowpath":[
								{key:'1',value:this.$VMigrateContent("1、咨询、评估及_2c54","1、咨询、评估及签约；")},
								{key:'1',value:this.$VMigrateContent("2、登陆6天5夜_10c7","2、登陆6天5夜考察美国生意")},
								{key:'1',value:this.$VMigrateContent("3、考察完成，达_9b11","3、考察完成，达成生意收购")},
								{key:'1',value:this.$VMigrateContent("4、准备申请材料_5a2c","4、准备申请材料递交I-140申请")},
								{key:'1',value:this.$VMigrateContent("5、I-140通_b1d5","5、I-140通过。等待永居身份获批")},
							]
						}
					}
				}else if(this.active == 'qt'){
					return []
				}
			},
		},
		created() {
			this.active = this.$route.query.type || 'yz'
			this.getMedalWall()
		},
		mounted() {
			this.init()
		},
		watch: {
			'$route.query': {
				handler(newQuery, oldQuery) {
					if (this.$route.query.type) {
						this.active = this.$route.query.type
						this.getMedalWall()
					}
					const currentRoute = this.$route;
					if (currentRoute.query.type) {
						this.$router.replace({
							query: {} // 更新后的查询参数对象
						});
					}
				},
				immediate: true, // 是否立即执行回调
				deep: true, // 是否深度监听
			},
		},
		methods: {
			init(){
				getConfig();
				
			},
			changeActive(active) {
				this.active = active;
			},
			
			getMedalWall() {
				console.log(this.active)
			}
		},
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VMigrateContent(key,origin){
      const messages = $VMigrateContentMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VMigrateContent(key,origin){
      const messages = $VMigrateContentMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件



	.all-medal-content {
		/* max-width: 1590px; */
		margin: auto;
		overflow: hidden;
		position: relative;
		z-index: 1;
		min-height: calc(100vh - 80px);
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 50px 75px 80px;
		display: flex;
		gap: 40px;

		@screen md {
			padding: 16px;
		}

		.content-menu {
			/* margin-right: 40px; */
			width: 196px;
			min-width: 196px;
			max-width: 196px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;


			.menu-item {
				cursor: pointer;
				width: 100%;
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 12px;
				border-radius: 2px;
				transition: all .3s;
				

				img {
					width: 24px;
					margin-right: 16px;
				}

				span {
					display: inline-block;
					color: $fontcolor;
					font-size: 16px;
					font-style: normal;
					font-weight: 900;
					line-height: 16px;
					/* 100% */
					letter-spacing: -0.4px;
				}
			}

			.menu-item-active {
				box-shadow: $box_shadow;
				background: $jbfontbacColor;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				border-radius: $bt_bo_radius;
			}
		}

		.content-main {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			gap: 24px;

			.sbt-item {
				width: 283px;
				/* height: 336px; */
				border-radius: 6px;
				border: 1px solid rgba(255, 255, 255, 0.10);
				box-shadow: $box_shadow;
				
				overflow: hidden;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				position: relative;
				z-index: 0;

				.isAcquire {
					position: absolute;
					right: 5%;
					top: 5%;
					width: 38px;
					height: 38px;

					@screen md {
						width: 28px;
						height: 28px;

					}
				}

				.nft-img {
					border-radius: 6px;
					overflow: hidden;
					width: 281px;
					/* height: 281px; */
					/* min-height: 281px;
					max-height: 281px; */

					img {
						border-radius: 6px;
						width: 100%;
						/* height: 100%; */
						object-fit: cover;
					}
				}

				.nft-name {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 54px;

					.isKt {
						margin-right: 10px;
						width: 24px;
						height: 24px;
					}

					span {
						color: $fontcolor;
						text-align: center;
						font-size: 14px;
						font-style: normal;
						font-weight: 590;
						line-height: 14px;
						/* 100% */
						letter-spacing: -0.4px;
					}
				}

				.nft-name-md {
					display: none;
					color: $fontcolor;

					.nft-detail-md {
						padding: 0 8px 8px;

						h1 {
							.isKt {
								margin-right: 10px;
								width: 24px;
								height: 24px;
							}

							color: $fontcolor;
							font-size: 14px;
							font-weight: 700;
							line-height: 16px;
							/* 100% */
							letter-spacing: -0.4px;
						}

						p {
							margin: 0;
							color: $fontcolor;
							font-size: 12px;
							font-weight: 400;
							line-height: 16px;
							/* 133.333% */
							letter-spacing: -0.8px;
						}
					}

					.nft-claim-md {
						width: 100%;
						height: 36px;
						display: flex;
						align-items: center;
						justify-content: center;

						span {
							display: inline-block;
							width: 100%;
							height: 100%;
							text-align: center;
							line-height: 36px;
							border-radius: 0px 0px 16px 16px;
							border: 1px solid rgba(255, 255, 255, 0.10);
							background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
							border: none;
						}
					}
				}

				.detail-btn {
					width: 100%;
					position: absolute;
					bottom: -142px;
					left: 0;
					z-index: 2;
					overflow: hidden;
					transition: all .3s;

					.detail-box {
						height: 100px;
						padding: 16px 12px;
						border: 1px solid rgba(255, 255, 255, 0.10);
						border-radius: 16px 16px 0px 0px;
						border-left: none;
						border-right: none;
						border-bottom: none;
						background: rgba(255, 255, 255, 0.09);
						backdrop-filter: blur(15px);

						span {
							color: $fontcolor;
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 14px;
							border: none;
							/* 100% */
						}

						p {
							margin: 0;
							margin-top: 10px;
							color: $fontcolor;
							font-size: 14px;
							font-style: normal;
							font-weight: 300;
							line-height: 18px;
							/* 128.571% */
						}
					}

					.btn-box {
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 42px;
						border-radius: 0px 0px 16px 16px;
						background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);

						/* backdrop-filter: blur(15px); */
						span {
							display: inline-block;
							width: 100%;
							height: 100%;
							color: $fontcolor;
							text-align: center;
							font-size: 14px;
							font-style: normal;
							font-weight: 590;
							line-height: 42px;
							/* 100% */
							letter-spacing: -0.4px;
						}
					}
				}

				&:hover {
					.detail-btn {
						bottom: 0;
						/* transform: translateY(0); */
					}
				}
			}
		}
	}
</style>