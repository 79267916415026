<template>
  <div class="content md:mx-0 md:mt-0 md:mb-0">
    <div class="desc-content">
      <div class="content-wrap md:mx-12">
        <div class="flex md:block">
          <div class="answer flex-1">{{$VIntroduce("服务优势")}}</div>
        </div>
  
      </div>
    </div>
    <div class="introduce-content md:block md:px-12 md:mt-40">
      <div class="flex-1" >
        <div class="box-item  item-2 flex-1 md:mb-20" @click="$router.push('/Excellent')">
          <div class="text">{{$VIntroduce("全球卓越律师团队")}}</div>
          <div class="desc">{{$VIntroduce("我们以一支多元化_c374")}}</div>
          <img src="/img/introduce/introduce-3.png" alt="">
        </div>
		
        <div class="box-item item-2 md:mb-20" @click="$router.push('/Property')">
          <div class="text">{{$VIntroduce("创新资产管理")}}</div>
          <div class="desc">{{$VIntroduce("我们不仅关注传统_f3de")}}</div>
          <div >
            <Button :class="$lang">{{$VIntroduce("了解更多")}}</Button>
          </div>
          <img src="/img/introduce/introduce-3.png" alt="">
        </div>
      </div>
      <div class="flex-1">
        <div class="box-item item-2 md:mb-20" @click="$router.push('/Excellent')">
          <div class="text">{{$VIntroduce("全球视野本地专业")}}</div>
          <div class="desc">{{$VIntroduce("无论您的需求是移_9891")}}</div>
          <img src="/img/introduce/introduce-3.png" alt="">
        </div>
		
		
        <div class="box-item item-2" @click="$router.push('/Excellent')">
          <div class="text">{{$VIntroduce("尊贵定制服务")}}</div>
          <div class="desc">{{$VIntroduce("我们深入了解每位_0efb")}}</div>
          <div>
            <Button :class="$lang">{{$VIntroduce("了解更多")}}</Button>
          </div>
          <img src="/img/introduce/introduce-3.png" alt="">
        </div>
      </div>
    </div>
	
  </div>
</template>


<script>
import Button from '@/components/Button.vue'

    /* viai18n-disable */
    import $VIntroduceMessages from "./Introduce.messages.json";
  const $defaultObject = {
  components: {
    Button,
  }
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VIntroduce(key,origin){
      const messages = $VIntroduceMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VIntroduce(key,origin){
      const messages = $VIntroduceMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

.content {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  z-index: 100;
}
.desc-content {
	color: $fontcolor;

  position: relative;
  /* top: -100px; */
  top: 0;
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 40px;

  @screen md {
    top: 0!important;
    margin-top: 30px;
  }

  @media screen and  (max-width: 1440px) {
    /* top: -50px; */
    margin-top: 40px;
  }

  .content-wrap {
    position: relative;
    margin: 0 75px;

    @media screen and (min-width: 2070px) {
      margin: 0;
    }
  }

  .text-dot {
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 6px;

    margin-right: 7px;
  }

  .question {
    font-size: 14px;
  }

  .answer {
    font-size: 54px;
    line-height: 68px;
    font-weight: 600;
    max-width: 100%;
	text-align: center;

    @screen md {
      max-width: 100%;
      font-size: 24px!important;
      line-height: 32px!important;
    }

    @media screen and  (max-width: 1440px) {
      font-size: 40px;
      line-height: 48px;
    }

    @media screen and  (max-width: 1200px) {
      font-size: 28px;
      line-height: 36px;
    }

  }

  .right-desc {
    font-size: 20px;
    width: 40%;
    position: absolute;
    bottom: 0;
    right: 0;

    @screen md {
      width: 100%;
      font-size: 12px!important;
    }

    @media screen and  (max-width: 1440px) {
      font-size: 16px;
    }

    @media screen and  (max-width: 1200px) {
      font-size: 14px;
    }
  }
}
.introduce-content {
  // max-width: 1770px;
  margin: 0 auto;
  display: flex;
  padding: 0 20px;
  max-width: 1920px;
  margin-bottom: 66px;

  @media screen and (min-width: 2070px) {
    padding: 0;
  }
  .flex-1 {
    display: flex;
    flex-direction: column;
  }
  .box-item {
    // flex: 1;
    border-radius: 20px;
    box-shadow: $box_shadow;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &::after {
      content: "";
      display:block;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      position: absolute;
      transform: rotateZ(180deg);
      border-radius: 20px;

      background: 
      radial-gradient(80.07% 90.07% at 50% 100%, #000000 10%, rgba(4, 1, 19, 1) 39.26%, rgba(61, 84, 202, 0.3) 100%),
      linear-gradient(0deg, rgba(46,42,65, 0.9), rgba(46,42,65, 0.1)),
      linear-gradient(180deg, #D7CCF6 -4.51%, rgba(19, 8, 48, 0) 52.91%);
      z-index: 1;
      // display: none;
      opacity: 0;
      transition: opacity 3s cubic-bezier(.26,1.04,.54,1);
    }

    .text {
      font-size: 32px;
      font-weight: bold;
      line-height: 38px;
      padding-left: 20px;
      padding-top: 47px;
      transition: all 0.3s;
      position: relative;
      z-index: 2;
      max-width: 300px;

      @screen md {
        padding-left: 23px;
        padding-top: 28px;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .desc {
      font-size: 16px;
      line-height: 24px;
      margin-top: 37px;
      // max-width: 473px;
      padding-left: 20px;
      transition: all 0.3s;
      position: relative;
      z-index: 2;
      max-width: 640px;
      margin-bottom: 29px;
      @screen md {
        font-size: 12px;
        padding: 0 23px;
        margin-top: 8px;
        line-height: 20px;
      }
    }
    .c-button {
      margin-left: 67px;
	  background-color: $bt_bg_color !important;
      @screen md {
        margin-left: 23px;
      }
    }

    &:hover, &.active {

      &::after {
        // display: block;
        opacity: 1;
      }
      .text {
        color: transparent;
        background-clip: text;
        display: inline-block;
        background-image: radial-gradient(98.96% 1459.2% at 102.6% 50%, #33C4F2 0%, #1F35FD 100%);
      }

      .desc {
		color: white;
      }
	  
	  
    }
    
    &.item-1 {
      height: 320px;
      margin-right: 50px;
      margin-bottom: 34px;

      @screen md {
        height: 293px;
        margin-right: 0;
      }

      img {
        position: absolute;
        right: -90px;
        bottom: -30px;
      }
    }

    &.item-2 {
      min-height: 274px;
      border-radius: 20px;
      margin: 20px;

      img {
        position: absolute;
        right: 14px;
        top: 0;
      }
    }

    &.item-3 {
      min-height: 347px;
      padding-bottom: 30px;
      img {
        position: absolute;
        right: 14px;
        bottom: 0;
      } 
      &::after {
        display: none;
      }
    }
    &.item-4 {
      min-height: 298px;
      margin-right: 50px;
      padding-bottom: 30px;
      @screen md {
        height: 293px;
        margin-right: 0;
      }
      img {
        position: absolute;
        right: 14px;
        bottom: 0;
      } 
      &:hover{
        &::after {
          display: none;
        }
      }
    }
  }
}
.header {
  height: 156px;
  width: 100%;
  background-image: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/dapp-header.png');
  position: relative;
  z-index: 100;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 20px;
  background-size: cover;
  margin-bottom: 200px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @screen md {
    display: block;
    background-image: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/dapp-header-m.png');
    height: 293px;
  }

  div {
    flex: 1;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

  }

  .text {
    font-size: 32px;
    line-height: 38px;
    width: 874px;
    padding-left: 57px;

    @screen md {
      width: auto;
    }
  }

  .desc {
    max-width: 480px;
    margin-left: 64px;
  }
}

</style>