<template>
  <div class="c-partnership">
    <div class="productivity md:px-12">
      <div class="content">
        <div class="title">{{$VProductivity("创新")}}<br>{{$VProductivity("AI的生产力")}}</div>
        <div class="desc">{{$VProductivity("BP-FLAC使_db88")}}</div>
        <div class="flex md:block">
          <div class="list--item">
            <img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/test-net.png" alt="">
            <span class="link" @click="toBlockChain">{{$VProductivity("Forgenet_2ed6")}}</span>
          </div>
          
        </div>
        <div class="page-btn">
          <div class="supply" @click="toPage('Supply')">
            <Button :class="$lang">{{$VProductivity("提供算力")}}</Button>
          </div>
          <div class="access" @click="toPage('Access')">
            <Button :class="$lang">{{$VProductivity("接入算力")}}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Button from '@/components/Button.vue'

    /* viai18n-disable */
    import $VProductivityMessages from "./Productivity.messages.json";
  const $defaultObject = {
  components: {
    Button,
  },
  methods: {
    toPage(name) {
      this.$router.push({name})
    },
    toBlockChain() {
      window.open('https://explorer.wodrpc.org/#/index')
    }
  }
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VProductivity(key,origin){
      const messages = $VProductivityMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VProductivity(key,origin){
      const messages = $VProductivityMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
.c-partnership {
  color: white;
  position: relative;

  .partner-title {
    font-size: 54px;
    line-height: 68px;
    color: white;
    text-align: center;
    margin-top: 149px;
    margin-bottom: 50px;

    @screen md {
      font-size: 24px;
      line-height:32px;
      margin-top: 130px;
    }
  }

  .partner-list {
    margin: 0 auto;
    max-width: 1920px;

    .list-content {
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid rgba(58, 61, 66, 0.5);
      padding-bottom: 110px;
    }

    @screen md {
      img {
        transform: scale(1)!important;
        max-height: 38px;
      }
    }

    @media screen and (min-width: 2070px) {
      padding: 0;
    }

    @media screen and (max-width: 1440px) {
      img {
        transform: scale(0.7);
      }
    }
  }

  .c-button {
    width: 240px;

    @screen md {
      width: 240px;
    }

    &:hover {
      width: 290px;

      @screen md {
        width: 280px;
      }
    }

    &.ru {
      width: 350px;

      &:hover {
        width: 400px;

        @screen md {
          width: 350px;
        }
      }
    }
  }

  .productivity {

    padding: 93px 75px;
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto;

    @screen md {
      padding: 50px 12px;
    }

    .content {
      position: relative;
      z-index: 1;
      height: 637px;

      @screen md {
        height: 544px;
        box-sizing:border-box;
      }
    }

    @media screen and (min-width: 2070px) {
      padding: 93px 0;
    }

    &::after {
      background-image: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/productivity-bg.webp');
      display: block;
      height: 730px;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 0;
      background-repeat: no-repeat;
      background-size: cover;

      @screen md {
        height: 594px;
        background-image: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/productivity-bg-m.webp')
      }
    }

    .title {
      font-size: 54px;
      line-height: 68px;
      color: white;
      font-weight: bold;

      @screen md {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .page-btn {
      display: flex;
      align-items: center;
      margin-top: 120px;
      @screen md {
        align-items: flex-start;
        flex-direction: column;
        margin-top: 80px;
      }
      .supply {
        margin-right: 73px;
        @screen md {
          margin-right: 0px;
          margin-bottom: 50px;
        }
      }
      .access {
        .c-button {
          border-radius: 30px;
          background: linear-gradient(to right, #0016DD, #4CE4F9);
          &::after {
            background: linear-gradient(to right, #4CE4F9, #0016DD);
          }
        }
      }
    }

    .desc {
      font-size: 20px;
      line-height: 22px;
      color: rgba($color: #ffffff, $alpha: 0.6);
      margin-top: 12px;
      margin-bottom: 110px;

      @screen md {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 68px;
      }
    }

    .list--item {
      display: flex;
      align-items: center;
      font-size: 24px;
      line-height: 32px;
      max-width: 800px;
      flex: 1;
      position: relative;
      .link {
        cursor: pointer;
      }

      @screen md {
        padding-bottom: 20px;
      }

      &::after {
        display: block;
        content: '';
        height: 1px;
        background-color: rgba(255, 255, 255, 0.4);
        position: absolute;
        left: 0;
        right: 0;
        bottom: -30px;

        @screen md {
          bottom: 0;
        }
      }

      &+.list--item {
        margin-left: 80px;
      }

      img {
        margin-inline-end: 31px;
      }
    }
  }
}
</style>