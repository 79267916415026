<template>
  <div class="guidance">
    <Header></Header>
    <div class="guidance-content md:px-0">
      <div class="guidance-tit">
        <span>{{$VProperty("创新资产管理配置_5053")}}</span>
      </div>
      <div class="guidance-main">
        <PropertyColl></PropertyColl>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import PropertyColl from '../../components/property/PropertyColl.vue';

    /* viai18n-disable */
    import $VPropertyMessages from "./Property.messages.json";
  const $defaultObject = {
  components: {
    Header,
    Footer,
    PropertyColl
},
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VProperty(key,origin){
      const messages = $VPropertyMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VProperty(key,origin){
      const messages = $VPropertyMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

.guidance {
  width: 100%;
  overflow: hidden;
  .guidance-content {
    min-height: 100vh;
    width: 1440px;
    margin: auto;
    padding: 80px 0 20px;
    @media (min-width: 1200px) and (max-width: 1440px) {
      width: 1200px;
    }
    @media (min-width: 667px) and (max-width: 1200px) {
      width: 600px;
    }
    @screen md {
      width: 342px;
      padding-top: 24px;
    }
    .guidance-tit {
      color: $fontcolor;
      font-size: 24px;
      font-weight: 590;
      line-height: 24px; /* 100% */
      margin-bottom: 46px;
      @screen md{
        margin-bottom: 26px;
        text-align: center;
      }
    }
  }
}
</style>