import { render, staticRenderFns } from "./ChipRwa.vue?vue&type=template&id=2af3cbb4&scoped=true"
import script from "./ChipRwa.vue?vue&type=script&lang=js"
export * from "./ChipRwa.vue?vue&type=script&lang=js"
import style0 from "./ChipRwa.vue?vue&type=style&index=0&id=2af3cbb4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2af3cbb4",
  null
  
)

export default component.exports